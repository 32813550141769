import { Component, HostListener } from '@angular/core';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { SortOrder, Project, ProjectsGQL, ProjectGQL, ProjectsQueryVariables, ProjectsQuery, DeleteOneProjectGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { QueryRef } from 'apollo-angular';
import { UtilService } from 'src/app/core/_services/util.service';
import { DialogService } from 'src/app/core/_services/dialog.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('500ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('50ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ]),
    trigger('slideLeftRight', [
      transition(':enter', [style({ left: '100%' }), animate(200)]),
      transition(':leave', [animate(200, style({ left: '100%' }))]),
    ]),
  ]
})
export class ProjectComponent {
  projects$:Observable<Project[]>
  queryVariables:ProjectsQueryVariables = {
    orderBy: {createdAt:{sort:SortOrder.Desc}},
    take:10
  }
  displayedColumns: string[] = [
    'code',
    'name',
    'description',
    'organization',
    'createdAt',
    'updatedAt',
    'action'
  ]
  displayForm = false
  formTitle = 'Add Project'
  isNewRecord = false
  projectId = ''
  project: Project = <Project>{}
  orgQuery: QueryRef<ProjectsQuery>
  private readonly refresh$ = new Subject<void>();

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.displayForm) this.displayForm = false
  }

  constructor(
    private projectsGQL: ProjectsGQL,
    private loader: LoaderService,
    private deleteProject: DeleteOneProjectGQL,
    public util: UtilService,
    private dialogService: DialogService
    ){

    this.orgQuery = projectsGQL.watch(this.queryVariables, {fetchPolicy:'network-only'})
    this.projects$ = this.refresh$
    .pipe(
      startWith(undefined),
      switchMap(ref=>{
        return this.projectsGQL.watch(this.queryVariables, {fetchPolicy:'network-only'}).valueChanges.pipe(
          take(1),
          this.loader.indicate(),
          map(res=><Project[]>res.data.projects)
        )
      })
    )

    // this.projects$ = this.orgQuery.valueChanges.pipe(
    //   take(1),
    //   this.loader.indicate(),
    //   map(res=><Project[]>res.data.projects)
    // )
  }
  selectRow(row?:Project){
    this.update(row)
  }
  toggleDisplayForm(){
    this.displayForm = this.displayForm ? false : true
  }
  add(){
    if(this.isNewRecord) this.toggleDisplayForm()
    else{
      this.displayForm = true
      this.isNewRecord = true
      this.formTitle = 'Add Project'
    }
  }
  update(row?:Project){
    if(row){
      if(row.id && row.id == this.project.id && !this.isNewRecord) this.toggleDisplayForm()
      else{
        this.displayForm = true
        this.formTitle = 'Update Project'
        this.isNewRecord = false
        this.project = row
      }
    }
  }
  delete(id:string){
    this.dialogService.confirm({
      title:'Delete Confirmation',
      message:'It is critical. Are you sure you want to delete this project and all related data?'
    }).subscribe(yes=>{
      if(yes){
        this.deleteProject.mutate({where:{id:id}}).subscribe(({data})=>{
          if(data?.deleteOneProject?.id){
            this.refresh$.next()
          }
        })
      }
    })
  }
  saved(){
    this.toggleDisplayForm()
    this.refresh$.next()
  }
}
