<h2>Dashboard</h2>
<app-project-stats></app-project-stats>
<div class="flex items-start">
  <div class="mt-3">
    <app-beneficiary-stats-sex></app-beneficiary-stats-sex>
  </div>
  <div class="mt-3 ml-3 grow">
    <app-beneficiary-stats-group></app-beneficiary-stats-group>
  </div>
</div>

<div>
  <mat-card class="p-3 mt-5">
    <h2>Beneficiaries by Location</h2>
    <div class="text-slate-500 py-5">Not implemented yet</div>
  </mat-card>
</div>
