<ng-container *ngIf="(participants$ | async) as par">
  <div class="flex flex-col px-3 pb-1">
    <div class="flex items-center text-blue-700 uppercase"><span class="">{{activityDate(implementation.dateStart, implementation.dateEnd)}}</span></div>
    <div class="mb-3 flex items-center justify-between">
      <div>
        <div class="py-2">{{implementation.description}}</div>
      </div>
    </div>
  </div>
  <h2>Participant List</h2>
  <div *ngIf="par.length == 0; else dataTable" class="p-5 bg-slate-100 rounded-lg">
    No participant list yet. You can add participant by search or add new participant data.
  </div>
  <ng-template #dataTable>
    <table mat-table [dataSource]="par" matSort>
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Full Name </th>
        <td mat-cell *matCellDef="let element"> {{element?.fullName}} </td>
      </ng-container>
      <ng-container matColumnDef="sex">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sex </th>
        <td mat-cell *matCellDef="let element"> {{element?.sex}} </td>
      </ng-container>
      <ng-container matColumnDef="age">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Age </th>
        <td mat-cell *matCellDef="let element"> {{element?.age}} </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
        <td mat-cell *matCellDef="let element"> {{element?.location?.name}} </td>
      </ng-container>
      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
        <td mat-cell *matCellDef="let element"> {{explodeGroups(element?.groups)}} </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
        <td mat-cell *matCellDef="let element"> <button mat-icon-button (click)="deleteParticipant(element?.participantId)"><mat-icon>delete</mat-icon></button> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="row-data" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </ng-template>

</ng-container>
<mat-card class="mt-3">
  <mat-card-content>
    <mat-label class="mb-3 text-lg">Add Participant</mat-label>
    <app-beneficiary-autocomplete (change)="addParticipant($event)" [placeholder]="'Type beneficiary name'"></app-beneficiary-autocomplete>
    <mat-hint class="text-xs">Search beneficiary's name to adding participant list on this activity implementation</mat-hint>
  </mat-card-content>
</mat-card>
