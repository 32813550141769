import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Subject, Observable, from, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ActivityImplementation, ActivityImplementationsGQL, Beneficiary, BeneficiaryGroup, CreateOneImplementationParticipantGQL, DeleteOneImplementationParticipantGQL, Group, ImplementationParticipant, ImplementationParticipantsGQL } from 'src/app/core/_services/graphql';
import { UtilService } from 'src/app/core/_services/util.service';
import { needConfirmation } from '../../../core/confirm-dialog/confirm-dialog.decorator';
import { DialogService } from 'src/app/core/_services/dialog.service';
import { ConfirmDialogComponent } from 'src/app/core/confirm-dialog/confirm-dialog.component';
interface IParticipant extends Beneficiary{
  participantId: string
}

@Component({
  selector: 'app-participants-form',
  templateUrl: './participants-form.component.html',
  styleUrls: ['./participants-form.component.css']
})


export class ParticipantsFormComponent implements OnChanges{
  @Input() implementation: ActivityImplementation = <ActivityImplementation>{}
  @Output() change = new EventEmitter()
  private readonly refresh$ = new Subject<void>();
  participants$: Observable<IParticipant[]> = of(<IParticipant[]>[])
  localParticipants: IParticipant[] = <IParticipant[]>[]
  displayedColumns: string[] = [
    'fullName',
    'sex',
    'age',
    'location',
    'group',
    'delete'
  ]
  constructor(
    private participants: ImplementationParticipantsGQL,
    private createParticipant: CreateOneImplementationParticipantGQL,
    private deleteOneParticipant: DeleteOneImplementationParticipantGQL,
    private activityImplementations: ActivityImplementationsGQL,
    private util: UtilService,
    private dialog: MatDialog,
    private dialogService: DialogService
  ){

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.participants$ = this.refresh$.pipe(
      startWith(undefined),
      switchMap(ref=>{
        return this.participants.watch({
          where:{
            implementationId:{
              equals: this.implementation.id
            }
          }
        }, {fetchPolicy:'network-only'}).valueChanges.pipe(
          map(res=>{
            const _participants =  <IParticipant[]>res.data.implementationParticipants.map(item=> {
              return {...item.beneficiary, ...{participantId: item.id}}
            })
            this.localParticipants = _participants
            return _participants
          })
        )
      })
    )
  }
  activityDate(dateStart:string, dateEnd: string){
    return dateStart == dateEnd ? this.util.formatDate(dateStart) : `${this.util.formatDate(dateStart)} - ${this.util.formatDate(dateEnd)}`
  }
  explodeGroups(groups:BeneficiaryGroup[]){
    return groups.map(g=>g.group.name).join(', ')
  }
  addParticipant($event:Beneficiary){

    if($event && $event.__typename=='Beneficiary' && $event.id.length > 10){
      const _filter = this.localParticipants.filter(item=> item.id == $event.id)
      if(_filter.length > 0){
        this.dialogService.alert({
          title: 'Ooops...',
          message: 'Sorry, the beneficiary has participate this event. No data duplication.'
        })
      }
      else this.doAddParticipant($event)

      //check duplicate beneficiary on activityId
      // this.activityImplementations.watch({
      //   where:{
      //     activityId:{equals:this.implementation.activityId},
      //     participants:{every:{
      //       beneficiaryId:{
      //         equals: $event.id
      //       }
      //     }}
      //   }
      // }).valueChanges.pipe(
      //   map(res=>{
      //     console.log('Duplicate: ' + res)
      //     if(res.data.activityImplementations.length > 0){
      //       // forbidden to duplicate
      //       // alert('Sorry, the beneficiary has participate this event. No data duplication.')
      //     }else{
      //       this.doAddParticipant($event)
      //     }
      //   })
      // )
    }

    if($event && $event.id && $event.id == 'add'){
      console.log($event)
      this.createBeneficiary()
    }

  }
  doAddParticipant($event:Beneficiary){
    this.createParticipant.mutate({
      data:{
        implementation:{
          connect: {
            id: this.implementation.id
          }
        },
        beneficiary:{
          connect:{
            id: $event.id
          }
        }
      }
    }).subscribe(res=>{
      if(res.data?.createOneImplementationParticipant){
        this.refresh$.next()
        this.change.emit()
      }
    })
  }
  createBeneficiary(){
    // console.log('CREATE BENE')
    const dialogRef = this.dialog.open(BeneficiaryFormDialog, {
      width:'50vw'
    });
    const sub = dialogRef.componentInstance.onAdd.subscribe((id) => {
      const newBene = <Beneficiary>{
        __typename:'Beneficiary',
        id:id
      }
      this.doAddParticipant(newBene)
    });
  }

  // @needConfirmation({
  //   title : "Delete Confirmation",
  //   message : "Are you sure to delete this attendance data?"
  // })
  deleteParticipant(participantId: string){
    this.dialogService.confirm({
        title : "Delete Confirmation",
        message : "Are you sure to delete this attendance data?"
      }).subscribe((result)=>{
        if(result){
          this.deleteOneParticipant.mutate({where:{
            id:participantId
          }}).subscribe((res)=>{
            if(res.data?.deleteOneImplementationParticipant){
              this.refresh$.next()
              this.change.emit()
            }else{
              alert('Data deletion has failed.')
            }
          })
        }
      })
  }
}

@Component({
  selector: 'app-beneficiary-form-dialog',
  templateUrl: './beneficiary-form-dialog.html'
})
export class BeneficiaryFormDialog {
  onAdd = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<BeneficiaryFormDialog>,
    @Inject(MAT_DIALOG_DATA) public beneficiaryId: string,
  ) {}
  beneficiarySaved(id:string){
    this.onAdd.emit(id)
    this.dialogRef.close();
  }
}
