import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import DataLabelsPlugin from 'chartjs-plugin-datalabels';

export type DataSets = {
  data: number
  label: string
}
export type Stats = {
  labels: string[],
  datasets: DataSets[]
}

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  // styleUrls: [ './bar-chart.component.scss' ],
})
export class BarChartComponent implements OnChanges{
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() labels:string[] = []
  @Input() datasets:any
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    DataLabelsPlugin
  ];

  // public barChartData: ChartData<'bar'> = {
  //   labels: [],
  //   datasets: []
  // };

  public barChartData?: ChartData<'bar'>

  ngOnChanges(changes: SimpleChanges): void {
      this.barChartData = {
        labels: this.labels,
        datasets: this.datasets
      }
      console.log(this.barChartData)
      this.chart?.update()
  }
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    indexAxis:'y',
    scales: {
      x: {},
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  // public randomize(): void {
  //   // Only Change 3 values
  //   this.barChartData.datasets[0].data = [
  //     Math.round(Math.random() * 100),
  //     59,
  //     80,
  //     Math.round(Math.random() * 100),
  //     56,
  //     Math.round(Math.random() * 100),
  //     40 ];

  //   this.chart?.update();
  // }
}
