import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { from, Subscription, Observable } from 'rxjs';
import { switchMap, debounceTime, tap, finalize, map, take, startWith, filter } from 'rxjs/operators';
import { isArray, isEmpty, clone, isObject } from 'underscore';
import { BeneficiariesGQL, Beneficiary, QueryMode, SortOrder } from 'src/app/core/_services/graphql';
import { UtilService } from 'src/app/core/_services/util.service';

@Component({
  selector: 'app-beneficiary-autocomplete',
  templateUrl: './beneficiary-autocomplete.component.html',
  styleUrls: ['./beneficiary-autocomplete.component.css']
})
export class BeneficiaryAutocompleteComponent{
  loading = false;
  autoForm:FormGroup;
  input:FormControl = new FormControl(<string>'')
  results$:Observable<Beneficiary[]>
  optionValueAdd = {id:'add', fullName: 'Add Beneficiary'}

  @Input() placeholder = 'Search beneficiary'
  @Output() change = new EventEmitter();
  @Output() addAction = new EventEmitter();

  constructor(private query: BeneficiariesGQL, private fb: FormBuilder, private utilService: UtilService){
    this.autoForm = this.fb.group({
      input:this.input
    })

    this.results$ = this.autoForm.valueChanges.pipe(
      startWith(this.autoForm.value),
      // debounceTime(300),
      // tap(() => this.loading = true),
      switchMap(formValue => {
        if(formValue.input && formValue.input !== '' && !isObject(formValue.input)){
          return this.utilService.projectState$.pipe(
            map(p=>p.project.id),
            switchMap(pid=>{
              return this.query.watch({
                where:{
                  fullName:{
                    contains: formValue.input,
                    mode:QueryMode.Insensitive
                  },
                  projectId: {equals: pid}
                },
                orderBy:{
                  fullName:SortOrder.Asc
                }
              }).valueChanges.pipe(
                map(r=>{
                  if(r.data.beneficiaries && r.data.beneficiaries.length > 0){
                    return <Beneficiary[]>r.data.beneficiaries
                  }else{
                    return <Beneficiary[]>[{id:'not-found', fullName:'Not found'}]
                  }
                })
              )
            })
          )
        }else{
          return <Observable<Beneficiary[]>>from(new Promise(resolve => resolve([{id:'empty', fullName:'Please type beneficiary\'s name'}])));
        }
      }),
      map(res=>res)
    )
  }
  displayFn(obj:any){
    if(obj) return obj.fullName
  }
  onSelectionChanged($event:any){
    if($event && !isEmpty($event.option.value.id) && $event.option.value.id !== 'not-found' && $event.option.value.id !== 'empty'){
      let option = clone($event.option.value);
      this.change.emit(option);
      this.input.setValue('');
    }
    if($event && !isEmpty($event.option.value.id) && $event.option.value.id !== 'add'){
      this.input.setValue('');
      this.addData()
    }
  }
  addData(){
    this.addAction.emit()
  }
}
