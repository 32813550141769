import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { DashboardMenuComponent } from './dashboard-menu/dashboard-menu.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { OrganizationComponent } from './organization/organization.component';
import { ProjectComponent } from './project/project.component';
import { LocationComponent } from './location/location.component';
import { OutputComponent } from './output/output.component';
import { ActivityComponent } from './activity/activity.component';
import { UserComponent } from './user/user.component';
import { GroupComponent } from './group/group.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { ActivityImplementationComponent } from './activity-implementation/activity-implementation.component';
import { ActivityAttendeeComponent } from './activity-attendee/activity-attendee.component';
import { OrganizationFormComponent } from './organization/organization-form/organization-form.component';
import { ProjectFormComponent } from './project/project-form/project-form.component';
import { UserFormComponent } from './user/user-form/user-form.component';
import { ProjectStateComponent } from './project/project-state/project-state.component';
import { ProjectAutocompleteComponent } from './project/project-autocomplete/project-autocomplete.component';
import { OutputFormComponent } from './output/output-form/output-form.component';
import { ActivityFormComponent } from './output/activity-form/activity-form.component';
import { BeneficiaryFormComponent } from './beneficiary/beneficiary-form/beneficiary-form.component';
import { ActivityImplementationFormComponent } from './activity-implementation/activity-implementation-form/activity-implementation-form.component';
import { ActivityAutocompleteComponent } from './activity/activity-autocomplete/activity-autocomplete.component';
import { AssetsFormComponent } from './activity-implementation/assets-form/assets-form.component';
import { ParticipantsFormComponent } from './activity-implementation/participants-form/participants-form.component';
import { BeneficiaryAutocompleteComponent } from './beneficiary/beneficiary-autocomplete/beneficiary-autocomplete.component';
import { ActivityImplementationStatsComponent } from './activity-implementation/activity-implementation-stats/activity-implementation-stats.component';
import { BeneficiaryFormDialog } from './activity-implementation/participants-form/participants-form.component';
import { ProjectStatsComponent } from './stats/project-stats/project-stats.component';
import { BeneficiaryStatsSexComponent } from './stats/beneficiary-stats-sex/beneficiary-stats-sex.component';
import { BeneficiaryStatsLocationComponent } from './stats/beneficiary-stats-location/beneficiary-stats-location.component';
import { BeneficiaryStatsGroupComponent } from './stats/beneficiary-stats-group/beneficiary-stats-group.component';
import { BarChartComponent } from './stats/charts/bar-chart/bar-chart.component';
import { PieChartComponent } from './stats/charts/pie-chart/pie-chart.component';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    HomeComponent,
    DashboardMenuComponent,
    HeaderMenuComponent,
    OrganizationComponent,
    ProjectComponent,
    LocationComponent,
    OutputComponent,
    ActivityComponent,
    UserComponent,
    GroupComponent,
    BeneficiaryComponent,
    ActivityImplementationComponent,
    ActivityAttendeeComponent,
    OrganizationFormComponent,
    ProjectFormComponent,
    UserFormComponent,
    ProjectStateComponent,
    ProjectAutocompleteComponent,
    OutputFormComponent,
    ActivityFormComponent,
    BeneficiaryFormComponent,
    ActivityImplementationFormComponent,
    ActivityAutocompleteComponent,
    AssetsFormComponent,
    ParticipantsFormComponent,
    BeneficiaryAutocompleteComponent,
    ActivityImplementationStatsComponent,
    BeneficiaryFormDialog,
    ProjectStatsComponent,
    BeneficiaryStatsSexComponent,
    BeneficiaryStatsLocationComponent,
    BeneficiaryStatsGroupComponent,
    BarChartComponent,
    PieChartComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule
  ],
  exports:[
    CoreModule,
    DashboardMenuComponent,
    HeaderMenuComponent
  ]
})
export class FeaturesModule { }
