import { Component } from '@angular/core';

@Component({
  selector: 'app-no-project',
  templateUrl: './no-project.component.html',
  styleUrls: ['./no-project.component.css']
})
export class NoProjectComponent {

}
