import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ConfirmDialogData } from '../confirm-dialog/confirm-dialog-data';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  matDialog = inject(MatDialog);

  private static instance : DialogService | null= null;
  constructor() {
    DialogService.instance = this;
  }
  public static getInstance(){
    return DialogService.instance;
  }


  openDialog<T>(data : ConfirmDialogData,component: ComponentType<T>) : Observable<boolean>{

    return this.matDialog.open(component,{
      data : data,
      disableClose: true,
    }).afterClosed();

  }
  confirm<T>(data : ConfirmDialogData) : Observable<boolean>{
    return this.matDialog.open(ConfirmDialogComponent,{
      data : data,
      disableClose: true,
    }).afterClosed();
  }
  alert<T>(data : ConfirmDialogData) : Observable<boolean>{
    return this.matDialog.open(AlertDialogComponent,{
      data : data,
      disableClose: false,
    }).afterClosed();
  }
}
