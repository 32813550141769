import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CreateOneProjectGQL, Organization, OrganizationsGQL, Project, SortOrder, UpdateOneProjectGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.css']
})
export class ProjectFormComponent implements OnChanges{
  @Input() recordId:string = ''
  @Input() projectId:string = ''
  @Input() project:Project = <Project>{}
  @Input() isFullAccess:boolean = false
  @Output() complete = new EventEmitter()

  // project: Project = <Project>{}
  projectForm?: FormGroup
  isNewRecord: boolean
  isFormReady:boolean = false
  listOrganizations: Observable<Organization[]>

  constructor(
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private createProject: CreateOneProjectGQL,
    private updateProject: UpdateOneProjectGQL,
    private loader: LoaderService,
    private organizationGQL: OrganizationsGQL
  ){
    this.isNewRecord = this.project?.id ? false : true
    this.projectForm = this.buildForm()
    this.isFormReady = true
    this.listOrganizations = this.organizationGQL.watch({orderBy:{name:SortOrder.Asc}}).valueChanges.pipe(
      map(res=> <Organization[]>res.data.organizations)
    )
  }
  ngOnChanges(changes: SimpleChanges): void {
      // this.project = changes.project?.currentValue
      // console.log(this.project)
      this.isNewRecord = this.project?.id ? false : true
      this.projectForm = this.buildForm()
      this.isFormReady = true
  }
  buildForm(){
    return this.fb.group({
      name: [!this.isNewRecord ? this.project?.name : '', Validators.required],
      code: [!this.isNewRecord ? this.project?.code : '', Validators.required],
      description: [!this.isNewRecord ? this.project?.description : '', Validators.required],
      organization: [!this.isNewRecord ? this.project?.organization : '', Validators.required],
      organizationId: [!this.isNewRecord ? this.project?.organizationId : ''],
    })
  }
  displayFn(org: Organization): string {
    return org && org.name ? org.name : '';
  }
  save(){
    if(this.projectForm?.valid){
      console.log(this.projectForm.value)
      const formInput = <Project>this.projectForm.value
      const input = {
        name: formInput.name,
        code: formInput.code,
        description: formInput.description
      }
      if(this.isNewRecord){
        this.createProject.mutate({data:{...input, ...{organization:{
          connect:{
            id: formInput.organization?.id
          }
        }}}})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.createOneProject.id)
        ).subscribe(id=>{
          if(id){
            this.complete.emit(true)
          }
        })
      }else{
        this.updateProject.mutate({data:{
          name:{set:input.name},
          code:{set:input.code},
          description:{set:input.description},
          organization: {connect: {
            id: formInput.organization?.id
          }}
        }, where:{id:this.project.id}})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.updateOneProject?.name)
        ).subscribe(name=>{
          if(name){
            this.complete.emit(true)
          }
        })
      }

    }
  }
}
