<ng-container *ngIf="(project$ | async) as p">
  <ng-container *ngIf="p && p.id; else noProject">
    <div class="flex justify-start items-center rounded project-state" matRipple (click)="toggleChooser()">
      <div class="flex flex-col justify-start m-0 p-0">
          <div class="project-text flex justify-between">
              <span>Project</span>
              <span>{{p?.code}}</span>
          </div>
          <div class="project-name flex justify-start items-center">
              <span>{{p?.organization?.name}}</span>
              <mat-icon class="project-arrow">chevron_right</mat-icon>
              <span>{{p?.name}}</span>
              <mat-icon>arrow_drop_down</mat-icon>
          </div>
      </div>
  </div>
  </ng-container>
  <ng-template #noProject>
    <span class="text-sm text-red-500">No Project found for your organization</span>
  </ng-template>
</ng-container>

<div *ngIf="projectChooserVisible" class="project-chooser">
    <mat-card class="shadow-xl">
        <div class="flex justify-between items-center p-2 border-b border-slate-200">
            <div class="pt-2 pl-2 text-lg">Choose Project</div>
            <button mat-icon-button (click)="toggleChooser()"><mat-icon>clear</mat-icon></button>
        </div>
        <mat-card-content>
            <ng-container *ngIf="(projectList$ | async) as projects">
                <ng-container *ngIf="projects.length > 0">
                    <mat-nav-list>
                        <mat-list-item *ngFor="let p of projects" (click)="setProject(p)">
                            <div class="flex justify-between items-center w-full project-item">
                                <div class="flex flex-col md:flex-row justify-start items-start md:items-center project-names">
                                    <mat-hint>{{p?.organization?.name}}</mat-hint>
                                    <mat-icon class="project-arrow invisible md:visible">chevron_right</mat-icon>
                                    <span>{{p?.name}}</span>
                                </div>
                                <mat-hint>{{p?.code}}</mat-hint>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>
                </ng-container>
            </ng-container>
            <!-- <div *ngIf="authService.isAdmin" class="pt-3"><app-project-autocomplete placeholder="Search active projects" (change)="setProject($event)" status="active"></app-project-autocomplete></div> -->
        </mat-card-content>
    </mat-card>
</div>
