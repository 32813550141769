import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Project, ProjectsGQL, SortOrder } from 'src/app/core/_services/graphql';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, debounceTime, tap, finalize, map, take } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-project-autocomplete',
  templateUrl: './project-autocomplete.component.html',
  styleUrls: ['./project-autocomplete.component.css']
})
export class ProjectAutocompleteComponent implements AfterViewInit {
  @Input() placeholder:string = 'Cari Proyek'
  @Input() data:Project = <Project>{}
  @Input() displayResult:boolean = false
  @Input() appearance:any = 'fill'
  @Input() status:string = '' // open | ready | active | closed
  @Output() change = new EventEmitter<Project>();
  loading = new BehaviorSubject(false);
  autoForm:FormGroup;
  data$: Observable<Project[]>
  separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;


  // @ViewChild('userInput', { static: false }) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('userInput', { static: false }) userInput:any=null;

  constructor(private query:ProjectsGQL, private formBuilder:FormBuilder) {
    this.autoForm = this.formBuilder.group({
      input:[null]
    })
    this.data$ = this.autoForm.controls['input']
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.loading.next(true)),
      switchMap(value => this.search(value)
      .pipe(
        take(1),
        finalize(() => this.loading.next(false))
        )
      )
    )
  }

  ngAfterViewInit() {
    // this.userInput.nativeElement.value = "";
  }

  search(keyword:string):Observable<Project[]>{
    if(keyword !== '' && keyword.length > 1){
      let filter = {
        where:{
          name:{contains:keyword}
        },
        take: 15,
        orderBy:{name: SortOrder.Asc}
      }
      return this.query.watch()
      .valueChanges
      .pipe(
        map(e => <Project[]>e.data.projects)
      );
    }
    return of(<Project[]>[]);
  }
  setStatusFilter(status: string){
    let r = null
    switch(status){
      case 'open': r = {isActive: false, isFundComplete: false}; break;
      case 'ready': r = {isActive: false, isFundComplete: true}; break;
      case 'active': r = {isActive: true}; break;
      case 'closed': r = {isClosed: true}; break;
      default: r = null; break;
    }
    return r;
  }
  displayFn(obj:Project): string{
    return obj && obj.name ? obj.name : ''
  }
  onSelectionChanged($event:MatAutocompleteSelectedEvent){
    if($event && $event.option.value._id && $event.option.value._id !== ''){
      // console.log($event)
      this.change.emit($event.option.value);
      this.data = $event.option.value
      this.autoForm?.controls['input'].setValue('');
      this.userInput.nativeElement.value = '';
    }
  }
  remove(){
    this.data = <Project>{}
    this.change.emit(this.data)
  }

}
