import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageState, UtilService } from './_services/util.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition} from '@angular/material/snack-bar';

@Component({
  selector: 'app-message-alert',
  template: `<div></div>`
})
export class MessageComponent implements OnInit, OnDestroy {
  message:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  private subscription: Subscription;
  constructor(private messageService: UtilService, public snackBar: MatSnackBar) {
    this.subscription = this.messageService.messageState
      .subscribe((state: MessageState) => {
        let config = new MatSnackBarConfig();
        config.horizontalPosition = this.horizontalPosition;
        config.verticalPosition = this.verticalPosition;
        config.duration = 4000;
        config.panelClass = 'snackbar-message';

        this.snackBar.open(state.message, state?.button, config);
      });
  }//public snackBarConfig: MatSnackBarConfig

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
