import { Component, HostListener } from '@angular/core';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { SortOrder, Beneficiary, BeneficiariesGQL, BeneficiaryGQL, BeneficiariesQueryVariables, BeneficiariesQuery, DeleteOneBeneficiaryGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { QueryRef } from 'apollo-angular';
import { UtilService } from 'src/app/core/_services/util.service';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('500ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('50ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ]),
    trigger('slideLeftRight', [
      transition(':enter', [style({ left: '100%' }), animate(200)]),
      transition(':leave', [animate(200, style({ left: '100%' }))]),
    ]),
  ]
})
export class BeneficiaryComponent {
  beneficiaries$:Observable<Beneficiary[]>
  displayedColumns: string[] = [
    'fullName',
    'sex',
    'age',
    'group',
    'phone',
    'email',
    'location',
    'createdAt',
    'updatedAt',
    'action'
  ]
  displayForm = false
  formTitle = 'Add Beneficiary'
  isNewRecord = false
  beneficiaryId = ''
  beneficiary: Beneficiary = <Beneficiary>{}
  private readonly refresh$ = new Subject<void>();

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.displayForm) this.displayForm = false
  }

  constructor(
    private beneficiariesGQL: BeneficiariesGQL,
    private loader: LoaderService,
    private deleteBeneficiary: DeleteOneBeneficiaryGQL,
    public util: UtilService
    ){

    this.beneficiaries$ = this.refresh$
    .pipe(
      startWith(undefined),
      switchMap(ref=>{
        return this.util.projectState$.pipe(
          map(p=>p.project.id)
        )
      }),
      switchMap(pid=>{
        return this.beneficiariesGQL.watch({
          where:{projectId: {equals:pid}},
          orderBy:{createdAt:{sort:SortOrder.Desc}}
        }, {fetchPolicy:'network-only'}).valueChanges.pipe(
          take(1),
          this.loader.indicate(),
          map(res=><Beneficiary[]>res.data.beneficiaries)
        )
      })
    )
  }
  selectRow(row?:Beneficiary){
    this.update(row)
  }
  toggleDisplayForm(){
    this.displayForm = this.displayForm ? false : true
  }
  add(){
    if(this.isNewRecord) this.toggleDisplayForm()
    else{
      this.displayForm = true
      this.isNewRecord = true
      this.formTitle = 'Add Beneficiary'
    }
  }
  update(row?:Beneficiary){
    if(row){
      if(row.id && row.id == this.beneficiary.id && !this.isNewRecord){
        this.toggleDisplayForm()
        this.beneficiary = row
      }
      else{
        this.displayForm = true
        this.formTitle = 'Update Beneficiary'
        this.isNewRecord = false
        this.beneficiary = row
      }
    }
  }
  delete(id:string){
    if(confirm('Are you sure want to delete this beneficiary?')){
      this.deleteBeneficiary.mutate({where:{id:id}}).subscribe(({data})=>{
        if(data?.deleteOneBeneficiary?.id){
          this.refresh$.next()
        }
      })
    }
  }
  saved(){
    this.toggleDisplayForm()
    this.refresh$.next()
  }
}
