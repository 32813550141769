import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivityImplementation, AggregateAssetGQL, AggregateParticipantGQL } from 'src/app/core/_services/graphql';
import { BehaviorSubject, Observable, Subject, combineLatest, merge, of } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-activity-implementation-stats',
  templateUrl: './activity-implementation-stats.component.html',
  styleUrls: ['./activity-implementation-stats.component.css']
})
export class ActivityImplementationStatsComponent implements OnChanges{
  @Input() implementation: ActivityImplementation = <ActivityImplementation>{}
  @Input() type:string = 'assets'
  @Input() refresh = new BehaviorSubject(new Date().toISOString())
  stats$: Observable<number> = of(<number>0)

  constructor(
    private aggregateParticipant: AggregateParticipantGQL,
    private aggregateAsset: AggregateAssetGQL
  ){

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.type == 'assets'){
      this.stats$ = this.assetsCount(this.implementation.id)
    }else if(this.type == 'participants'){
      this.stats$ = this.participantsCount(this.implementation.id)
    }
  }
  assetsCount(implementationId:string){
    return this.refresh.pipe(
      startWith(this.refresh.value),
      switchMap(ref=>{
        return this.aggregateAsset.watch({where:{
          implementation:{
            is:{
              id:{equals: implementationId}
            }
          }
        }}, {fetchPolicy:'network-only'}).valueChanges
        .pipe(
          map(res=>{
            return res.data.aggregateImplementationAsset._count?.id ? res.data.aggregateImplementationAsset._count.id : 0
          })
        )
      })
    )
  }
  participantsCount(implementationId:string){
    return this.refresh.pipe(
      startWith(this.refresh.value),
      switchMap(ref=>{
        return this.aggregateParticipant.watch({where:{
          implementation:{
            is:{
              id:{equals: implementationId}
            }
          }
        }}, {fetchPolicy:'network-only'}).valueChanges
        .pipe(
          map(res=>{
            return res.data.aggregateImplementationParticipant._count?.id ? res.data.aggregateImplementationParticipant._count.id : 0
          })
        )
      })
    )
  }
}
