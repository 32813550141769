import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Activity = {
  __typename?: 'Activity';
  _count?: Maybe<ActivityCount>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  implementations: Array<ActivityImplementation>;
  name: Scalars['String'];
  output: Output;
  outputId: Scalars['String'];
  project: Project;
  projectId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ActivityImplementationsArgs = {
  cursor?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityImplementationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityImplementationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityImplementationWhereInput>;
};

export type ActivityCount = {
  __typename?: 'ActivityCount';
  implementations: Scalars['Int'];
};

export type ActivityCountAggregate = {
  __typename?: 'ActivityCountAggregate';
  _all: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  outputId: Scalars['Int'];
  projectId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ActivityCountOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outputId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActivityCreateInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  implementations?: InputMaybe<ActivityImplementationCreateNestedManyWithoutActivityInput>;
  name: Scalars['String'];
  output: OutputCreateNestedOneWithoutActivitiesInput;
  project: ProjectCreateNestedOneWithoutActivitiesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityCreateManyInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outputId: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityCreateManyOutputInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityCreateManyOutputInputEnvelope = {
  data: Array<ActivityCreateManyOutputInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityCreateManyProjectInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outputId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityCreateManyProjectInputEnvelope = {
  data: Array<ActivityCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityCreateNestedManyWithoutOutputInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutOutputInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutOutputInput>>;
  createMany?: InputMaybe<ActivityCreateManyOutputInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutProjectInput>>;
  createMany?: InputMaybe<ActivityCreateManyProjectInputEnvelope>;
};

export type ActivityCreateNestedOneWithoutImplementationsInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutImplementationsInput>;
  create?: InputMaybe<ActivityCreateWithoutImplementationsInput>;
};

export type ActivityCreateOrConnectWithoutImplementationsInput = {
  create: ActivityCreateWithoutImplementationsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutOutputInput = {
  create: ActivityCreateWithoutOutputInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutProjectInput = {
  create: ActivityCreateWithoutProjectInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateWithoutImplementationsInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  output: OutputCreateNestedOneWithoutActivitiesInput;
  project: ProjectCreateNestedOneWithoutActivitiesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityCreateWithoutOutputInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  implementations?: InputMaybe<ActivityImplementationCreateNestedManyWithoutActivityInput>;
  name: Scalars['String'];
  project: ProjectCreateNestedOneWithoutActivitiesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityCreateWithoutProjectInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  implementations?: InputMaybe<ActivityImplementationCreateNestedManyWithoutActivityInput>;
  name: Scalars['String'];
  output: OutputCreateNestedOneWithoutActivitiesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityGroupBy = {
  __typename?: 'ActivityGroupBy';
  _count?: Maybe<ActivityCountAggregate>;
  _max?: Maybe<ActivityMaxAggregate>;
  _min?: Maybe<ActivityMinAggregate>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  outputId: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivityImplementation = {
  __typename?: 'ActivityImplementation';
  _count?: Maybe<ActivityImplementationCount>;
  activity: Activity;
  activityId: Scalars['String'];
  assets: Array<ImplementationAsset>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  participants: Array<ImplementationParticipant>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ActivityImplementationAssetsArgs = {
  cursor?: InputMaybe<ImplementationAssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationAssetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationAssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationAssetWhereInput>;
};


export type ActivityImplementationParticipantsArgs = {
  cursor?: InputMaybe<ImplementationParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};

export type ActivityImplementationCount = {
  __typename?: 'ActivityImplementationCount';
  assets: Scalars['Int'];
  participants: Scalars['Int'];
};

export type ActivityImplementationCountAggregate = {
  __typename?: 'ActivityImplementationCountAggregate';
  _all: Scalars['Int'];
  activityId: Scalars['Int'];
  createdAt: Scalars['Int'];
  dateEnd: Scalars['Int'];
  dateStart: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ActivityImplementationCountOrderByAggregateInput = {
  activityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateEnd?: InputMaybe<SortOrder>;
  dateStart?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActivityImplementationCreateInput = {
  activity: ActivityCreateNestedOneWithoutImplementationsInput;
  assets?: InputMaybe<ImplementationAssetCreateNestedManyWithoutImplementationInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  participants?: InputMaybe<ImplementationParticipantCreateNestedManyWithoutImplementationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityImplementationCreateManyActivityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityImplementationCreateManyActivityInputEnvelope = {
  data: Array<ActivityImplementationCreateManyActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityImplementationCreateManyInput = {
  activityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityImplementationCreateNestedManyWithoutActivityInput = {
  connect?: InputMaybe<Array<ActivityImplementationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityImplementationCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<ActivityImplementationCreateWithoutActivityInput>>;
  createMany?: InputMaybe<ActivityImplementationCreateManyActivityInputEnvelope>;
};

export type ActivityImplementationCreateNestedOneWithoutAssetsInput = {
  connect?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityImplementationCreateOrConnectWithoutAssetsInput>;
  create?: InputMaybe<ActivityImplementationCreateWithoutAssetsInput>;
};

export type ActivityImplementationCreateNestedOneWithoutParticipantsInput = {
  connect?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityImplementationCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<ActivityImplementationCreateWithoutParticipantsInput>;
};

export type ActivityImplementationCreateOrConnectWithoutActivityInput = {
  create: ActivityImplementationCreateWithoutActivityInput;
  where: ActivityImplementationWhereUniqueInput;
};

export type ActivityImplementationCreateOrConnectWithoutAssetsInput = {
  create: ActivityImplementationCreateWithoutAssetsInput;
  where: ActivityImplementationWhereUniqueInput;
};

export type ActivityImplementationCreateOrConnectWithoutParticipantsInput = {
  create: ActivityImplementationCreateWithoutParticipantsInput;
  where: ActivityImplementationWhereUniqueInput;
};

export type ActivityImplementationCreateWithoutActivityInput = {
  assets?: InputMaybe<ImplementationAssetCreateNestedManyWithoutImplementationInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  participants?: InputMaybe<ImplementationParticipantCreateNestedManyWithoutImplementationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityImplementationCreateWithoutAssetsInput = {
  activity: ActivityCreateNestedOneWithoutImplementationsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  participants?: InputMaybe<ImplementationParticipantCreateNestedManyWithoutImplementationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityImplementationCreateWithoutParticipantsInput = {
  activity: ActivityCreateNestedOneWithoutImplementationsInput;
  assets?: InputMaybe<ImplementationAssetCreateNestedManyWithoutImplementationInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityImplementationGroupBy = {
  __typename?: 'ActivityImplementationGroupBy';
  _count?: Maybe<ActivityImplementationCountAggregate>;
  _max?: Maybe<ActivityImplementationMaxAggregate>;
  _min?: Maybe<ActivityImplementationMinAggregate>;
  activityId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivityImplementationListRelationFilter = {
  every?: InputMaybe<ActivityImplementationWhereInput>;
  none?: InputMaybe<ActivityImplementationWhereInput>;
  some?: InputMaybe<ActivityImplementationWhereInput>;
};

export type ActivityImplementationMaxAggregate = {
  __typename?: 'ActivityImplementationMaxAggregate';
  activityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivityImplementationMaxOrderByAggregateInput = {
  activityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateEnd?: InputMaybe<SortOrder>;
  dateStart?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActivityImplementationMinAggregate = {
  __typename?: 'ActivityImplementationMinAggregate';
  activityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivityImplementationMinOrderByAggregateInput = {
  activityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateEnd?: InputMaybe<SortOrder>;
  dateStart?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActivityImplementationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityImplementationOrderByWithAggregationInput = {
  _count?: InputMaybe<ActivityImplementationCountOrderByAggregateInput>;
  _max?: InputMaybe<ActivityImplementationMaxOrderByAggregateInput>;
  _min?: InputMaybe<ActivityImplementationMinOrderByAggregateInput>;
  activityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  dateEnd?: InputMaybe<SortOrder>;
  dateStart?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type ActivityImplementationOrderByWithRelationInput = {
  activity?: InputMaybe<ActivityOrderByWithRelationInput>;
  activityId?: InputMaybe<SortOrder>;
  assets?: InputMaybe<ImplementationAssetOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  dateEnd?: InputMaybe<SortOrder>;
  dateStart?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  participants?: InputMaybe<ImplementationParticipantOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type ActivityImplementationRelationFilter = {
  is?: InputMaybe<ActivityImplementationWhereInput>;
  isNot?: InputMaybe<ActivityImplementationWhereInput>;
};

export enum ActivityImplementationScalarFieldEnum {
  ActivityId = 'activityId',
  CreatedAt = 'createdAt',
  DateEnd = 'dateEnd',
  DateStart = 'dateStart',
  Description = 'description',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type ActivityImplementationScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityImplementationScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityImplementationScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityImplementationScalarWhereInput>>;
  activityId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  dateEnd?: InputMaybe<DateTimeFilter>;
  dateStart?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ActivityImplementationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ActivityImplementationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ActivityImplementationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ActivityImplementationScalarWhereWithAggregatesInput>>;
  activityId?: InputMaybe<UuidWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  dateEnd?: InputMaybe<DateTimeWithAggregatesFilter>;
  dateStart?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type ActivityImplementationUpdateInput = {
  activity?: InputMaybe<ActivityUpdateOneRequiredWithoutImplementationsNestedInput>;
  assets?: InputMaybe<ImplementationAssetUpdateManyWithoutImplementationNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateEnd?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<ImplementationParticipantUpdateManyWithoutImplementationNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityImplementationUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateEnd?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityImplementationUpdateManyWithWhereWithoutActivityInput = {
  data: ActivityImplementationUpdateManyMutationInput;
  where: ActivityImplementationScalarWhereInput;
};

export type ActivityImplementationUpdateManyWithoutActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityImplementationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityImplementationCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<ActivityImplementationCreateWithoutActivityInput>>;
  createMany?: InputMaybe<ActivityImplementationCreateManyActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityImplementationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityImplementationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityImplementationWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityImplementationWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityImplementationUpdateWithWhereUniqueWithoutActivityInput>>;
  updateMany?: InputMaybe<Array<ActivityImplementationUpdateManyWithWhereWithoutActivityInput>>;
  upsert?: InputMaybe<Array<ActivityImplementationUpsertWithWhereUniqueWithoutActivityInput>>;
};

export type ActivityImplementationUpdateOneRequiredWithoutAssetsNestedInput = {
  connect?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityImplementationCreateOrConnectWithoutAssetsInput>;
  create?: InputMaybe<ActivityImplementationCreateWithoutAssetsInput>;
  update?: InputMaybe<ActivityImplementationUpdateWithoutAssetsInput>;
  upsert?: InputMaybe<ActivityImplementationUpsertWithoutAssetsInput>;
};

export type ActivityImplementationUpdateOneRequiredWithoutParticipantsNestedInput = {
  connect?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityImplementationCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<ActivityImplementationCreateWithoutParticipantsInput>;
  update?: InputMaybe<ActivityImplementationUpdateWithoutParticipantsInput>;
  upsert?: InputMaybe<ActivityImplementationUpsertWithoutParticipantsInput>;
};

export type ActivityImplementationUpdateWithWhereUniqueWithoutActivityInput = {
  data: ActivityImplementationUpdateWithoutActivityInput;
  where: ActivityImplementationWhereUniqueInput;
};

export type ActivityImplementationUpdateWithoutActivityInput = {
  assets?: InputMaybe<ImplementationAssetUpdateManyWithoutImplementationNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateEnd?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<ImplementationParticipantUpdateManyWithoutImplementationNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityImplementationUpdateWithoutAssetsInput = {
  activity?: InputMaybe<ActivityUpdateOneRequiredWithoutImplementationsNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateEnd?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<ImplementationParticipantUpdateManyWithoutImplementationNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityImplementationUpdateWithoutParticipantsInput = {
  activity?: InputMaybe<ActivityUpdateOneRequiredWithoutImplementationsNestedInput>;
  assets?: InputMaybe<ImplementationAssetUpdateManyWithoutImplementationNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateEnd?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateStart?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityImplementationUpsertWithWhereUniqueWithoutActivityInput = {
  create: ActivityImplementationCreateWithoutActivityInput;
  update: ActivityImplementationUpdateWithoutActivityInput;
  where: ActivityImplementationWhereUniqueInput;
};

export type ActivityImplementationUpsertWithoutAssetsInput = {
  create: ActivityImplementationCreateWithoutAssetsInput;
  update: ActivityImplementationUpdateWithoutAssetsInput;
};

export type ActivityImplementationUpsertWithoutParticipantsInput = {
  create: ActivityImplementationCreateWithoutParticipantsInput;
  update: ActivityImplementationUpdateWithoutParticipantsInput;
};

export type ActivityImplementationWhereInput = {
  AND?: InputMaybe<Array<ActivityImplementationWhereInput>>;
  NOT?: InputMaybe<Array<ActivityImplementationWhereInput>>;
  OR?: InputMaybe<Array<ActivityImplementationWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<UuidFilter>;
  assets?: InputMaybe<ImplementationAssetListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  dateEnd?: InputMaybe<DateTimeFilter>;
  dateStart?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  participants?: InputMaybe<ImplementationParticipantListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ActivityImplementationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ActivityListRelationFilter = {
  every?: InputMaybe<ActivityWhereInput>;
  none?: InputMaybe<ActivityWhereInput>;
  some?: InputMaybe<ActivityWhereInput>;
};

export type ActivityMaxAggregate = {
  __typename?: 'ActivityMaxAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outputId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivityMaxOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outputId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActivityMinAggregate = {
  __typename?: 'ActivityMinAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outputId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivityMinOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outputId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityOrderByWithAggregationInput = {
  _count?: InputMaybe<ActivityCountOrderByAggregateInput>;
  _max?: InputMaybe<ActivityMaxOrderByAggregateInput>;
  _min?: InputMaybe<ActivityMinOrderByAggregateInput>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outputId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type ActivityOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  implementations?: InputMaybe<ActivityImplementationOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  output?: InputMaybe<OutputOrderByWithRelationInput>;
  outputId?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type ActivityRelationFilter = {
  is?: InputMaybe<ActivityWhereInput>;
  isNot?: InputMaybe<ActivityWhereInput>;
};

export enum ActivityScalarFieldEnum {
  Code = 'code',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  OutputId = 'outputId',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt'
}

export type ActivityScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  outputId?: InputMaybe<UuidFilter>;
  projectId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ActivityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ActivityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ActivityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ActivityScalarWhereWithAggregatesInput>>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  outputId?: InputMaybe<UuidWithAggregatesFilter>;
  projectId?: InputMaybe<UuidWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type ActivityUpdateInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implementations?: InputMaybe<ActivityImplementationUpdateManyWithoutActivityNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  output?: InputMaybe<OutputUpdateOneRequiredWithoutActivitiesNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutActivitiesNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityUpdateManyMutationInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityUpdateManyWithWhereWithoutOutputInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutProjectInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithoutOutputNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutOutputInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutOutputInput>>;
  createMany?: InputMaybe<ActivityCreateManyOutputInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutOutputInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutOutputInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutOutputInput>>;
};

export type ActivityUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutProjectInput>>;
  createMany?: InputMaybe<ActivityCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type ActivityUpdateOneRequiredWithoutImplementationsNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutImplementationsInput>;
  create?: InputMaybe<ActivityCreateWithoutImplementationsInput>;
  update?: InputMaybe<ActivityUpdateWithoutImplementationsInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutImplementationsInput>;
};

export type ActivityUpdateWithWhereUniqueWithoutOutputInput = {
  data: ActivityUpdateWithoutOutputInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutProjectInput = {
  data: ActivityUpdateWithoutProjectInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithoutImplementationsInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  output?: InputMaybe<OutputUpdateOneRequiredWithoutActivitiesNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutActivitiesNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutOutputInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implementations?: InputMaybe<ActivityImplementationUpdateManyWithoutActivityNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutActivitiesNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutProjectInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implementations?: InputMaybe<ActivityImplementationUpdateManyWithoutActivityNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  output?: InputMaybe<OutputUpdateOneRequiredWithoutActivitiesNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ActivityUpsertWithWhereUniqueWithoutOutputInput = {
  create: ActivityCreateWithoutOutputInput;
  update: ActivityUpdateWithoutOutputInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutProjectInput = {
  create: ActivityCreateWithoutProjectInput;
  update: ActivityUpdateWithoutProjectInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithoutImplementationsInput = {
  create: ActivityCreateWithoutImplementationsInput;
  update: ActivityUpdateWithoutImplementationsInput;
};

export type ActivityWhereInput = {
  AND?: InputMaybe<Array<ActivityWhereInput>>;
  NOT?: InputMaybe<Array<ActivityWhereInput>>;
  OR?: InputMaybe<Array<ActivityWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  implementations?: InputMaybe<ActivityImplementationListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  output?: InputMaybe<OutputRelationFilter>;
  outputId?: InputMaybe<UuidFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ActivityWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateActivity = {
  __typename?: 'AggregateActivity';
  _count?: Maybe<ActivityCountAggregate>;
  _max?: Maybe<ActivityMaxAggregate>;
  _min?: Maybe<ActivityMinAggregate>;
};

export type AggregateActivityImplementation = {
  __typename?: 'AggregateActivityImplementation';
  _count?: Maybe<ActivityImplementationCountAggregate>;
  _max?: Maybe<ActivityImplementationMaxAggregate>;
  _min?: Maybe<ActivityImplementationMinAggregate>;
};

export type AggregateBeneficiary = {
  __typename?: 'AggregateBeneficiary';
  _avg?: Maybe<BeneficiaryAvgAggregate>;
  _count?: Maybe<BeneficiaryCountAggregate>;
  _max?: Maybe<BeneficiaryMaxAggregate>;
  _min?: Maybe<BeneficiaryMinAggregate>;
  _sum?: Maybe<BeneficiarySumAggregate>;
};

export type AggregateBeneficiaryGroup = {
  __typename?: 'AggregateBeneficiaryGroup';
  _count?: Maybe<BeneficiaryGroupCountAggregate>;
  _max?: Maybe<BeneficiaryGroupMaxAggregate>;
  _min?: Maybe<BeneficiaryGroupMinAggregate>;
};

export type AggregateGroup = {
  __typename?: 'AggregateGroup';
  _avg?: Maybe<GroupAvgAggregate>;
  _count?: Maybe<GroupCountAggregate>;
  _max?: Maybe<GroupMaxAggregate>;
  _min?: Maybe<GroupMinAggregate>;
  _sum?: Maybe<GroupSumAggregate>;
};

export type AggregateImplementationAsset = {
  __typename?: 'AggregateImplementationAsset';
  _count?: Maybe<ImplementationAssetCountAggregate>;
  _max?: Maybe<ImplementationAssetMaxAggregate>;
  _min?: Maybe<ImplementationAssetMinAggregate>;
};

export type AggregateImplementationParticipant = {
  __typename?: 'AggregateImplementationParticipant';
  _count?: Maybe<ImplementationParticipantCountAggregate>;
  _max?: Maybe<ImplementationParticipantMaxAggregate>;
  _min?: Maybe<ImplementationParticipantMinAggregate>;
};

export type AggregateLocation = {
  __typename?: 'AggregateLocation';
  _avg?: Maybe<LocationAvgAggregate>;
  _count?: Maybe<LocationCountAggregate>;
  _max?: Maybe<LocationMaxAggregate>;
  _min?: Maybe<LocationMinAggregate>;
  _sum?: Maybe<LocationSumAggregate>;
};

export type AggregateOrganization = {
  __typename?: 'AggregateOrganization';
  _count?: Maybe<OrganizationCountAggregate>;
  _max?: Maybe<OrganizationMaxAggregate>;
  _min?: Maybe<OrganizationMinAggregate>;
};

export type AggregateOutput = {
  __typename?: 'AggregateOutput';
  _count?: Maybe<OutputCountAggregate>;
  _max?: Maybe<OutputMaxAggregate>;
  _min?: Maybe<OutputMinAggregate>;
};

export type AggregateProject = {
  __typename?: 'AggregateProject';
  _count?: Maybe<ProjectCountAggregate>;
  _max?: Maybe<ProjectMaxAggregate>;
  _min?: Maybe<ProjectMinAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type Beneficiary = {
  __typename?: 'Beneficiary';
  _count?: Maybe<BeneficiaryCount>;
  age?: Maybe<Scalars['Int']>;
  attends: Array<ImplementationParticipant>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  groups: Array<BeneficiaryGroup>;
  id: Scalars['String'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  project: Project;
  projectId: Scalars['String'];
  sex: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type BeneficiaryAttendsArgs = {
  cursor?: InputMaybe<ImplementationParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};


export type BeneficiaryGroupsArgs = {
  cursor?: InputMaybe<BeneficiaryGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};

export type BeneficiaryAvgAggregate = {
  __typename?: 'BeneficiaryAvgAggregate';
  age?: Maybe<Scalars['Float']>;
};

export type BeneficiaryAvgOrderByAggregateInput = {
  age?: InputMaybe<SortOrder>;
};

export type BeneficiaryCount = {
  __typename?: 'BeneficiaryCount';
  attends: Scalars['Int'];
  groups: Scalars['Int'];
};

export type BeneficiaryCountAggregate = {
  __typename?: 'BeneficiaryCountAggregate';
  _all: Scalars['Int'];
  age: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  fullName: Scalars['Int'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  phone: Scalars['Int'];
  projectId: Scalars['Int'];
  sex: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BeneficiaryCountOrderByAggregateInput = {
  age?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryCreateInput = {
  age?: InputMaybe<Scalars['Int']>;
  attends?: InputMaybe<ImplementationParticipantCreateNestedManyWithoutBeneficiaryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  groups?: InputMaybe<BeneficiaryGroupCreateNestedManyWithoutBeneficiaryInput>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationCreateNestedOneWithoutBeneficiariesInput>;
  phone?: InputMaybe<Scalars['String']>;
  project: ProjectCreateNestedOneWithoutBeneficiariesInput;
  sex: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryCreateManyInput = {
  age?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  sex: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryCreateManyLocationInput = {
  age?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  sex: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryCreateManyLocationInputEnvelope = {
  data: Array<BeneficiaryCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeneficiaryCreateManyProjectInput = {
  age?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  sex: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryCreateManyProjectInputEnvelope = {
  data: Array<BeneficiaryCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeneficiaryCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutLocationInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyLocationInputEnvelope>;
};

export type BeneficiaryCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutProjectInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyProjectInputEnvelope>;
};

export type BeneficiaryCreateNestedOneWithoutAttendsInput = {
  connect?: InputMaybe<BeneficiaryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeneficiaryCreateOrConnectWithoutAttendsInput>;
  create?: InputMaybe<BeneficiaryCreateWithoutAttendsInput>;
};

export type BeneficiaryCreateNestedOneWithoutGroupsInput = {
  connect?: InputMaybe<BeneficiaryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeneficiaryCreateOrConnectWithoutGroupsInput>;
  create?: InputMaybe<BeneficiaryCreateWithoutGroupsInput>;
};

export type BeneficiaryCreateOrConnectWithoutAttendsInput = {
  create: BeneficiaryCreateWithoutAttendsInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryCreateOrConnectWithoutGroupsInput = {
  create: BeneficiaryCreateWithoutGroupsInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryCreateOrConnectWithoutLocationInput = {
  create: BeneficiaryCreateWithoutLocationInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryCreateOrConnectWithoutProjectInput = {
  create: BeneficiaryCreateWithoutProjectInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryCreateWithoutAttendsInput = {
  age?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  groups?: InputMaybe<BeneficiaryGroupCreateNestedManyWithoutBeneficiaryInput>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationCreateNestedOneWithoutBeneficiariesInput>;
  phone?: InputMaybe<Scalars['String']>;
  project: ProjectCreateNestedOneWithoutBeneficiariesInput;
  sex: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryCreateWithoutGroupsInput = {
  age?: InputMaybe<Scalars['Int']>;
  attends?: InputMaybe<ImplementationParticipantCreateNestedManyWithoutBeneficiaryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationCreateNestedOneWithoutBeneficiariesInput>;
  phone?: InputMaybe<Scalars['String']>;
  project: ProjectCreateNestedOneWithoutBeneficiariesInput;
  sex: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryCreateWithoutLocationInput = {
  age?: InputMaybe<Scalars['Int']>;
  attends?: InputMaybe<ImplementationParticipantCreateNestedManyWithoutBeneficiaryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  groups?: InputMaybe<BeneficiaryGroupCreateNestedManyWithoutBeneficiaryInput>;
  id?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  project: ProjectCreateNestedOneWithoutBeneficiariesInput;
  sex: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryCreateWithoutProjectInput = {
  age?: InputMaybe<Scalars['Int']>;
  attends?: InputMaybe<ImplementationParticipantCreateNestedManyWithoutBeneficiaryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  groups?: InputMaybe<BeneficiaryGroupCreateNestedManyWithoutBeneficiaryInput>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationCreateNestedOneWithoutBeneficiariesInput>;
  phone?: InputMaybe<Scalars['String']>;
  sex: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryGroup = {
  __typename?: 'BeneficiaryGroup';
  beneficiary: Beneficiary;
  beneficiaryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  group: Group;
  groupId: Scalars['String'];
  id: Scalars['String'];
  membership?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupBy = {
  __typename?: 'BeneficiaryGroupBy';
  _avg?: Maybe<BeneficiaryAvgAggregate>;
  _count?: Maybe<BeneficiaryCountAggregate>;
  _max?: Maybe<BeneficiaryMaxAggregate>;
  _min?: Maybe<BeneficiaryMinAggregate>;
  _sum?: Maybe<BeneficiarySumAggregate>;
  age?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  sex: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupCountAggregate = {
  __typename?: 'BeneficiaryGroupCountAggregate';
  _all: Scalars['Int'];
  beneficiaryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  groupId: Scalars['Int'];
  id: Scalars['Int'];
  membership: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BeneficiaryGroupCountOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  membership?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryGroupCreateInput = {
  beneficiary: BeneficiaryCreateNestedOneWithoutGroupsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  group: GroupCreateNestedOneWithoutBeneficiariesInput;
  id?: InputMaybe<Scalars['String']>;
  membership?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupCreateManyBeneficiaryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  groupId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  membership?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupCreateManyBeneficiaryInputEnvelope = {
  data: Array<BeneficiaryGroupCreateManyBeneficiaryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeneficiaryGroupCreateManyGroupInput = {
  beneficiaryId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  membership?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupCreateManyGroupInputEnvelope = {
  data: Array<BeneficiaryGroupCreateManyGroupInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeneficiaryGroupCreateManyInput = {
  beneficiaryId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  groupId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  membership?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupCreateNestedManyWithoutBeneficiaryInput = {
  connect?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryGroupCreateOrConnectWithoutBeneficiaryInput>>;
  create?: InputMaybe<Array<BeneficiaryGroupCreateWithoutBeneficiaryInput>>;
  createMany?: InputMaybe<BeneficiaryGroupCreateManyBeneficiaryInputEnvelope>;
};

export type BeneficiaryGroupCreateNestedManyWithoutGroupInput = {
  connect?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryGroupCreateOrConnectWithoutGroupInput>>;
  create?: InputMaybe<Array<BeneficiaryGroupCreateWithoutGroupInput>>;
  createMany?: InputMaybe<BeneficiaryGroupCreateManyGroupInputEnvelope>;
};

export type BeneficiaryGroupCreateOrConnectWithoutBeneficiaryInput = {
  create: BeneficiaryGroupCreateWithoutBeneficiaryInput;
  where: BeneficiaryGroupWhereUniqueInput;
};

export type BeneficiaryGroupCreateOrConnectWithoutGroupInput = {
  create: BeneficiaryGroupCreateWithoutGroupInput;
  where: BeneficiaryGroupWhereUniqueInput;
};

export type BeneficiaryGroupCreateWithoutBeneficiaryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  group: GroupCreateNestedOneWithoutBeneficiariesInput;
  id?: InputMaybe<Scalars['String']>;
  membership?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupCreateWithoutGroupInput = {
  beneficiary: BeneficiaryCreateNestedOneWithoutGroupsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  membership?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupGroupBy = {
  __typename?: 'BeneficiaryGroupGroupBy';
  _count?: Maybe<BeneficiaryGroupCountAggregate>;
  _max?: Maybe<BeneficiaryGroupMaxAggregate>;
  _min?: Maybe<BeneficiaryGroupMinAggregate>;
  beneficiaryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  groupId: Scalars['String'];
  id: Scalars['String'];
  membership?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupListRelationFilter = {
  every?: InputMaybe<BeneficiaryGroupWhereInput>;
  none?: InputMaybe<BeneficiaryGroupWhereInput>;
  some?: InputMaybe<BeneficiaryGroupWhereInput>;
};

export type BeneficiaryGroupMaxAggregate = {
  __typename?: 'BeneficiaryGroupMaxAggregate';
  beneficiaryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  membership?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupMaxOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  membership?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryGroupMinAggregate = {
  __typename?: 'BeneficiaryGroupMinAggregate';
  beneficiaryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  membership?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BeneficiaryGroupMinOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  membership?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryGroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeneficiaryGroupOrderByWithAggregationInput = {
  _count?: InputMaybe<BeneficiaryGroupCountOrderByAggregateInput>;
  _max?: InputMaybe<BeneficiaryGroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<BeneficiaryGroupMinOrderByAggregateInput>;
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  membership?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type BeneficiaryGroupOrderByWithRelationInput = {
  beneficiary?: InputMaybe<BeneficiaryOrderByWithRelationInput>;
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  group?: InputMaybe<GroupOrderByWithRelationInput>;
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  membership?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export enum BeneficiaryGroupScalarFieldEnum {
  BeneficiaryId = 'beneficiaryId',
  CreatedAt = 'createdAt',
  GroupId = 'groupId',
  Id = 'id',
  Membership = 'membership',
  UpdatedAt = 'updatedAt'
}

export type BeneficiaryGroupScalarWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryGroupScalarWhereInput>>;
  beneficiaryId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  groupId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  membership?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type BeneficiaryGroupScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BeneficiaryGroupScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BeneficiaryGroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BeneficiaryGroupScalarWhereWithAggregatesInput>>;
  beneficiaryId?: InputMaybe<UuidWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  groupId?: InputMaybe<UuidWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  membership?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type BeneficiaryGroupUpdateInput = {
  beneficiary?: InputMaybe<BeneficiaryUpdateOneRequiredWithoutGroupsNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<GroupUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  membership?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryGroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  membership?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryGroupUpdateManyWithWhereWithoutBeneficiaryInput = {
  data: BeneficiaryGroupUpdateManyMutationInput;
  where: BeneficiaryGroupScalarWhereInput;
};

export type BeneficiaryGroupUpdateManyWithWhereWithoutGroupInput = {
  data: BeneficiaryGroupUpdateManyMutationInput;
  where: BeneficiaryGroupScalarWhereInput;
};

export type BeneficiaryGroupUpdateManyWithoutBeneficiaryNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryGroupCreateOrConnectWithoutBeneficiaryInput>>;
  create?: InputMaybe<Array<BeneficiaryGroupCreateWithoutBeneficiaryInput>>;
  createMany?: InputMaybe<BeneficiaryGroupCreateManyBeneficiaryInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<BeneficiaryGroupUpdateWithWhereUniqueWithoutBeneficiaryInput>>;
  updateMany?: InputMaybe<Array<BeneficiaryGroupUpdateManyWithWhereWithoutBeneficiaryInput>>;
  upsert?: InputMaybe<Array<BeneficiaryGroupUpsertWithWhereUniqueWithoutBeneficiaryInput>>;
};

export type BeneficiaryGroupUpdateManyWithoutGroupNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryGroupCreateOrConnectWithoutGroupInput>>;
  create?: InputMaybe<Array<BeneficiaryGroupCreateWithoutGroupInput>>;
  createMany?: InputMaybe<BeneficiaryGroupCreateManyGroupInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<BeneficiaryGroupUpdateWithWhereUniqueWithoutGroupInput>>;
  updateMany?: InputMaybe<Array<BeneficiaryGroupUpdateManyWithWhereWithoutGroupInput>>;
  upsert?: InputMaybe<Array<BeneficiaryGroupUpsertWithWhereUniqueWithoutGroupInput>>;
};

export type BeneficiaryGroupUpdateWithWhereUniqueWithoutBeneficiaryInput = {
  data: BeneficiaryGroupUpdateWithoutBeneficiaryInput;
  where: BeneficiaryGroupWhereUniqueInput;
};

export type BeneficiaryGroupUpdateWithWhereUniqueWithoutGroupInput = {
  data: BeneficiaryGroupUpdateWithoutGroupInput;
  where: BeneficiaryGroupWhereUniqueInput;
};

export type BeneficiaryGroupUpdateWithoutBeneficiaryInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<GroupUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  membership?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryGroupUpdateWithoutGroupInput = {
  beneficiary?: InputMaybe<BeneficiaryUpdateOneRequiredWithoutGroupsNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  membership?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryGroupUpsertWithWhereUniqueWithoutBeneficiaryInput = {
  create: BeneficiaryGroupCreateWithoutBeneficiaryInput;
  update: BeneficiaryGroupUpdateWithoutBeneficiaryInput;
  where: BeneficiaryGroupWhereUniqueInput;
};

export type BeneficiaryGroupUpsertWithWhereUniqueWithoutGroupInput = {
  create: BeneficiaryGroupCreateWithoutGroupInput;
  update: BeneficiaryGroupUpdateWithoutGroupInput;
  where: BeneficiaryGroupWhereUniqueInput;
};

export type BeneficiaryGroupWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryGroupWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryGroupWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryGroupWhereInput>>;
  beneficiary?: InputMaybe<BeneficiaryRelationFilter>;
  beneficiaryId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  group?: InputMaybe<GroupRelationFilter>;
  groupId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  membership?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type BeneficiaryGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BeneficiaryListRelationFilter = {
  every?: InputMaybe<BeneficiaryWhereInput>;
  none?: InputMaybe<BeneficiaryWhereInput>;
  some?: InputMaybe<BeneficiaryWhereInput>;
};

export type BeneficiaryMaxAggregate = {
  __typename?: 'BeneficiaryMaxAggregate';
  age?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BeneficiaryMaxOrderByAggregateInput = {
  age?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryMinAggregate = {
  __typename?: 'BeneficiaryMinAggregate';
  age?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BeneficiaryMinOrderByAggregateInput = {
  age?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeneficiaryOrderByWithAggregationInput = {
  _avg?: InputMaybe<BeneficiaryAvgOrderByAggregateInput>;
  _count?: InputMaybe<BeneficiaryCountOrderByAggregateInput>;
  _max?: InputMaybe<BeneficiaryMaxOrderByAggregateInput>;
  _min?: InputMaybe<BeneficiaryMinOrderByAggregateInput>;
  _sum?: InputMaybe<BeneficiarySumOrderByAggregateInput>;
  age?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrderInput>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type BeneficiaryOrderByWithRelationInput = {
  age?: InputMaybe<SortOrderInput>;
  attends?: InputMaybe<ImplementationParticipantOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrderInput>;
  fullName?: InputMaybe<SortOrder>;
  groups?: InputMaybe<BeneficiaryGroupOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  locationId?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type BeneficiaryRelationFilter = {
  is?: InputMaybe<BeneficiaryWhereInput>;
  isNot?: InputMaybe<BeneficiaryWhereInput>;
};

export enum BeneficiaryScalarFieldEnum {
  Age = 'age',
  CreatedAt = 'createdAt',
  Email = 'email',
  FullName = 'fullName',
  Id = 'id',
  LocationId = 'locationId',
  Phone = 'phone',
  ProjectId = 'projectId',
  Sex = 'sex',
  UpdatedAt = 'updatedAt'
}

export type BeneficiaryScalarWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  age?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  locationId?: InputMaybe<UuidNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  projectId?: InputMaybe<UuidFilter>;
  sex?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type BeneficiaryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BeneficiaryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BeneficiaryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BeneficiaryScalarWhereWithAggregatesInput>>;
  age?: InputMaybe<IntNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  email?: InputMaybe<StringNullableWithAggregatesFilter>;
  fullName?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  locationId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  projectId?: InputMaybe<UuidWithAggregatesFilter>;
  sex?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type BeneficiarySumAggregate = {
  __typename?: 'BeneficiarySumAggregate';
  age?: Maybe<Scalars['Int']>;
};

export type BeneficiarySumOrderByAggregateInput = {
  age?: InputMaybe<SortOrder>;
};

export type BeneficiaryUpdateInput = {
  age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  attends?: InputMaybe<ImplementationParticipantUpdateManyWithoutBeneficiaryNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  groups?: InputMaybe<BeneficiaryGroupUpdateManyWithoutBeneficiaryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutBeneficiariesNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  sex?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryUpdateManyMutationInput = {
  age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryUpdateManyWithWhereWithoutLocationInput = {
  data: BeneficiaryUpdateManyMutationInput;
  where: BeneficiaryScalarWhereInput;
};

export type BeneficiaryUpdateManyWithWhereWithoutProjectInput = {
  data: BeneficiaryUpdateManyMutationInput;
  where: BeneficiaryScalarWhereInput;
};

export type BeneficiaryUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutLocationInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  update?: InputMaybe<Array<BeneficiaryUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: InputMaybe<Array<BeneficiaryUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: InputMaybe<Array<BeneficiaryUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type BeneficiaryUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutProjectInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  update?: InputMaybe<Array<BeneficiaryUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<BeneficiaryUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<BeneficiaryUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type BeneficiaryUpdateOneRequiredWithoutAttendsNestedInput = {
  connect?: InputMaybe<BeneficiaryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeneficiaryCreateOrConnectWithoutAttendsInput>;
  create?: InputMaybe<BeneficiaryCreateWithoutAttendsInput>;
  update?: InputMaybe<BeneficiaryUpdateWithoutAttendsInput>;
  upsert?: InputMaybe<BeneficiaryUpsertWithoutAttendsInput>;
};

export type BeneficiaryUpdateOneRequiredWithoutGroupsNestedInput = {
  connect?: InputMaybe<BeneficiaryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeneficiaryCreateOrConnectWithoutGroupsInput>;
  create?: InputMaybe<BeneficiaryCreateWithoutGroupsInput>;
  update?: InputMaybe<BeneficiaryUpdateWithoutGroupsInput>;
  upsert?: InputMaybe<BeneficiaryUpsertWithoutGroupsInput>;
};

export type BeneficiaryUpdateWithWhereUniqueWithoutLocationInput = {
  data: BeneficiaryUpdateWithoutLocationInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpdateWithWhereUniqueWithoutProjectInput = {
  data: BeneficiaryUpdateWithoutProjectInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpdateWithoutAttendsInput = {
  age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  groups?: InputMaybe<BeneficiaryGroupUpdateManyWithoutBeneficiaryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutBeneficiariesNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  sex?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryUpdateWithoutGroupsInput = {
  age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  attends?: InputMaybe<ImplementationParticipantUpdateManyWithoutBeneficiaryNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutBeneficiariesNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  sex?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryUpdateWithoutLocationInput = {
  age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  attends?: InputMaybe<ImplementationParticipantUpdateManyWithoutBeneficiaryNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  groups?: InputMaybe<BeneficiaryGroupUpdateManyWithoutBeneficiaryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  sex?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryUpdateWithoutProjectInput = {
  age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  attends?: InputMaybe<ImplementationParticipantUpdateManyWithoutBeneficiaryNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  groups?: InputMaybe<BeneficiaryGroupUpdateManyWithoutBeneficiaryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutBeneficiariesNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryUpsertWithWhereUniqueWithoutLocationInput = {
  create: BeneficiaryCreateWithoutLocationInput;
  update: BeneficiaryUpdateWithoutLocationInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpsertWithWhereUniqueWithoutProjectInput = {
  create: BeneficiaryCreateWithoutProjectInput;
  update: BeneficiaryUpdateWithoutProjectInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpsertWithoutAttendsInput = {
  create: BeneficiaryCreateWithoutAttendsInput;
  update: BeneficiaryUpdateWithoutAttendsInput;
};

export type BeneficiaryUpsertWithoutGroupsInput = {
  create: BeneficiaryCreateWithoutGroupsInput;
  update: BeneficiaryUpdateWithoutGroupsInput;
};

export type BeneficiaryWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryWhereInput>>;
  age?: InputMaybe<IntNullableFilter>;
  attends?: InputMaybe<ImplementationParticipantListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringFilter>;
  groups?: InputMaybe<BeneficiaryGroupListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  locationId?: InputMaybe<UuidNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<UuidFilter>;
  sex?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type BeneficiaryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type Group = {
  __typename?: 'Group';
  _count?: Maybe<GroupCount>;
  beneficiaries: Array<BeneficiaryGroup>;
  children: Array<Group>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: Maybe<Group>;
  parentId?: Maybe<Scalars['String']>;
  project: Project;
  projectId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type GroupBeneficiariesArgs = {
  cursor?: InputMaybe<BeneficiaryGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};


export type GroupChildrenArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};

export type GroupAvgAggregate = {
  __typename?: 'GroupAvgAggregate';
  level?: Maybe<Scalars['Float']>;
};

export type GroupAvgOrderByAggregateInput = {
  level?: InputMaybe<SortOrder>;
};

export type GroupCount = {
  __typename?: 'GroupCount';
  beneficiaries: Scalars['Int'];
  children: Scalars['Int'];
};

export type GroupCountAggregate = {
  __typename?: 'GroupCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isLeaf: Scalars['Int'];
  level: Scalars['Int'];
  name: Scalars['Int'];
  parentId: Scalars['Int'];
  projectId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type GroupCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GroupCreateInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupCreateNestedManyWithoutGroupInput>;
  children?: InputMaybe<GroupCreateNestedManyWithoutParentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: InputMaybe<GroupCreateNestedOneWithoutChildrenInput>;
  project: ProjectCreateNestedOneWithoutGroupsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateManyParentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateManyParentInputEnvelope = {
  data: Array<GroupCreateManyParentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GroupCreateManyProjectInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateManyProjectInputEnvelope = {
  data: Array<GroupCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GroupCreateNestedManyWithoutParentInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutParentInput>>;
  createMany?: InputMaybe<GroupCreateManyParentInputEnvelope>;
};

export type GroupCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutProjectInput>>;
  createMany?: InputMaybe<GroupCreateManyProjectInputEnvelope>;
};

export type GroupCreateNestedOneWithoutBeneficiariesInput = {
  connect?: InputMaybe<GroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GroupCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<GroupCreateWithoutBeneficiariesInput>;
};

export type GroupCreateNestedOneWithoutChildrenInput = {
  connect?: InputMaybe<GroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GroupCreateOrConnectWithoutChildrenInput>;
  create?: InputMaybe<GroupCreateWithoutChildrenInput>;
};

export type GroupCreateOrConnectWithoutBeneficiariesInput = {
  create: GroupCreateWithoutBeneficiariesInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateOrConnectWithoutChildrenInput = {
  create: GroupCreateWithoutChildrenInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateOrConnectWithoutParentInput = {
  create: GroupCreateWithoutParentInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateOrConnectWithoutProjectInput = {
  create: GroupCreateWithoutProjectInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateWithoutBeneficiariesInput = {
  children?: InputMaybe<GroupCreateNestedManyWithoutParentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: InputMaybe<GroupCreateNestedOneWithoutChildrenInput>;
  project: ProjectCreateNestedOneWithoutGroupsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateWithoutChildrenInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupCreateNestedManyWithoutGroupInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: InputMaybe<GroupCreateNestedOneWithoutChildrenInput>;
  project: ProjectCreateNestedOneWithoutGroupsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateWithoutParentInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupCreateNestedManyWithoutGroupInput>;
  children?: InputMaybe<GroupCreateNestedManyWithoutParentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  project: ProjectCreateNestedOneWithoutGroupsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupCreateWithoutProjectInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupCreateNestedManyWithoutGroupInput>;
  children?: InputMaybe<GroupCreateNestedManyWithoutParentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: InputMaybe<GroupCreateNestedOneWithoutChildrenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupGroupBy = {
  __typename?: 'GroupGroupBy';
  _avg?: Maybe<GroupAvgAggregate>;
  _count?: Maybe<GroupCountAggregate>;
  _max?: Maybe<GroupMaxAggregate>;
  _min?: Maybe<GroupMinAggregate>;
  _sum?: Maybe<GroupSumAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupListRelationFilter = {
  every?: InputMaybe<GroupWhereInput>;
  none?: InputMaybe<GroupWhereInput>;
  some?: InputMaybe<GroupWhereInput>;
};

export type GroupMaxAggregate = {
  __typename?: 'GroupMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isLeaf?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GroupMinAggregate = {
  __typename?: 'GroupMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isLeaf?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GroupOrderByWithAggregationInput = {
  _avg?: InputMaybe<GroupAvgOrderByAggregateInput>;
  _count?: InputMaybe<GroupCountOrderByAggregateInput>;
  _max?: InputMaybe<GroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<GroupMinOrderByAggregateInput>;
  _sum?: InputMaybe<GroupSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type GroupOrderByWithRelationInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupOrderByRelationAggregateInput>;
  children?: InputMaybe<GroupOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parent?: InputMaybe<GroupOrderByWithRelationInput>;
  parentId?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type GroupRelationFilter = {
  is?: InputMaybe<GroupWhereInput>;
  isNot?: InputMaybe<GroupWhereInput>;
};

export enum GroupScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsLeaf = 'isLeaf',
  Level = 'level',
  Name = 'name',
  ParentId = 'parentId',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt'
}

export type GroupScalarWhereInput = {
  AND?: InputMaybe<Array<GroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<GroupScalarWhereInput>>;
  OR?: InputMaybe<Array<GroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isLeaf?: InputMaybe<BoolFilter>;
  level?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<UuidNullableFilter>;
  projectId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type GroupScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<GroupScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<GroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<GroupScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  isLeaf?: InputMaybe<BoolWithAggregatesFilter>;
  level?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  parentId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  projectId?: InputMaybe<UuidWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type GroupSumAggregate = {
  __typename?: 'GroupSumAggregate';
  level?: Maybe<Scalars['Int']>;
};

export type GroupSumOrderByAggregateInput = {
  level?: InputMaybe<SortOrder>;
};

export type GroupUpdateInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupUpdateManyWithoutGroupNestedInput>;
  children?: InputMaybe<GroupUpdateManyWithoutParentNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parent?: InputMaybe<GroupUpdateOneWithoutChildrenNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutGroupsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateManyWithWhereWithoutParentInput = {
  data: GroupUpdateManyMutationInput;
  where: GroupScalarWhereInput;
};

export type GroupUpdateManyWithWhereWithoutProjectInput = {
  data: GroupUpdateManyMutationInput;
  where: GroupScalarWhereInput;
};

export type GroupUpdateManyWithoutParentNestedInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutParentInput>>;
  createMany?: InputMaybe<GroupCreateManyParentInputEnvelope>;
  delete?: InputMaybe<Array<GroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  set?: InputMaybe<Array<GroupWhereUniqueInput>>;
  update?: InputMaybe<Array<GroupUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: InputMaybe<Array<GroupUpdateManyWithWhereWithoutParentInput>>;
  upsert?: InputMaybe<Array<GroupUpsertWithWhereUniqueWithoutParentInput>>;
};

export type GroupUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutProjectInput>>;
  createMany?: InputMaybe<GroupCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<GroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  set?: InputMaybe<Array<GroupWhereUniqueInput>>;
  update?: InputMaybe<Array<GroupUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<GroupUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<GroupUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type GroupUpdateOneRequiredWithoutBeneficiariesNestedInput = {
  connect?: InputMaybe<GroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GroupCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<GroupCreateWithoutBeneficiariesInput>;
  update?: InputMaybe<GroupUpdateWithoutBeneficiariesInput>;
  upsert?: InputMaybe<GroupUpsertWithoutBeneficiariesInput>;
};

export type GroupUpdateOneWithoutChildrenNestedInput = {
  connect?: InputMaybe<GroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GroupCreateOrConnectWithoutChildrenInput>;
  create?: InputMaybe<GroupCreateWithoutChildrenInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<GroupUpdateWithoutChildrenInput>;
  upsert?: InputMaybe<GroupUpsertWithoutChildrenInput>;
};

export type GroupUpdateWithWhereUniqueWithoutParentInput = {
  data: GroupUpdateWithoutParentInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpdateWithWhereUniqueWithoutProjectInput = {
  data: GroupUpdateWithoutProjectInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpdateWithoutBeneficiariesInput = {
  children?: InputMaybe<GroupUpdateManyWithoutParentNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parent?: InputMaybe<GroupUpdateOneWithoutChildrenNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutGroupsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateWithoutChildrenInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupUpdateManyWithoutGroupNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parent?: InputMaybe<GroupUpdateOneWithoutChildrenNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutGroupsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateWithoutParentInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupUpdateManyWithoutGroupNestedInput>;
  children?: InputMaybe<GroupUpdateManyWithoutParentNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutGroupsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type GroupUpdateWithoutProjectInput = {
  beneficiaries?: InputMaybe<BeneficiaryGroupUpdateManyWithoutGroupNestedInput>;
  children?: InputMaybe<GroupUpdateManyWithoutParentNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parent?: InputMaybe<GroupUpdateOneWithoutChildrenNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type GroupUpsertWithWhereUniqueWithoutParentInput = {
  create: GroupCreateWithoutParentInput;
  update: GroupUpdateWithoutParentInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpsertWithWhereUniqueWithoutProjectInput = {
  create: GroupCreateWithoutProjectInput;
  update: GroupUpdateWithoutProjectInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpsertWithoutBeneficiariesInput = {
  create: GroupCreateWithoutBeneficiariesInput;
  update: GroupUpdateWithoutBeneficiariesInput;
};

export type GroupUpsertWithoutChildrenInput = {
  create: GroupCreateWithoutChildrenInput;
  update: GroupUpdateWithoutChildrenInput;
};

export type GroupWhereInput = {
  AND?: InputMaybe<Array<GroupWhereInput>>;
  NOT?: InputMaybe<Array<GroupWhereInput>>;
  OR?: InputMaybe<Array<GroupWhereInput>>;
  beneficiaries?: InputMaybe<BeneficiaryGroupListRelationFilter>;
  children?: InputMaybe<GroupListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isLeaf?: InputMaybe<BoolFilter>;
  level?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<GroupRelationFilter>;
  parentId?: InputMaybe<UuidNullableFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type GroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ImplementationAsset = {
  __typename?: 'ImplementationAsset';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  implementation: ActivityImplementation;
  implementationId: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type ImplementationAssetCountAggregate = {
  __typename?: 'ImplementationAssetCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  implementationId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  url: Scalars['Int'];
};

export type ImplementationAssetCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  implementationId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type ImplementationAssetCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  implementation: ActivityImplementationCreateNestedOneWithoutAssetsInput;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type ImplementationAssetCreateManyImplementationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type ImplementationAssetCreateManyImplementationInputEnvelope = {
  data: Array<ImplementationAssetCreateManyImplementationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ImplementationAssetCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  implementationId: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type ImplementationAssetCreateNestedManyWithoutImplementationInput = {
  connect?: InputMaybe<Array<ImplementationAssetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ImplementationAssetCreateOrConnectWithoutImplementationInput>>;
  create?: InputMaybe<Array<ImplementationAssetCreateWithoutImplementationInput>>;
  createMany?: InputMaybe<ImplementationAssetCreateManyImplementationInputEnvelope>;
};

export type ImplementationAssetCreateOrConnectWithoutImplementationInput = {
  create: ImplementationAssetCreateWithoutImplementationInput;
  where: ImplementationAssetWhereUniqueInput;
};

export type ImplementationAssetCreateWithoutImplementationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type ImplementationAssetGroupBy = {
  __typename?: 'ImplementationAssetGroupBy';
  _count?: Maybe<ImplementationAssetCountAggregate>;
  _max?: Maybe<ImplementationAssetMaxAggregate>;
  _min?: Maybe<ImplementationAssetMinAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  implementationId: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type ImplementationAssetListRelationFilter = {
  every?: InputMaybe<ImplementationAssetWhereInput>;
  none?: InputMaybe<ImplementationAssetWhereInput>;
  some?: InputMaybe<ImplementationAssetWhereInput>;
};

export type ImplementationAssetMaxAggregate = {
  __typename?: 'ImplementationAssetMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  implementationId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type ImplementationAssetMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  implementationId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type ImplementationAssetMinAggregate = {
  __typename?: 'ImplementationAssetMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  implementationId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type ImplementationAssetMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  implementationId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type ImplementationAssetOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ImplementationAssetOrderByWithAggregationInput = {
  _count?: InputMaybe<ImplementationAssetCountOrderByAggregateInput>;
  _max?: InputMaybe<ImplementationAssetMaxOrderByAggregateInput>;
  _min?: InputMaybe<ImplementationAssetMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  implementationId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
  url?: InputMaybe<SortOrder>;
};

export type ImplementationAssetOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  implementation?: InputMaybe<ActivityImplementationOrderByWithRelationInput>;
  implementationId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
  url?: InputMaybe<SortOrder>;
};

export enum ImplementationAssetScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ImplementationId = 'implementationId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Url = 'url'
}

export type ImplementationAssetScalarWhereInput = {
  AND?: InputMaybe<Array<ImplementationAssetScalarWhereInput>>;
  NOT?: InputMaybe<Array<ImplementationAssetScalarWhereInput>>;
  OR?: InputMaybe<Array<ImplementationAssetScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  implementationId?: InputMaybe<UuidFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  url?: InputMaybe<StringFilter>;
};

export type ImplementationAssetScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ImplementationAssetScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ImplementationAssetScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ImplementationAssetScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  implementationId?: InputMaybe<UuidWithAggregatesFilter>;
  type?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  url?: InputMaybe<StringWithAggregatesFilter>;
};

export type ImplementationAssetUpdateInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implementation?: InputMaybe<ActivityImplementationUpdateOneRequiredWithoutAssetsNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ImplementationAssetUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ImplementationAssetUpdateManyWithWhereWithoutImplementationInput = {
  data: ImplementationAssetUpdateManyMutationInput;
  where: ImplementationAssetScalarWhereInput;
};

export type ImplementationAssetUpdateManyWithoutImplementationNestedInput = {
  connect?: InputMaybe<Array<ImplementationAssetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ImplementationAssetCreateOrConnectWithoutImplementationInput>>;
  create?: InputMaybe<Array<ImplementationAssetCreateWithoutImplementationInput>>;
  createMany?: InputMaybe<ImplementationAssetCreateManyImplementationInputEnvelope>;
  delete?: InputMaybe<Array<ImplementationAssetWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ImplementationAssetScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ImplementationAssetWhereUniqueInput>>;
  set?: InputMaybe<Array<ImplementationAssetWhereUniqueInput>>;
  update?: InputMaybe<Array<ImplementationAssetUpdateWithWhereUniqueWithoutImplementationInput>>;
  updateMany?: InputMaybe<Array<ImplementationAssetUpdateManyWithWhereWithoutImplementationInput>>;
  upsert?: InputMaybe<Array<ImplementationAssetUpsertWithWhereUniqueWithoutImplementationInput>>;
};

export type ImplementationAssetUpdateWithWhereUniqueWithoutImplementationInput = {
  data: ImplementationAssetUpdateWithoutImplementationInput;
  where: ImplementationAssetWhereUniqueInput;
};

export type ImplementationAssetUpdateWithoutImplementationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ImplementationAssetUpsertWithWhereUniqueWithoutImplementationInput = {
  create: ImplementationAssetCreateWithoutImplementationInput;
  update: ImplementationAssetUpdateWithoutImplementationInput;
  where: ImplementationAssetWhereUniqueInput;
};

export type ImplementationAssetWhereInput = {
  AND?: InputMaybe<Array<ImplementationAssetWhereInput>>;
  NOT?: InputMaybe<Array<ImplementationAssetWhereInput>>;
  OR?: InputMaybe<Array<ImplementationAssetWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  implementation?: InputMaybe<ActivityImplementationRelationFilter>;
  implementationId?: InputMaybe<UuidFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  url?: InputMaybe<StringFilter>;
};

export type ImplementationAssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ImplementationParticipant = {
  __typename?: 'ImplementationParticipant';
  beneficiary: Beneficiary;
  beneficiaryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  implementation: ActivityImplementation;
  implementationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImplementationParticipantCountAggregate = {
  __typename?: 'ImplementationParticipantCountAggregate';
  _all: Scalars['Int'];
  beneficiaryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  implementationId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ImplementationParticipantCountOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  implementationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ImplementationParticipantCreateInput = {
  beneficiary: BeneficiaryCreateNestedOneWithoutAttendsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  implementation: ActivityImplementationCreateNestedOneWithoutParticipantsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImplementationParticipantCreateManyBeneficiaryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  implementationId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImplementationParticipantCreateManyBeneficiaryInputEnvelope = {
  data: Array<ImplementationParticipantCreateManyBeneficiaryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ImplementationParticipantCreateManyImplementationInput = {
  beneficiaryId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImplementationParticipantCreateManyImplementationInputEnvelope = {
  data: Array<ImplementationParticipantCreateManyImplementationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ImplementationParticipantCreateManyInput = {
  beneficiaryId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  implementationId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImplementationParticipantCreateNestedManyWithoutBeneficiaryInput = {
  connect?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ImplementationParticipantCreateOrConnectWithoutBeneficiaryInput>>;
  create?: InputMaybe<Array<ImplementationParticipantCreateWithoutBeneficiaryInput>>;
  createMany?: InputMaybe<ImplementationParticipantCreateManyBeneficiaryInputEnvelope>;
};

export type ImplementationParticipantCreateNestedManyWithoutImplementationInput = {
  connect?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ImplementationParticipantCreateOrConnectWithoutImplementationInput>>;
  create?: InputMaybe<Array<ImplementationParticipantCreateWithoutImplementationInput>>;
  createMany?: InputMaybe<ImplementationParticipantCreateManyImplementationInputEnvelope>;
};

export type ImplementationParticipantCreateOrConnectWithoutBeneficiaryInput = {
  create: ImplementationParticipantCreateWithoutBeneficiaryInput;
  where: ImplementationParticipantWhereUniqueInput;
};

export type ImplementationParticipantCreateOrConnectWithoutImplementationInput = {
  create: ImplementationParticipantCreateWithoutImplementationInput;
  where: ImplementationParticipantWhereUniqueInput;
};

export type ImplementationParticipantCreateWithoutBeneficiaryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  implementation: ActivityImplementationCreateNestedOneWithoutParticipantsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImplementationParticipantCreateWithoutImplementationInput = {
  beneficiary: BeneficiaryCreateNestedOneWithoutAttendsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImplementationParticipantGroupBy = {
  __typename?: 'ImplementationParticipantGroupBy';
  _count?: Maybe<ImplementationParticipantCountAggregate>;
  _max?: Maybe<ImplementationParticipantMaxAggregate>;
  _min?: Maybe<ImplementationParticipantMinAggregate>;
  beneficiaryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  implementationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImplementationParticipantListRelationFilter = {
  every?: InputMaybe<ImplementationParticipantWhereInput>;
  none?: InputMaybe<ImplementationParticipantWhereInput>;
  some?: InputMaybe<ImplementationParticipantWhereInput>;
};

export type ImplementationParticipantMaxAggregate = {
  __typename?: 'ImplementationParticipantMaxAggregate';
  beneficiaryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  implementationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImplementationParticipantMaxOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  implementationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ImplementationParticipantMinAggregate = {
  __typename?: 'ImplementationParticipantMinAggregate';
  beneficiaryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  implementationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImplementationParticipantMinOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  implementationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ImplementationParticipantOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ImplementationParticipantOrderByWithAggregationInput = {
  _count?: InputMaybe<ImplementationParticipantCountOrderByAggregateInput>;
  _max?: InputMaybe<ImplementationParticipantMaxOrderByAggregateInput>;
  _min?: InputMaybe<ImplementationParticipantMinOrderByAggregateInput>;
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  implementationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type ImplementationParticipantOrderByWithRelationInput = {
  beneficiary?: InputMaybe<BeneficiaryOrderByWithRelationInput>;
  beneficiaryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  implementation?: InputMaybe<ActivityImplementationOrderByWithRelationInput>;
  implementationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export enum ImplementationParticipantScalarFieldEnum {
  BeneficiaryId = 'beneficiaryId',
  CreatedAt = 'createdAt',
  Id = 'id',
  ImplementationId = 'implementationId',
  UpdatedAt = 'updatedAt'
}

export type ImplementationParticipantScalarWhereInput = {
  AND?: InputMaybe<Array<ImplementationParticipantScalarWhereInput>>;
  NOT?: InputMaybe<Array<ImplementationParticipantScalarWhereInput>>;
  OR?: InputMaybe<Array<ImplementationParticipantScalarWhereInput>>;
  beneficiaryId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  implementationId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ImplementationParticipantScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ImplementationParticipantScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ImplementationParticipantScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ImplementationParticipantScalarWhereWithAggregatesInput>>;
  beneficiaryId?: InputMaybe<UuidWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  implementationId?: InputMaybe<UuidWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type ImplementationParticipantUpdateInput = {
  beneficiary?: InputMaybe<BeneficiaryUpdateOneRequiredWithoutAttendsNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implementation?: InputMaybe<ActivityImplementationUpdateOneRequiredWithoutParticipantsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ImplementationParticipantUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ImplementationParticipantUpdateManyWithWhereWithoutBeneficiaryInput = {
  data: ImplementationParticipantUpdateManyMutationInput;
  where: ImplementationParticipantScalarWhereInput;
};

export type ImplementationParticipantUpdateManyWithWhereWithoutImplementationInput = {
  data: ImplementationParticipantUpdateManyMutationInput;
  where: ImplementationParticipantScalarWhereInput;
};

export type ImplementationParticipantUpdateManyWithoutBeneficiaryNestedInput = {
  connect?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ImplementationParticipantCreateOrConnectWithoutBeneficiaryInput>>;
  create?: InputMaybe<Array<ImplementationParticipantCreateWithoutBeneficiaryInput>>;
  createMany?: InputMaybe<ImplementationParticipantCreateManyBeneficiaryInputEnvelope>;
  delete?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ImplementationParticipantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  set?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  update?: InputMaybe<Array<ImplementationParticipantUpdateWithWhereUniqueWithoutBeneficiaryInput>>;
  updateMany?: InputMaybe<Array<ImplementationParticipantUpdateManyWithWhereWithoutBeneficiaryInput>>;
  upsert?: InputMaybe<Array<ImplementationParticipantUpsertWithWhereUniqueWithoutBeneficiaryInput>>;
};

export type ImplementationParticipantUpdateManyWithoutImplementationNestedInput = {
  connect?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ImplementationParticipantCreateOrConnectWithoutImplementationInput>>;
  create?: InputMaybe<Array<ImplementationParticipantCreateWithoutImplementationInput>>;
  createMany?: InputMaybe<ImplementationParticipantCreateManyImplementationInputEnvelope>;
  delete?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ImplementationParticipantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  set?: InputMaybe<Array<ImplementationParticipantWhereUniqueInput>>;
  update?: InputMaybe<Array<ImplementationParticipantUpdateWithWhereUniqueWithoutImplementationInput>>;
  updateMany?: InputMaybe<Array<ImplementationParticipantUpdateManyWithWhereWithoutImplementationInput>>;
  upsert?: InputMaybe<Array<ImplementationParticipantUpsertWithWhereUniqueWithoutImplementationInput>>;
};

export type ImplementationParticipantUpdateWithWhereUniqueWithoutBeneficiaryInput = {
  data: ImplementationParticipantUpdateWithoutBeneficiaryInput;
  where: ImplementationParticipantWhereUniqueInput;
};

export type ImplementationParticipantUpdateWithWhereUniqueWithoutImplementationInput = {
  data: ImplementationParticipantUpdateWithoutImplementationInput;
  where: ImplementationParticipantWhereUniqueInput;
};

export type ImplementationParticipantUpdateWithoutBeneficiaryInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implementation?: InputMaybe<ActivityImplementationUpdateOneRequiredWithoutParticipantsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ImplementationParticipantUpdateWithoutImplementationInput = {
  beneficiary?: InputMaybe<BeneficiaryUpdateOneRequiredWithoutAttendsNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ImplementationParticipantUpsertWithWhereUniqueWithoutBeneficiaryInput = {
  create: ImplementationParticipantCreateWithoutBeneficiaryInput;
  update: ImplementationParticipantUpdateWithoutBeneficiaryInput;
  where: ImplementationParticipantWhereUniqueInput;
};

export type ImplementationParticipantUpsertWithWhereUniqueWithoutImplementationInput = {
  create: ImplementationParticipantCreateWithoutImplementationInput;
  update: ImplementationParticipantUpdateWithoutImplementationInput;
  where: ImplementationParticipantWhereUniqueInput;
};

export type ImplementationParticipantWhereInput = {
  AND?: InputMaybe<Array<ImplementationParticipantWhereInput>>;
  NOT?: InputMaybe<Array<ImplementationParticipantWhereInput>>;
  OR?: InputMaybe<Array<ImplementationParticipantWhereInput>>;
  beneficiary?: InputMaybe<BeneficiaryRelationFilter>;
  beneficiaryId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  implementation?: InputMaybe<ActivityImplementationRelationFilter>;
  implementationId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ImplementationParticipantWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Location = {
  __typename?: 'Location';
  _count?: Maybe<LocationCount>;
  beneficiaries: Array<Beneficiary>;
  children: Array<Location>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: Maybe<Location>;
  parentId?: Maybe<Scalars['String']>;
  project: Project;
  projectId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type LocationBeneficiariesArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type LocationChildrenArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationAvgAggregate = {
  __typename?: 'LocationAvgAggregate';
  level?: Maybe<Scalars['Float']>;
};

export type LocationAvgOrderByAggregateInput = {
  level?: InputMaybe<SortOrder>;
};

export type LocationCount = {
  __typename?: 'LocationCount';
  beneficiaries: Scalars['Int'];
  children: Scalars['Int'];
};

export type LocationCountAggregate = {
  __typename?: 'LocationCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isLeaf: Scalars['Int'];
  level: Scalars['Int'];
  name: Scalars['Int'];
  parentId: Scalars['Int'];
  projectId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LocationCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LocationCreateInput = {
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutLocationInput>;
  children?: InputMaybe<LocationCreateNestedManyWithoutParentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: InputMaybe<LocationCreateNestedOneWithoutChildrenInput>;
  project: ProjectCreateNestedOneWithoutLocationsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationCreateManyParentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationCreateManyParentInputEnvelope = {
  data: Array<LocationCreateManyParentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LocationCreateManyProjectInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationCreateManyProjectInputEnvelope = {
  data: Array<LocationCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LocationCreateNestedManyWithoutParentInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutParentInput>>;
  createMany?: InputMaybe<LocationCreateManyParentInputEnvelope>;
};

export type LocationCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutProjectInput>>;
  createMany?: InputMaybe<LocationCreateManyProjectInputEnvelope>;
};

export type LocationCreateNestedOneWithoutBeneficiariesInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<LocationCreateWithoutBeneficiariesInput>;
};

export type LocationCreateNestedOneWithoutChildrenInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutChildrenInput>;
  create?: InputMaybe<LocationCreateWithoutChildrenInput>;
};

export type LocationCreateOrConnectWithoutBeneficiariesInput = {
  create: LocationCreateWithoutBeneficiariesInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutChildrenInput = {
  create: LocationCreateWithoutChildrenInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutParentInput = {
  create: LocationCreateWithoutParentInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutProjectInput = {
  create: LocationCreateWithoutProjectInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateWithoutBeneficiariesInput = {
  children?: InputMaybe<LocationCreateNestedManyWithoutParentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: InputMaybe<LocationCreateNestedOneWithoutChildrenInput>;
  project: ProjectCreateNestedOneWithoutLocationsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationCreateWithoutChildrenInput = {
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutLocationInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: InputMaybe<LocationCreateNestedOneWithoutChildrenInput>;
  project: ProjectCreateNestedOneWithoutLocationsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationCreateWithoutParentInput = {
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutLocationInput>;
  children?: InputMaybe<LocationCreateNestedManyWithoutParentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  project: ProjectCreateNestedOneWithoutLocationsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationCreateWithoutProjectInput = {
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutLocationInput>;
  children?: InputMaybe<LocationCreateNestedManyWithoutParentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parent?: InputMaybe<LocationCreateNestedOneWithoutChildrenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationGroupBy = {
  __typename?: 'LocationGroupBy';
  _avg?: Maybe<LocationAvgAggregate>;
  _count?: Maybe<LocationCountAggregate>;
  _max?: Maybe<LocationMaxAggregate>;
  _min?: Maybe<LocationMinAggregate>;
  _sum?: Maybe<LocationSumAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isLeaf: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationListRelationFilter = {
  every?: InputMaybe<LocationWhereInput>;
  none?: InputMaybe<LocationWhereInput>;
  some?: InputMaybe<LocationWhereInput>;
};

export type LocationMaxAggregate = {
  __typename?: 'LocationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isLeaf?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LocationMinAggregate = {
  __typename?: 'LocationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isLeaf?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LocationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LocationOrderByWithAggregationInput = {
  _avg?: InputMaybe<LocationAvgOrderByAggregateInput>;
  _count?: InputMaybe<LocationCountOrderByAggregateInput>;
  _max?: InputMaybe<LocationMaxOrderByAggregateInput>;
  _min?: InputMaybe<LocationMinOrderByAggregateInput>;
  _sum?: InputMaybe<LocationSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type LocationOrderByWithRelationInput = {
  beneficiaries?: InputMaybe<BeneficiaryOrderByRelationAggregateInput>;
  children?: InputMaybe<LocationOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isLeaf?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parent?: InputMaybe<LocationOrderByWithRelationInput>;
  parentId?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type LocationRelationFilter = {
  is?: InputMaybe<LocationWhereInput>;
  isNot?: InputMaybe<LocationWhereInput>;
};

export enum LocationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsLeaf = 'isLeaf',
  Level = 'level',
  Name = 'name',
  ParentId = 'parentId',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt'
}

export type LocationScalarWhereInput = {
  AND?: InputMaybe<Array<LocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<LocationScalarWhereInput>>;
  OR?: InputMaybe<Array<LocationScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isLeaf?: InputMaybe<BoolFilter>;
  level?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<UuidNullableFilter>;
  projectId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type LocationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  isLeaf?: InputMaybe<BoolWithAggregatesFilter>;
  level?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  parentId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  projectId?: InputMaybe<UuidWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type LocationSumAggregate = {
  __typename?: 'LocationSumAggregate';
  level?: Maybe<Scalars['Int']>;
};

export type LocationSumOrderByAggregateInput = {
  level?: InputMaybe<SortOrder>;
};

export type LocationUpdateInput = {
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutLocationNestedInput>;
  children?: InputMaybe<LocationUpdateManyWithoutParentNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parent?: InputMaybe<LocationUpdateOneWithoutChildrenNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutLocationsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type LocationUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type LocationUpdateManyWithWhereWithoutParentInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithWhereWithoutProjectInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithoutParentNestedInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutParentInput>>;
  createMany?: InputMaybe<LocationCreateManyParentInputEnvelope>;
  delete?: InputMaybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationWhereUniqueInput>>;
  update?: InputMaybe<Array<LocationUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: InputMaybe<Array<LocationUpdateManyWithWhereWithoutParentInput>>;
  upsert?: InputMaybe<Array<LocationUpsertWithWhereUniqueWithoutParentInput>>;
};

export type LocationUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutProjectInput>>;
  createMany?: InputMaybe<LocationCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationWhereUniqueInput>>;
  update?: InputMaybe<Array<LocationUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<LocationUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<LocationUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type LocationUpdateOneWithoutBeneficiariesNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<LocationCreateWithoutBeneficiariesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<LocationUpdateWithoutBeneficiariesInput>;
  upsert?: InputMaybe<LocationUpsertWithoutBeneficiariesInput>;
};

export type LocationUpdateOneWithoutChildrenNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutChildrenInput>;
  create?: InputMaybe<LocationCreateWithoutChildrenInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<LocationUpdateWithoutChildrenInput>;
  upsert?: InputMaybe<LocationUpsertWithoutChildrenInput>;
};

export type LocationUpdateWithWhereUniqueWithoutParentInput = {
  data: LocationUpdateWithoutParentInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithWhereUniqueWithoutProjectInput = {
  data: LocationUpdateWithoutProjectInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithoutBeneficiariesInput = {
  children?: InputMaybe<LocationUpdateManyWithoutParentNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parent?: InputMaybe<LocationUpdateOneWithoutChildrenNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutLocationsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutChildrenInput = {
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutLocationNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parent?: InputMaybe<LocationUpdateOneWithoutChildrenNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutLocationsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutParentInput = {
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutLocationNestedInput>;
  children?: InputMaybe<LocationUpdateManyWithoutParentNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutLocationsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutProjectInput = {
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutLocationNestedInput>;
  children?: InputMaybe<LocationUpdateManyWithoutParentNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isLeaf?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parent?: InputMaybe<LocationUpdateOneWithoutChildrenNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type LocationUpsertWithWhereUniqueWithoutParentInput = {
  create: LocationCreateWithoutParentInput;
  update: LocationUpdateWithoutParentInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithWhereUniqueWithoutProjectInput = {
  create: LocationCreateWithoutProjectInput;
  update: LocationUpdateWithoutProjectInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithoutBeneficiariesInput = {
  create: LocationCreateWithoutBeneficiariesInput;
  update: LocationUpdateWithoutBeneficiariesInput;
};

export type LocationUpsertWithoutChildrenInput = {
  create: LocationCreateWithoutChildrenInput;
  update: LocationUpdateWithoutChildrenInput;
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  beneficiaries?: InputMaybe<BeneficiaryListRelationFilter>;
  children?: InputMaybe<LocationListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isLeaf?: InputMaybe<BoolFilter>;
  level?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<LocationRelationFilter>;
  parentId?: InputMaybe<UuidNullableFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type LocationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  access_token?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createManyActivity: AffectedRowsOutput;
  createManyActivityImplementation: AffectedRowsOutput;
  createManyBeneficiary: AffectedRowsOutput;
  createManyBeneficiaryGroup: AffectedRowsOutput;
  createManyGroup: AffectedRowsOutput;
  createManyImplementationAsset: AffectedRowsOutput;
  createManyImplementationParticipant: AffectedRowsOutput;
  createManyLocation: AffectedRowsOutput;
  createManyOrganization: AffectedRowsOutput;
  createManyOutput: AffectedRowsOutput;
  createManyProject: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createOneActivity: Activity;
  createOneActivityImplementation: ActivityImplementation;
  createOneBeneficiary: Beneficiary;
  createOneBeneficiaryGroup: BeneficiaryGroup;
  createOneGroup: Group;
  createOneImplementationAsset: ImplementationAsset;
  createOneImplementationParticipant: ImplementationParticipant;
  createOneLocation: Location;
  createOneOrganization: Organization;
  createOneOutput: Output;
  createOneProject: Project;
  createOneUser: User;
  deleteManyActivity: AffectedRowsOutput;
  deleteManyActivityImplementation: AffectedRowsOutput;
  deleteManyBeneficiary: AffectedRowsOutput;
  deleteManyBeneficiaryGroup: AffectedRowsOutput;
  deleteManyGroup: AffectedRowsOutput;
  deleteManyImplementationAsset: AffectedRowsOutput;
  deleteManyImplementationParticipant: AffectedRowsOutput;
  deleteManyLocation: AffectedRowsOutput;
  deleteManyOrganization: AffectedRowsOutput;
  deleteManyOutput: AffectedRowsOutput;
  deleteManyProject: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteOneActivity?: Maybe<Activity>;
  deleteOneActivityImplementation?: Maybe<ActivityImplementation>;
  deleteOneBeneficiary?: Maybe<Beneficiary>;
  deleteOneBeneficiaryGroup?: Maybe<BeneficiaryGroup>;
  deleteOneGroup?: Maybe<Group>;
  deleteOneImplementationAsset?: Maybe<ImplementationAsset>;
  deleteOneImplementationParticipant?: Maybe<ImplementationParticipant>;
  deleteOneLocation?: Maybe<Location>;
  deleteOneOrganization?: Maybe<Organization>;
  deleteOneOutput?: Maybe<Output>;
  deleteOneProject?: Maybe<Project>;
  deleteOneUser?: Maybe<User>;
  login: LoginOutput;
  updateManyActivity: AffectedRowsOutput;
  updateManyActivityImplementation: AffectedRowsOutput;
  updateManyBeneficiary: AffectedRowsOutput;
  updateManyBeneficiaryGroup: AffectedRowsOutput;
  updateManyGroup: AffectedRowsOutput;
  updateManyImplementationAsset: AffectedRowsOutput;
  updateManyImplementationParticipant: AffectedRowsOutput;
  updateManyLocation: AffectedRowsOutput;
  updateManyOrganization: AffectedRowsOutput;
  updateManyOutput: AffectedRowsOutput;
  updateManyProject: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateOneActivity?: Maybe<Activity>;
  updateOneActivityImplementation?: Maybe<ActivityImplementation>;
  updateOneBeneficiary?: Maybe<Beneficiary>;
  updateOneBeneficiaryGroup?: Maybe<BeneficiaryGroup>;
  updateOneGroup?: Maybe<Group>;
  updateOneImplementationAsset?: Maybe<ImplementationAsset>;
  updateOneImplementationParticipant?: Maybe<ImplementationParticipant>;
  updateOneLocation?: Maybe<Location>;
  updateOneOrganization?: Maybe<Organization>;
  updateOneOutput?: Maybe<Output>;
  updateOneProject?: Maybe<Project>;
  updateOneUser?: Maybe<User>;
  upsertOneActivity: Activity;
  upsertOneActivityImplementation: ActivityImplementation;
  upsertOneBeneficiary: Beneficiary;
  upsertOneBeneficiaryGroup: BeneficiaryGroup;
  upsertOneGroup: Group;
  upsertOneImplementationAsset: ImplementationAsset;
  upsertOneImplementationParticipant: ImplementationParticipant;
  upsertOneLocation: Location;
  upsertOneOrganization: Organization;
  upsertOneOutput: Output;
  upsertOneProject: Project;
  upsertOneUser: User;
};


export type MutationCreateManyActivityArgs = {
  data: Array<ActivityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyActivityImplementationArgs = {
  data: Array<ActivityImplementationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBeneficiaryArgs = {
  data: Array<BeneficiaryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBeneficiaryGroupArgs = {
  data: Array<BeneficiaryGroupCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyGroupArgs = {
  data: Array<GroupCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyImplementationAssetArgs = {
  data: Array<ImplementationAssetCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyImplementationParticipantArgs = {
  data: Array<ImplementationParticipantCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyLocationArgs = {
  data: Array<LocationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyOrganizationArgs = {
  data: Array<OrganizationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyOutputArgs = {
  data: Array<OutputCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProjectArgs = {
  data: Array<ProjectCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOneActivityArgs = {
  data: ActivityCreateInput;
};


export type MutationCreateOneActivityImplementationArgs = {
  data: ActivityImplementationCreateInput;
};


export type MutationCreateOneBeneficiaryArgs = {
  data: BeneficiaryCreateInput;
};


export type MutationCreateOneBeneficiaryGroupArgs = {
  data: BeneficiaryGroupCreateInput;
};


export type MutationCreateOneGroupArgs = {
  data: GroupCreateInput;
};


export type MutationCreateOneImplementationAssetArgs = {
  data: ImplementationAssetCreateInput;
};


export type MutationCreateOneImplementationParticipantArgs = {
  data: ImplementationParticipantCreateInput;
};


export type MutationCreateOneLocationArgs = {
  data: LocationCreateInput;
};


export type MutationCreateOneOrganizationArgs = {
  data: OrganizationCreateInput;
};


export type MutationCreateOneOutputArgs = {
  data: OutputCreateInput;
};


export type MutationCreateOneProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteManyActivityArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type MutationDeleteManyActivityImplementationArgs = {
  where?: InputMaybe<ActivityImplementationWhereInput>;
};


export type MutationDeleteManyBeneficiaryArgs = {
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type MutationDeleteManyBeneficiaryGroupArgs = {
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};


export type MutationDeleteManyGroupArgs = {
  where?: InputMaybe<GroupWhereInput>;
};


export type MutationDeleteManyImplementationAssetArgs = {
  where?: InputMaybe<ImplementationAssetWhereInput>;
};


export type MutationDeleteManyImplementationParticipantArgs = {
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};


export type MutationDeleteManyLocationArgs = {
  where?: InputMaybe<LocationWhereInput>;
};


export type MutationDeleteManyOrganizationArgs = {
  where?: InputMaybe<OrganizationWhereInput>;
};


export type MutationDeleteManyOutputArgs = {
  where?: InputMaybe<OutputWhereInput>;
};


export type MutationDeleteManyProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteOneActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type MutationDeleteOneActivityImplementationArgs = {
  where: ActivityImplementationWhereUniqueInput;
};


export type MutationDeleteOneBeneficiaryArgs = {
  where: BeneficiaryWhereUniqueInput;
};


export type MutationDeleteOneBeneficiaryGroupArgs = {
  where: BeneficiaryGroupWhereUniqueInput;
};


export type MutationDeleteOneGroupArgs = {
  where: GroupWhereUniqueInput;
};


export type MutationDeleteOneImplementationAssetArgs = {
  where: ImplementationAssetWhereUniqueInput;
};


export type MutationDeleteOneImplementationParticipantArgs = {
  where: ImplementationParticipantWhereUniqueInput;
};


export type MutationDeleteOneLocationArgs = {
  where: LocationWhereUniqueInput;
};


export type MutationDeleteOneOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type MutationDeleteOneOutputArgs = {
  where: OutputWhereUniqueInput;
};


export type MutationDeleteOneProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateManyActivityArgs = {
  data: ActivityUpdateManyMutationInput;
  where?: InputMaybe<ActivityWhereInput>;
};


export type MutationUpdateManyActivityImplementationArgs = {
  data: ActivityImplementationUpdateManyMutationInput;
  where?: InputMaybe<ActivityImplementationWhereInput>;
};


export type MutationUpdateManyBeneficiaryArgs = {
  data: BeneficiaryUpdateManyMutationInput;
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type MutationUpdateManyBeneficiaryGroupArgs = {
  data: BeneficiaryGroupUpdateManyMutationInput;
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};


export type MutationUpdateManyGroupArgs = {
  data: GroupUpdateManyMutationInput;
  where?: InputMaybe<GroupWhereInput>;
};


export type MutationUpdateManyImplementationAssetArgs = {
  data: ImplementationAssetUpdateManyMutationInput;
  where?: InputMaybe<ImplementationAssetWhereInput>;
};


export type MutationUpdateManyImplementationParticipantArgs = {
  data: ImplementationParticipantUpdateManyMutationInput;
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};


export type MutationUpdateManyLocationArgs = {
  data: LocationUpdateManyMutationInput;
  where?: InputMaybe<LocationWhereInput>;
};


export type MutationUpdateManyOrganizationArgs = {
  data: OrganizationUpdateManyMutationInput;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type MutationUpdateManyOutputArgs = {
  data: OutputUpdateManyMutationInput;
  where?: InputMaybe<OutputWhereInput>;
};


export type MutationUpdateManyProjectArgs = {
  data: ProjectUpdateManyMutationInput;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateOneActivityArgs = {
  data: ActivityUpdateInput;
  where: ActivityWhereUniqueInput;
};


export type MutationUpdateOneActivityImplementationArgs = {
  data: ActivityImplementationUpdateInput;
  where: ActivityImplementationWhereUniqueInput;
};


export type MutationUpdateOneBeneficiaryArgs = {
  data: BeneficiaryUpdateInput;
  where: BeneficiaryWhereUniqueInput;
};


export type MutationUpdateOneBeneficiaryGroupArgs = {
  data: BeneficiaryGroupUpdateInput;
  where: BeneficiaryGroupWhereUniqueInput;
};


export type MutationUpdateOneGroupArgs = {
  data: GroupUpdateInput;
  where: GroupWhereUniqueInput;
};


export type MutationUpdateOneImplementationAssetArgs = {
  data: ImplementationAssetUpdateInput;
  where: ImplementationAssetWhereUniqueInput;
};


export type MutationUpdateOneImplementationParticipantArgs = {
  data: ImplementationParticipantUpdateInput;
  where: ImplementationParticipantWhereUniqueInput;
};


export type MutationUpdateOneLocationArgs = {
  data: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};


export type MutationUpdateOneOrganizationArgs = {
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdateOneOutputArgs = {
  data: OutputUpdateInput;
  where: OutputWhereUniqueInput;
};


export type MutationUpdateOneProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneActivityArgs = {
  create: ActivityCreateInput;
  update: ActivityUpdateInput;
  where: ActivityWhereUniqueInput;
};


export type MutationUpsertOneActivityImplementationArgs = {
  create: ActivityImplementationCreateInput;
  update: ActivityImplementationUpdateInput;
  where: ActivityImplementationWhereUniqueInput;
};


export type MutationUpsertOneBeneficiaryArgs = {
  create: BeneficiaryCreateInput;
  update: BeneficiaryUpdateInput;
  where: BeneficiaryWhereUniqueInput;
};


export type MutationUpsertOneBeneficiaryGroupArgs = {
  create: BeneficiaryGroupCreateInput;
  update: BeneficiaryGroupUpdateInput;
  where: BeneficiaryGroupWhereUniqueInput;
};


export type MutationUpsertOneGroupArgs = {
  create: GroupCreateInput;
  update: GroupUpdateInput;
  where: GroupWhereUniqueInput;
};


export type MutationUpsertOneImplementationAssetArgs = {
  create: ImplementationAssetCreateInput;
  update: ImplementationAssetUpdateInput;
  where: ImplementationAssetWhereUniqueInput;
};


export type MutationUpsertOneImplementationParticipantArgs = {
  create: ImplementationParticipantCreateInput;
  update: ImplementationParticipantUpdateInput;
  where: ImplementationParticipantWhereUniqueInput;
};


export type MutationUpsertOneLocationArgs = {
  create: LocationCreateInput;
  update: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};


export type MutationUpsertOneOrganizationArgs = {
  create: OrganizationCreateInput;
  update: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpsertOneOutputArgs = {
  create: OutputCreateInput;
  update: OutputUpdateInput;
  where: OutputWhereUniqueInput;
};


export type MutationUpsertOneProjectArgs = {
  create: ProjectCreateInput;
  update: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type NestedUuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type NestedUuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type NestedUuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Organization = {
  __typename?: 'Organization';
  _count?: Maybe<OrganizationCount>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  projects: Array<Project>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users: Array<User>;
};


export type OrganizationProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type OrganizationUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type OrganizationCount = {
  __typename?: 'OrganizationCount';
  projects: Scalars['Int'];
  users: Scalars['Int'];
};

export type OrganizationCountAggregate = {
  __typename?: 'OrganizationCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OrganizationCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrganizationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projects?: InputMaybe<ProjectCreateNestedManyWithoutOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrganizationCreateNestedOneWithoutProjectsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutProjectsInput>;
  create?: InputMaybe<OrganizationCreateWithoutProjectsInput>;
};

export type OrganizationCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<OrganizationCreateWithoutUsersInput>;
};

export type OrganizationCreateOrConnectWithoutProjectsInput = {
  create: OrganizationCreateWithoutProjectsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutUsersInput = {
  create: OrganizationCreateWithoutUsersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateWithoutProjectsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projects?: InputMaybe<ProjectCreateNestedManyWithoutOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrganizationGroupBy = {
  __typename?: 'OrganizationGroupBy';
  _count?: Maybe<OrganizationCountAggregate>;
  _max?: Maybe<OrganizationMaxAggregate>;
  _min?: Maybe<OrganizationMinAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationMaxAggregate = {
  __typename?: 'OrganizationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrganizationMinAggregate = {
  __typename?: 'OrganizationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrganizationOrderByWithAggregationInput = {
  _count?: InputMaybe<OrganizationCountOrderByAggregateInput>;
  _max?: InputMaybe<OrganizationMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrganizationMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type OrganizationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projects?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrderInput>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type OrganizationRelationFilter = {
  is?: InputMaybe<OrganizationWhereInput>;
  isNot?: InputMaybe<OrganizationWhereInput>;
};

export enum OrganizationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type OrganizationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type OrganizationUpdateInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  projects?: InputMaybe<ProjectUpdateManyWithoutOrganizationNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateOneWithoutProjectsNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutProjectsInput>;
  create?: InputMaybe<OrganizationCreateWithoutProjectsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<OrganizationUpdateWithoutProjectsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutProjectsInput>;
};

export type OrganizationUpdateOneWithoutUsersNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<OrganizationCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<OrganizationUpdateWithoutUsersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutUsersInput>;
};

export type OrganizationUpdateWithoutProjectsInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationNestedInput>;
};

export type OrganizationUpdateWithoutUsersInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  projects?: InputMaybe<ProjectUpdateManyWithoutOrganizationNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpsertWithoutProjectsInput = {
  create: OrganizationCreateWithoutProjectsInput;
  update: OrganizationUpdateWithoutProjectsInput;
};

export type OrganizationUpsertWithoutUsersInput = {
  create: OrganizationCreateWithoutUsersInput;
  update: OrganizationUpdateWithoutUsersInput;
};

export type OrganizationWhereInput = {
  AND?: InputMaybe<Array<OrganizationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  projects?: InputMaybe<ProjectListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type OrganizationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Output = {
  __typename?: 'Output';
  _count?: Maybe<OutputCount>;
  activities: Array<Activity>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  project: Project;
  projectId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type OutputActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityWhereInput>;
};

export type OutputCount = {
  __typename?: 'OutputCount';
  activities: Scalars['Int'];
};

export type OutputCountAggregate = {
  __typename?: 'OutputCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  projectId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OutputCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OutputCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutOutputInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  project: ProjectCreateNestedOneWithoutOutputsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OutputCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OutputCreateManyProjectInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OutputCreateManyProjectInputEnvelope = {
  data: Array<OutputCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OutputCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<OutputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutputCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<OutputCreateWithoutProjectInput>>;
  createMany?: InputMaybe<OutputCreateManyProjectInputEnvelope>;
};

export type OutputCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<OutputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutputCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<OutputCreateWithoutActivitiesInput>;
};

export type OutputCreateOrConnectWithoutActivitiesInput = {
  create: OutputCreateWithoutActivitiesInput;
  where: OutputWhereUniqueInput;
};

export type OutputCreateOrConnectWithoutProjectInput = {
  create: OutputCreateWithoutProjectInput;
  where: OutputWhereUniqueInput;
};

export type OutputCreateWithoutActivitiesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  project: ProjectCreateNestedOneWithoutOutputsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OutputCreateWithoutProjectInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutOutputInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OutputGroupBy = {
  __typename?: 'OutputGroupBy';
  _count?: Maybe<OutputCountAggregate>;
  _max?: Maybe<OutputMaxAggregate>;
  _min?: Maybe<OutputMinAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OutputListRelationFilter = {
  every?: InputMaybe<OutputWhereInput>;
  none?: InputMaybe<OutputWhereInput>;
  some?: InputMaybe<OutputWhereInput>;
};

export type OutputMaxAggregate = {
  __typename?: 'OutputMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OutputMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OutputMinAggregate = {
  __typename?: 'OutputMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OutputMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OutputOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OutputOrderByWithAggregationInput = {
  _count?: InputMaybe<OutputCountOrderByAggregateInput>;
  _max?: InputMaybe<OutputMaxOrderByAggregateInput>;
  _min?: InputMaybe<OutputMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type OutputOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type OutputRelationFilter = {
  is?: InputMaybe<OutputWhereInput>;
  isNot?: InputMaybe<OutputWhereInput>;
};

export enum OutputScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt'
}

export type OutputScalarWhereInput = {
  AND?: InputMaybe<Array<OutputScalarWhereInput>>;
  NOT?: InputMaybe<Array<OutputScalarWhereInput>>;
  OR?: InputMaybe<Array<OutputScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  projectId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type OutputScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OutputScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OutputScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OutputScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  projectId?: InputMaybe<UuidWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type OutputUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutOutputNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutOutputsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type OutputUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type OutputUpdateManyWithWhereWithoutProjectInput = {
  data: OutputUpdateManyMutationInput;
  where: OutputScalarWhereInput;
};

export type OutputUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<OutputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OutputCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<OutputCreateWithoutProjectInput>>;
  createMany?: InputMaybe<OutputCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<OutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutputWhereUniqueInput>>;
  set?: InputMaybe<Array<OutputWhereUniqueInput>>;
  update?: InputMaybe<Array<OutputUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<OutputUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<OutputUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type OutputUpdateOneRequiredWithoutActivitiesNestedInput = {
  connect?: InputMaybe<OutputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OutputCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<OutputCreateWithoutActivitiesInput>;
  update?: InputMaybe<OutputUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<OutputUpsertWithoutActivitiesInput>;
};

export type OutputUpdateWithWhereUniqueWithoutProjectInput = {
  data: OutputUpdateWithoutProjectInput;
  where: OutputWhereUniqueInput;
};

export type OutputUpdateWithoutActivitiesInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutOutputsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type OutputUpdateWithoutProjectInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutOutputNestedInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type OutputUpsertWithWhereUniqueWithoutProjectInput = {
  create: OutputCreateWithoutProjectInput;
  update: OutputUpdateWithoutProjectInput;
  where: OutputWhereUniqueInput;
};

export type OutputUpsertWithoutActivitiesInput = {
  create: OutputCreateWithoutActivitiesInput;
  update: OutputUpdateWithoutActivitiesInput;
};

export type OutputWhereInput = {
  AND?: InputMaybe<Array<OutputWhereInput>>;
  NOT?: InputMaybe<Array<OutputWhereInput>>;
  OR?: InputMaybe<Array<OutputWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type OutputWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Project = {
  __typename?: 'Project';
  _count?: Maybe<ProjectCount>;
  activities: Array<Activity>;
  beneficiaries: Array<Beneficiary>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  groups: Array<Group>;
  id: Scalars['String'];
  locations: Array<Location>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  outputs: Array<Output>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ProjectActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type ProjectBeneficiariesArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type ProjectGroupsArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type ProjectLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type ProjectOutputsArgs = {
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OutputWhereInput>;
};

export type ProjectCount = {
  __typename?: 'ProjectCount';
  activities: Scalars['Int'];
  beneficiaries: Scalars['Int'];
  groups: Scalars['Int'];
  locations: Scalars['Int'];
  outputs: Scalars['Int'];
};

export type ProjectCountAggregate = {
  __typename?: 'ProjectCountAggregate';
  _all: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  organizationId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ProjectCountOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProjectCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutProjectInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutProjectInput>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<GroupCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutProjectInput>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  outputs?: InputMaybe<OutputCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateManyInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateManyOrganizationInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateManyOrganizationInputEnvelope = {
  data: Array<ProjectCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProjectCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ProjectCreateManyOrganizationInputEnvelope>;
};

export type ProjectCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<ProjectCreateWithoutActivitiesInput>;
};

export type ProjectCreateNestedOneWithoutBeneficiariesInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<ProjectCreateWithoutBeneficiariesInput>;
};

export type ProjectCreateNestedOneWithoutGroupsInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutGroupsInput>;
  create?: InputMaybe<ProjectCreateWithoutGroupsInput>;
};

export type ProjectCreateNestedOneWithoutLocationsInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<ProjectCreateWithoutLocationsInput>;
};

export type ProjectCreateNestedOneWithoutOutputsInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutOutputsInput>;
  create?: InputMaybe<ProjectCreateWithoutOutputsInput>;
};

export type ProjectCreateOrConnectWithoutActivitiesInput = {
  create: ProjectCreateWithoutActivitiesInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutBeneficiariesInput = {
  create: ProjectCreateWithoutBeneficiariesInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutGroupsInput = {
  create: ProjectCreateWithoutGroupsInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutLocationsInput = {
  create: ProjectCreateWithoutLocationsInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutOrganizationInput = {
  create: ProjectCreateWithoutOrganizationInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutOutputsInput = {
  create: ProjectCreateWithoutOutputsInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateWithoutActivitiesInput = {
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutProjectInput>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<GroupCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutProjectInput>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  outputs?: InputMaybe<OutputCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateWithoutBeneficiariesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutProjectInput>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<GroupCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutProjectInput>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  outputs?: InputMaybe<OutputCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateWithoutGroupsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutProjectInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutProjectInput>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutProjectInput>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  outputs?: InputMaybe<OutputCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateWithoutLocationsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutProjectInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutProjectInput>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<GroupCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  outputs?: InputMaybe<OutputCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateWithoutOrganizationInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutProjectInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutProjectInput>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<GroupCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutProjectInput>;
  name: Scalars['String'];
  outputs?: InputMaybe<OutputCreateNestedManyWithoutProjectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateWithoutOutputsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutProjectInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutProjectInput>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<GroupCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<LocationCreateNestedManyWithoutProjectInput>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectGroupBy = {
  __typename?: 'ProjectGroupBy';
  _count?: Maybe<ProjectCountAggregate>;
  _max?: Maybe<ProjectMaxAggregate>;
  _min?: Maybe<ProjectMinAggregate>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectListRelationFilter = {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectMaxAggregate = {
  __typename?: 'ProjectMaxAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectMaxOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProjectMinAggregate = {
  __typename?: 'ProjectMinAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectMinOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectOrderByWithAggregationInput = {
  _count?: InputMaybe<ProjectCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectMinOrderByAggregateInput>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type ProjectOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  beneficiaries?: InputMaybe<BeneficiaryOrderByRelationAggregateInput>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  groups?: InputMaybe<GroupOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  locations?: InputMaybe<LocationOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrderInput>;
  outputs?: InputMaybe<OutputOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type ProjectRelationFilter = {
  is?: InputMaybe<ProjectWhereInput>;
  isNot?: InputMaybe<ProjectWhereInput>;
};

export enum ProjectScalarFieldEnum {
  Code = 'code',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organizationId',
  UpdatedAt = 'updatedAt'
}

export type ProjectScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<UuidNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ProjectScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  organizationId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type ProjectUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutProjectNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutProjectNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groups?: InputMaybe<GroupUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutProjectNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  outputs?: InputMaybe<OutputUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyMutationInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyWithWhereWithoutOrganizationInput = {
  data: ProjectUpdateManyMutationInput;
  where: ProjectScalarWhereInput;
};

export type ProjectUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ProjectCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ProjectUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ProjectUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ProjectUpdateOneRequiredWithoutActivitiesNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<ProjectCreateWithoutActivitiesInput>;
  update?: InputMaybe<ProjectUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutActivitiesInput>;
};

export type ProjectUpdateOneRequiredWithoutBeneficiariesNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<ProjectCreateWithoutBeneficiariesInput>;
  update?: InputMaybe<ProjectUpdateWithoutBeneficiariesInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutBeneficiariesInput>;
};

export type ProjectUpdateOneRequiredWithoutGroupsNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutGroupsInput>;
  create?: InputMaybe<ProjectCreateWithoutGroupsInput>;
  update?: InputMaybe<ProjectUpdateWithoutGroupsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutGroupsInput>;
};

export type ProjectUpdateOneRequiredWithoutLocationsNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<ProjectCreateWithoutLocationsInput>;
  update?: InputMaybe<ProjectUpdateWithoutLocationsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutLocationsInput>;
};

export type ProjectUpdateOneRequiredWithoutOutputsNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutOutputsInput>;
  create?: InputMaybe<ProjectCreateWithoutOutputsInput>;
  update?: InputMaybe<ProjectUpdateWithoutOutputsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutOutputsInput>;
};

export type ProjectUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ProjectUpdateWithoutOrganizationInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateWithoutActivitiesInput = {
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutProjectNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groups?: InputMaybe<GroupUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutProjectNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  outputs?: InputMaybe<OutputUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutBeneficiariesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutProjectNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groups?: InputMaybe<GroupUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutProjectNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  outputs?: InputMaybe<OutputUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutGroupsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutProjectNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutProjectNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutProjectNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  outputs?: InputMaybe<OutputUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutLocationsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutProjectNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutProjectNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groups?: InputMaybe<GroupUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  outputs?: InputMaybe<OutputUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutOrganizationInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutProjectNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutProjectNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groups?: InputMaybe<GroupUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutProjectNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  outputs?: InputMaybe<OutputUpdateManyWithoutProjectNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutOutputsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutProjectNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutProjectNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groups?: InputMaybe<GroupUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutProjectNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ProjectCreateWithoutOrganizationInput;
  update: ProjectUpdateWithoutOrganizationInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpsertWithoutActivitiesInput = {
  create: ProjectCreateWithoutActivitiesInput;
  update: ProjectUpdateWithoutActivitiesInput;
};

export type ProjectUpsertWithoutBeneficiariesInput = {
  create: ProjectCreateWithoutBeneficiariesInput;
  update: ProjectUpdateWithoutBeneficiariesInput;
};

export type ProjectUpsertWithoutGroupsInput = {
  create: ProjectCreateWithoutGroupsInput;
  update: ProjectUpdateWithoutGroupsInput;
};

export type ProjectUpsertWithoutLocationsInput = {
  create: ProjectCreateWithoutLocationsInput;
  update: ProjectUpdateWithoutLocationsInput;
};

export type ProjectUpsertWithoutOutputsInput = {
  create: ProjectCreateWithoutOutputsInput;
  update: ProjectUpdateWithoutOutputsInput;
};

export type ProjectWhereInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  beneficiaries?: InputMaybe<BeneficiaryListRelationFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  groups?: InputMaybe<GroupListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  locations?: InputMaybe<LocationListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<UuidNullableFilter>;
  outputs?: InputMaybe<OutputListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ProjectWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activities: Array<Activity>;
  activity?: Maybe<Activity>;
  activityImplementation?: Maybe<ActivityImplementation>;
  activityImplementations: Array<ActivityImplementation>;
  aggregateActivity: AggregateActivity;
  aggregateActivityImplementation: AggregateActivityImplementation;
  aggregateBeneficiary: AggregateBeneficiary;
  aggregateBeneficiaryGroup: AggregateBeneficiaryGroup;
  aggregateGroup: AggregateGroup;
  aggregateImplementationAsset: AggregateImplementationAsset;
  aggregateImplementationParticipant: AggregateImplementationParticipant;
  aggregateLocation: AggregateLocation;
  aggregateOrganization: AggregateOrganization;
  aggregateOutput: AggregateOutput;
  aggregateProject: AggregateProject;
  aggregateUser: AggregateUser;
  beneficiaries: Array<Beneficiary>;
  beneficiary?: Maybe<Beneficiary>;
  beneficiaryGroup?: Maybe<BeneficiaryGroup>;
  beneficiaryGroups: Array<BeneficiaryGroup>;
  findFirstActivity?: Maybe<Activity>;
  findFirstActivityImplementation?: Maybe<ActivityImplementation>;
  findFirstActivityImplementationOrThrow?: Maybe<ActivityImplementation>;
  findFirstActivityOrThrow?: Maybe<Activity>;
  findFirstBeneficiary?: Maybe<Beneficiary>;
  findFirstBeneficiaryGroup?: Maybe<BeneficiaryGroup>;
  findFirstBeneficiaryGroupOrThrow?: Maybe<BeneficiaryGroup>;
  findFirstBeneficiaryOrThrow?: Maybe<Beneficiary>;
  findFirstGroup?: Maybe<Group>;
  findFirstGroupOrThrow?: Maybe<Group>;
  findFirstImplementationAsset?: Maybe<ImplementationAsset>;
  findFirstImplementationAssetOrThrow?: Maybe<ImplementationAsset>;
  findFirstImplementationParticipant?: Maybe<ImplementationParticipant>;
  findFirstImplementationParticipantOrThrow?: Maybe<ImplementationParticipant>;
  findFirstLocation?: Maybe<Location>;
  findFirstLocationOrThrow?: Maybe<Location>;
  findFirstOrganization?: Maybe<Organization>;
  findFirstOrganizationOrThrow?: Maybe<Organization>;
  findFirstOutput?: Maybe<Output>;
  findFirstOutputOrThrow?: Maybe<Output>;
  findFirstProject?: Maybe<Project>;
  findFirstProjectOrThrow?: Maybe<Project>;
  findFirstUser?: Maybe<User>;
  findFirstUserOrThrow?: Maybe<User>;
  getActivity?: Maybe<Activity>;
  getActivityImplementation?: Maybe<ActivityImplementation>;
  getBeneficiary?: Maybe<Beneficiary>;
  getBeneficiaryGroup?: Maybe<BeneficiaryGroup>;
  getGroup?: Maybe<Group>;
  getImplementationAsset?: Maybe<ImplementationAsset>;
  getImplementationParticipant?: Maybe<ImplementationParticipant>;
  getLocation?: Maybe<Location>;
  getOrganization?: Maybe<Organization>;
  getOutput?: Maybe<Output>;
  getProject?: Maybe<Project>;
  getUser?: Maybe<User>;
  group?: Maybe<Group>;
  groupByActivity: Array<ActivityGroupBy>;
  groupByActivityImplementation: Array<ActivityImplementationGroupBy>;
  groupByBeneficiary: Array<BeneficiaryGroupBy>;
  groupByBeneficiaryGroup: Array<BeneficiaryGroupGroupBy>;
  groupByGroup: Array<GroupGroupBy>;
  groupByImplementationAsset: Array<ImplementationAssetGroupBy>;
  groupByImplementationParticipant: Array<ImplementationParticipantGroupBy>;
  groupByLocation: Array<LocationGroupBy>;
  groupByOrganization: Array<OrganizationGroupBy>;
  groupByOutput: Array<OutputGroupBy>;
  groupByProject: Array<ProjectGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groups: Array<Group>;
  implementationAsset?: Maybe<ImplementationAsset>;
  implementationAssets: Array<ImplementationAsset>;
  implementationParticipant?: Maybe<ImplementationParticipant>;
  implementationParticipants: Array<ImplementationParticipant>;
  location?: Maybe<Location>;
  locations: Array<Location>;
  organization?: Maybe<Organization>;
  organizations: Array<Organization>;
  output?: Maybe<Output>;
  outputs: Array<Output>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryActivityImplementationArgs = {
  where: ActivityImplementationWhereUniqueInput;
};


export type QueryActivityImplementationsArgs = {
  cursor?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityImplementationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityImplementationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityImplementationWhereInput>;
};


export type QueryAggregateActivityArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryAggregateActivityImplementationArgs = {
  cursor?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ActivityImplementationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityImplementationWhereInput>;
};


export type QueryAggregateBeneficiaryArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type QueryAggregateBeneficiaryGroupArgs = {
  cursor?: InputMaybe<BeneficiaryGroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BeneficiaryGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};


export type QueryAggregateGroupArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryAggregateImplementationAssetArgs = {
  cursor?: InputMaybe<ImplementationAssetWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ImplementationAssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationAssetWhereInput>;
};


export type QueryAggregateImplementationParticipantArgs = {
  cursor?: InputMaybe<ImplementationParticipantWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ImplementationParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};


export type QueryAggregateLocationArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryAggregateOrganizationArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type QueryAggregateOutputArgs = {
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OutputWhereInput>;
};


export type QueryAggregateProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryBeneficiariesArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type QueryBeneficiaryArgs = {
  where: BeneficiaryWhereUniqueInput;
};


export type QueryBeneficiaryGroupArgs = {
  where: BeneficiaryGroupWhereUniqueInput;
};


export type QueryBeneficiaryGroupsArgs = {
  cursor?: InputMaybe<BeneficiaryGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};


export type QueryFindFirstActivityArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryFindFirstActivityImplementationArgs = {
  cursor?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityImplementationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityImplementationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityImplementationWhereInput>;
};


export type QueryFindFirstActivityImplementationOrThrowArgs = {
  cursor?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityImplementationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityImplementationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityImplementationWhereInput>;
};


export type QueryFindFirstActivityOrThrowArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryFindFirstBeneficiaryArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type QueryFindFirstBeneficiaryGroupArgs = {
  cursor?: InputMaybe<BeneficiaryGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};


export type QueryFindFirstBeneficiaryGroupOrThrowArgs = {
  cursor?: InputMaybe<BeneficiaryGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};


export type QueryFindFirstBeneficiaryOrThrowArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type QueryFindFirstGroupArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryFindFirstGroupOrThrowArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryFindFirstImplementationAssetArgs = {
  cursor?: InputMaybe<ImplementationAssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationAssetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationAssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationAssetWhereInput>;
};


export type QueryFindFirstImplementationAssetOrThrowArgs = {
  cursor?: InputMaybe<ImplementationAssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationAssetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationAssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationAssetWhereInput>;
};


export type QueryFindFirstImplementationParticipantArgs = {
  cursor?: InputMaybe<ImplementationParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};


export type QueryFindFirstImplementationParticipantOrThrowArgs = {
  cursor?: InputMaybe<ImplementationParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};


export type QueryFindFirstLocationArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryFindFirstLocationOrThrowArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryFindFirstOrganizationArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type QueryFindFirstOrganizationOrThrowArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type QueryFindFirstOutputArgs = {
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OutputWhereInput>;
};


export type QueryFindFirstOutputOrThrowArgs = {
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OutputWhereInput>;
};


export type QueryFindFirstProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindFirstProjectOrThrowArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstUserOrThrowArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGetActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryGetActivityImplementationArgs = {
  where: ActivityImplementationWhereUniqueInput;
};


export type QueryGetBeneficiaryArgs = {
  where: BeneficiaryWhereUniqueInput;
};


export type QueryGetBeneficiaryGroupArgs = {
  where: BeneficiaryGroupWhereUniqueInput;
};


export type QueryGetGroupArgs = {
  where: GroupWhereUniqueInput;
};


export type QueryGetImplementationAssetArgs = {
  where: ImplementationAssetWhereUniqueInput;
};


export type QueryGetImplementationParticipantArgs = {
  where: ImplementationParticipantWhereUniqueInput;
};


export type QueryGetLocationArgs = {
  where: LocationWhereUniqueInput;
};


export type QueryGetOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryGetOutputArgs = {
  where: OutputWhereUniqueInput;
};


export type QueryGetProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGroupArgs = {
  where: GroupWhereUniqueInput;
};


export type QueryGroupByActivityArgs = {
  by: Array<ActivityScalarFieldEnum>;
  having?: InputMaybe<ActivityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryGroupByActivityImplementationArgs = {
  by: Array<ActivityImplementationScalarFieldEnum>;
  having?: InputMaybe<ActivityImplementationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ActivityImplementationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityImplementationWhereInput>;
};


export type QueryGroupByBeneficiaryArgs = {
  by: Array<BeneficiaryScalarFieldEnum>;
  having?: InputMaybe<BeneficiaryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};


export type QueryGroupByBeneficiaryGroupArgs = {
  by: Array<BeneficiaryGroupScalarFieldEnum>;
  having?: InputMaybe<BeneficiaryGroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BeneficiaryGroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeneficiaryGroupWhereInput>;
};


export type QueryGroupByGroupArgs = {
  by: Array<GroupScalarFieldEnum>;
  having?: InputMaybe<GroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<GroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryGroupByImplementationAssetArgs = {
  by: Array<ImplementationAssetScalarFieldEnum>;
  having?: InputMaybe<ImplementationAssetScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ImplementationAssetOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationAssetWhereInput>;
};


export type QueryGroupByImplementationParticipantArgs = {
  by: Array<ImplementationParticipantScalarFieldEnum>;
  having?: InputMaybe<ImplementationParticipantScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ImplementationParticipantOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};


export type QueryGroupByLocationArgs = {
  by: Array<LocationScalarFieldEnum>;
  having?: InputMaybe<LocationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryGroupByOrganizationArgs = {
  by: Array<OrganizationScalarFieldEnum>;
  having?: InputMaybe<OrganizationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type QueryGroupByOutputArgs = {
  by: Array<OutputScalarFieldEnum>;
  having?: InputMaybe<OutputScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OutputOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OutputWhereInput>;
};


export type QueryGroupByProjectArgs = {
  by: Array<ProjectScalarFieldEnum>;
  having?: InputMaybe<ProjectScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGroupsArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryImplementationAssetArgs = {
  where: ImplementationAssetWhereUniqueInput;
};


export type QueryImplementationAssetsArgs = {
  cursor?: InputMaybe<ImplementationAssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationAssetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationAssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationAssetWhereInput>;
};


export type QueryImplementationParticipantArgs = {
  where: ImplementationParticipantWhereUniqueInput;
};


export type QueryImplementationParticipantsArgs = {
  cursor?: InputMaybe<ImplementationParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImplementationParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImplementationParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImplementationParticipantWhereInput>;
};


export type QueryLocationArgs = {
  where: LocationWhereUniqueInput;
};


export type QueryLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryOrganizationsArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type QueryOutputArgs = {
  where: OutputWhereUniqueInput;
};


export type QueryOutputsArgs = {
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  distinct?: InputMaybe<Array<OutputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OutputWhereInput>;
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  loggedIn: User;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  fullName: Scalars['Int'];
  id: Scalars['Int'];
  isAdmin: Scalars['Int'];
  organizationId: Scalars['Int'];
  password: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutUsersInput>;
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UserCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UserCreateManyOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UserCreateManyOrganizationInputEnvelope = {
  data: Array<UserCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<UserCreateManyOrganizationInputEnvelope>;
};

export type UserCreateOrConnectWithoutOrganizationInput = {
  create: UserCreateWithoutOrganizationInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  organizationId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrderInput>;
  password?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
  username?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrderInput>;
  password?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
  username?: InputMaybe<SortOrder>;
};

export enum UserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  FullName = 'fullName',
  Id = 'id',
  IsAdmin = 'isAdmin',
  OrganizationId = 'organizationId',
  Password = 'password',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isAdmin?: InputMaybe<BoolFilter>;
  organizationId?: InputMaybe<UuidNullableFilter>;
  password?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  fullName?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  isAdmin?: InputMaybe<BoolWithAggregatesFilter>;
  organizationId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  password?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  username?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserUpdateInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutUsersNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutOrganizationInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<UserCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type UserUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: UserUpdateWithoutOrganizationInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutOrganizationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: UserCreateWithoutOrganizationInput;
  update: UserUpdateWithoutOrganizationInput;
  where: UserWhereUniqueInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isAdmin?: InputMaybe<BoolFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<UuidNullableFilter>;
  password?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type UuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type UuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type UuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type ActivitiesQueryVariables = Exact<{
  where?: InputMaybe<ActivityWhereInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput> | ActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum> | ActivityScalarFieldEnum>;
}>;


export type ActivitiesQuery = { __typename?: 'Query', activities: Array<{ __typename?: 'Activity', id: string, code: string, name: string, description?: string | null, createdAt?: any | null, updatedAt?: any | null, output: { __typename?: 'Output', id: string, name: string } }> };

export type ActivityQueryVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type ActivityQuery = { __typename?: 'Query', activity?: { __typename?: 'Activity', id: string, name: string, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type AggregateActivityQueryVariables = Exact<{
  where?: InputMaybe<ActivityWhereInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput> | ActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AggregateActivityQuery = { __typename?: 'Query', aggregateActivity: { __typename?: 'AggregateActivity', _count?: { __typename?: 'ActivityCountAggregate', id: number } | null } };

export type CreateOneActivityMutationVariables = Exact<{
  data: ActivityCreateInput;
}>;


export type CreateOneActivityMutation = { __typename?: 'Mutation', createOneActivity: { __typename?: 'Activity', id: string, code: string, name: string, description?: string | null } };

export type UpdateOneActivityMutationVariables = Exact<{
  data: ActivityUpdateInput;
  where: ActivityWhereUniqueInput;
}>;


export type UpdateOneActivityMutation = { __typename?: 'Mutation', updateOneActivity?: { __typename?: 'Activity', code: string, name: string, description?: string | null } | null };

export type DeleteOneActivityMutationVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type DeleteOneActivityMutation = { __typename?: 'Mutation', deleteOneActivity?: { __typename?: 'Activity', id: string, name: string } | null };

export type ActivityImplementationsQueryVariables = Exact<{
  where?: InputMaybe<ActivityImplementationWhereInput>;
  orderBy?: InputMaybe<Array<ActivityImplementationOrderByWithRelationInput> | ActivityImplementationOrderByWithRelationInput>;
  cursor?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<ActivityImplementationScalarFieldEnum> | ActivityImplementationScalarFieldEnum>;
}>;


export type ActivityImplementationsQuery = { __typename?: 'Query', activityImplementations: Array<{ __typename?: 'ActivityImplementation', id: string, dateStart: any, dateEnd: any, createdAt?: any | null, updatedAt?: any | null, activity: { __typename?: 'Activity', id: string, code: string, name: string } }> };

export type ActivityImplementationQueryVariables = Exact<{
  where: ActivityImplementationWhereUniqueInput;
}>;


export type ActivityImplementationQuery = { __typename?: 'Query', activityImplementation?: { __typename?: 'ActivityImplementation', id: string, dateStart: any, dateEnd: any, createdAt?: any | null, updatedAt?: any | null, activity: { __typename?: 'Activity', id: string, code: string, name: string } } | null };

export type AggregateActivityImplementationQueryVariables = Exact<{
  where?: InputMaybe<ActivityImplementationWhereInput>;
  orderBy?: InputMaybe<Array<ActivityImplementationOrderByWithRelationInput> | ActivityImplementationOrderByWithRelationInput>;
  cursor?: InputMaybe<ActivityImplementationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AggregateActivityImplementationQuery = { __typename?: 'Query', aggregateActivityImplementation: { __typename?: 'AggregateActivityImplementation', _count?: { __typename?: 'ActivityImplementationCountAggregate', id: number } | null } };

export type CreateOneActivityImplementationMutationVariables = Exact<{
  data: ActivityImplementationCreateInput;
}>;


export type CreateOneActivityImplementationMutation = { __typename?: 'Mutation', createOneActivityImplementation: { __typename?: 'ActivityImplementation', id: string, dateStart: any, dateEnd: any, activityId: string, createdAt?: any | null, updatedAt?: any | null } };

export type UpdateOneActivityImplementationMutationVariables = Exact<{
  data: ActivityImplementationUpdateInput;
  where: ActivityImplementationWhereUniqueInput;
}>;


export type UpdateOneActivityImplementationMutation = { __typename?: 'Mutation', updateOneActivityImplementation?: { __typename?: 'ActivityImplementation', id: string, dateStart: any, dateEnd: any, activityId: string, createdAt?: any | null, updatedAt?: any | null } | null };

export type DeleteOneActivityImplementationMutationVariables = Exact<{
  where: ActivityImplementationWhereUniqueInput;
}>;


export type DeleteOneActivityImplementationMutation = { __typename?: 'Mutation', deleteOneActivityImplementation?: { __typename?: 'ActivityImplementation', id: string } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginOutput', success: boolean, access_token?: string | null } };

export type LoggedInSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type LoggedInSubscription = { __typename?: 'Subscription', loggedIn: { __typename?: 'User', fullName?: string | null, email: string } };

export type BeneficiariesQueryVariables = Exact<{
  where?: InputMaybe<BeneficiaryWhereInput>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput> | BeneficiaryOrderByWithRelationInput>;
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum> | BeneficiaryScalarFieldEnum>;
}>;


export type BeneficiariesQuery = { __typename?: 'Query', beneficiaries: Array<{ __typename?: 'Beneficiary', id: string, fullName: string, sex: string, age?: number | null, phone?: string | null, email?: string | null, projectId: string, createdAt?: any | null, updatedAt?: any | null, location?: { __typename?: 'Location', id: string, name: string, isLeaf: boolean } | null, groups: Array<{ __typename?: 'BeneficiaryGroup', group: { __typename?: 'Group', id: string, name: string } }> }> };

export type BeneficiaryQueryVariables = Exact<{
  where: BeneficiaryWhereUniqueInput;
}>;


export type BeneficiaryQuery = { __typename?: 'Query', beneficiary?: { __typename?: 'Beneficiary', id: string, fullName: string, sex: string, age?: number | null, phone?: string | null, email?: string | null, projectId: string, createdAt?: any | null, updatedAt?: any | null, location?: { __typename?: 'Location', id: string, name: string, isLeaf: boolean } | null, groups: Array<{ __typename?: 'BeneficiaryGroup', group: { __typename?: 'Group', id: string, name: string } }> } | null };

export type AggregateBeneficiaryQueryVariables = Exact<{
  where?: InputMaybe<BeneficiaryWhereInput>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput> | BeneficiaryOrderByWithRelationInput>;
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AggregateBeneficiaryQuery = { __typename?: 'Query', aggregateBeneficiary: { __typename?: 'AggregateBeneficiary', _count?: { __typename?: 'BeneficiaryCountAggregate', id: number } | null } };

export type CreateOneBeneficiaryMutationVariables = Exact<{
  data: BeneficiaryCreateInput;
}>;


export type CreateOneBeneficiaryMutation = { __typename?: 'Mutation', createOneBeneficiary: { __typename?: 'Beneficiary', id: string, fullName: string, sex: string, age?: number | null } };

export type UpdateOneBeneficiaryMutationVariables = Exact<{
  data: BeneficiaryUpdateInput;
  where: BeneficiaryWhereUniqueInput;
}>;


export type UpdateOneBeneficiaryMutation = { __typename?: 'Mutation', updateOneBeneficiary?: { __typename?: 'Beneficiary', id: string, fullName: string, sex: string, age?: number | null } | null };

export type DeleteOneBeneficiaryMutationVariables = Exact<{
  where: BeneficiaryWhereUniqueInput;
}>;


export type DeleteOneBeneficiaryMutation = { __typename?: 'Mutation', deleteOneBeneficiary?: { __typename?: 'Beneficiary', id: string } | null };

export type DeleteManyBeneficiaryGroupMutationVariables = Exact<{
  where: BeneficiaryGroupWhereInput;
}>;


export type DeleteManyBeneficiaryGroupMutation = { __typename?: 'Mutation', deleteManyBeneficiaryGroup: { __typename?: 'AffectedRowsOutput', count: number } };

export type GroupsQueryVariables = Exact<{
  where?: InputMaybe<GroupWhereInput>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput> | GroupOrderByWithRelationInput>;
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum> | GroupScalarFieldEnum>;
}>;


export type GroupsQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, name: string, isLeaf: boolean, createdAt?: any | null, updatedAt?: any | null, parent?: { __typename?: 'Group', id: string, name: string } | null, children: Array<{ __typename?: 'Group', id: string, name: string, parentId?: string | null }> }> };

export type GroupQueryVariables = Exact<{
  where: GroupWhereUniqueInput;
}>;


export type GroupQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, name: string, isLeaf: boolean, createdAt?: any | null, updatedAt?: any | null, parent?: { __typename?: 'Group', id: string, name: string } | null, children: Array<{ __typename?: 'Group', id: string, name: string, parentId?: string | null }> } | null };

export type GroupTreeQueryVariables = Exact<{
  where?: InputMaybe<GroupWhereInput>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput> | GroupOrderByWithRelationInput>;
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum> | GroupScalarFieldEnum>;
}>;


export type GroupTreeQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, name: string, level: number, isLeaf: boolean, children: Array<{ __typename?: 'Group', id: string, name: string, level: number, isLeaf: boolean, children: Array<{ __typename?: 'Group', id: string, name: string, level: number, isLeaf: boolean, children: Array<{ __typename?: 'Group', id: string, name: string, level: number, isLeaf: boolean }> }> }> }> };

export type AggregateGroupQueryVariables = Exact<{
  where?: InputMaybe<GroupWhereInput>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput> | GroupOrderByWithRelationInput>;
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AggregateGroupQuery = { __typename?: 'Query', aggregateGroup: { __typename?: 'AggregateGroup', _count?: { __typename?: 'GroupCountAggregate', id: number } | null } };

export type CreateOneGroupMutationVariables = Exact<{
  data: GroupCreateInput;
}>;


export type CreateOneGroupMutation = { __typename?: 'Mutation', createOneGroup: { __typename?: 'Group', id: string, name: string } };

export type UpdateOneGroupMutationVariables = Exact<{
  data: GroupUpdateInput;
  where: GroupWhereUniqueInput;
}>;


export type UpdateOneGroupMutation = { __typename?: 'Mutation', updateOneGroup?: { __typename?: 'Group', id: string, name: string } | null };

export type DeleteOneGroupMutationVariables = Exact<{
  where: GroupWhereUniqueInput;
}>;


export type DeleteOneGroupMutation = { __typename?: 'Mutation', deleteOneGroup?: { __typename?: 'Group', id: string, name: string } | null };

export type ImplementationAssetsQueryVariables = Exact<{
  where?: InputMaybe<ImplementationAssetWhereInput>;
  orderBy?: InputMaybe<Array<ImplementationAssetOrderByWithRelationInput> | ImplementationAssetOrderByWithRelationInput>;
  cursor?: InputMaybe<ImplementationAssetWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<ImplementationAssetScalarFieldEnum> | ImplementationAssetScalarFieldEnum>;
}>;


export type ImplementationAssetsQuery = { __typename?: 'Query', implementationAssets: Array<{ __typename?: 'ImplementationAsset', id: string, implementationId: string, type: string, url: string, createdAt?: any | null, updatedAt?: any | null }> };

export type ImplementationAssetQueryVariables = Exact<{
  where: ImplementationAssetWhereUniqueInput;
}>;


export type ImplementationAssetQuery = { __typename?: 'Query', implementationAsset?: { __typename?: 'ImplementationAsset', id: string, implementationId: string, type: string, url: string, createdAt?: any | null, updatedAt?: any | null } | null };

export type AggregateAssetQueryVariables = Exact<{
  where: ImplementationAssetWhereInput;
}>;


export type AggregateAssetQuery = { __typename?: 'Query', aggregateImplementationAsset: { __typename?: 'AggregateImplementationAsset', _count?: { __typename?: 'ImplementationAssetCountAggregate', id: number } | null } };

export type CreateOneImplementationAssetMutationVariables = Exact<{
  data: ImplementationAssetCreateInput;
}>;


export type CreateOneImplementationAssetMutation = { __typename?: 'Mutation', createOneImplementationAsset: { __typename?: 'ImplementationAsset', id: string, implementationId: string, type: string, url: string, createdAt?: any | null, updatedAt?: any | null } };

export type UpdateOneImplementationAssetMutationVariables = Exact<{
  data: ImplementationAssetUpdateInput;
  where: ImplementationAssetWhereUniqueInput;
}>;


export type UpdateOneImplementationAssetMutation = { __typename?: 'Mutation', updateOneImplementationAsset?: { __typename?: 'ImplementationAsset', id: string, implementationId: string, type: string, url: string, createdAt?: any | null, updatedAt?: any | null } | null };

export type DeleteOneImplementationAssetMutationVariables = Exact<{
  where: ImplementationAssetWhereUniqueInput;
}>;


export type DeleteOneImplementationAssetMutation = { __typename?: 'Mutation', deleteOneImplementationAsset?: { __typename?: 'ImplementationAsset', id: string } | null };

export type ImplementationParticipantsQueryVariables = Exact<{
  where?: InputMaybe<ImplementationParticipantWhereInput>;
  orderBy?: InputMaybe<Array<ImplementationParticipantOrderByWithRelationInput> | ImplementationParticipantOrderByWithRelationInput>;
  cursor?: InputMaybe<ImplementationParticipantWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<ImplementationParticipantScalarFieldEnum> | ImplementationParticipantScalarFieldEnum>;
}>;


export type ImplementationParticipantsQuery = { __typename?: 'Query', implementationParticipants: Array<{ __typename?: 'ImplementationParticipant', id: string, beneficiaryId: string, implementationId: string, createdAt?: any | null, updatedAt?: any | null, implementation: { __typename?: 'ActivityImplementation', id: string, activityId: string }, beneficiary: { __typename?: 'Beneficiary', id: string, fullName: string, sex: string, age?: number | null, phone?: string | null, email?: string | null, location?: { __typename?: 'Location', id: string, name: string, isLeaf: boolean } | null, groups: Array<{ __typename?: 'BeneficiaryGroup', group: { __typename?: 'Group', id: string, name: string } }> } }> };

export type ImplementationParticipantQueryVariables = Exact<{
  where: ImplementationParticipantWhereUniqueInput;
}>;


export type ImplementationParticipantQuery = { __typename?: 'Query', implementationParticipant?: { __typename?: 'ImplementationParticipant', id: string, beneficiaryId: string, implementationId: string, createdAt?: any | null, updatedAt?: any | null, beneficiary: { __typename?: 'Beneficiary', id: string, fullName: string, sex: string, age?: number | null, phone?: string | null, email?: string | null, location?: { __typename?: 'Location', id: string, name: string, isLeaf: boolean } | null, groups: Array<{ __typename?: 'BeneficiaryGroup', group: { __typename?: 'Group', id: string, name: string } }> } } | null };

export type AggregateParticipantQueryVariables = Exact<{
  where: ImplementationParticipantWhereInput;
}>;


export type AggregateParticipantQuery = { __typename?: 'Query', aggregateImplementationParticipant: { __typename?: 'AggregateImplementationParticipant', _count?: { __typename?: 'ImplementationParticipantCountAggregate', id: number } | null } };

export type CreateOneImplementationParticipantMutationVariables = Exact<{
  data: ImplementationParticipantCreateInput;
}>;


export type CreateOneImplementationParticipantMutation = { __typename?: 'Mutation', createOneImplementationParticipant: { __typename?: 'ImplementationParticipant', id: string, beneficiaryId: string, implementationId: string, createdAt?: any | null, updatedAt?: any | null } };

export type UpdateOneImplementationParticipantMutationVariables = Exact<{
  data: ImplementationParticipantUpdateInput;
  where: ImplementationParticipantWhereUniqueInput;
}>;


export type UpdateOneImplementationParticipantMutation = { __typename?: 'Mutation', updateOneImplementationParticipant?: { __typename?: 'ImplementationParticipant', id: string, beneficiaryId: string, implementationId: string, createdAt?: any | null, updatedAt?: any | null } | null };

export type DeleteOneImplementationParticipantMutationVariables = Exact<{
  where: ImplementationParticipantWhereUniqueInput;
}>;


export type DeleteOneImplementationParticipantMutation = { __typename?: 'Mutation', deleteOneImplementationParticipant?: { __typename?: 'ImplementationParticipant', id: string } | null };

export type LocationsQueryVariables = Exact<{
  where?: InputMaybe<LocationWhereInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput> | LocationOrderByWithRelationInput>;
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum> | LocationScalarFieldEnum>;
}>;


export type LocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', id: string, name: string, isLeaf: boolean, createdAt?: any | null, updatedAt?: any | null, parent?: { __typename?: 'Location', id: string, name: string } | null, children: Array<{ __typename?: 'Location', id: string, name: string, parentId?: string | null }> }> };

export type LocationQueryVariables = Exact<{
  where: LocationWhereUniqueInput;
}>;


export type LocationQuery = { __typename?: 'Query', location?: { __typename?: 'Location', id: string, name: string, isLeaf: boolean, createdAt?: any | null, updatedAt?: any | null, parent?: { __typename?: 'Location', id: string, name: string } | null, children: Array<{ __typename?: 'Location', id: string, name: string, parentId?: string | null }> } | null };

export type LocationTreeQueryVariables = Exact<{
  where?: InputMaybe<LocationWhereInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput> | LocationOrderByWithRelationInput>;
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum> | LocationScalarFieldEnum>;
}>;


export type LocationTreeQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', id: string, name: string, level: number, isLeaf: boolean, projectId: string, children: Array<{ __typename?: 'Location', id: string, name: string, level: number, isLeaf: boolean, projectId: string, children: Array<{ __typename?: 'Location', id: string, name: string, level: number, isLeaf: boolean, projectId: string, children: Array<{ __typename?: 'Location', id: string, name: string, level: number, isLeaf: boolean, projectId: string }> }> }> }> };

export type AggregateLocationQueryVariables = Exact<{
  where?: InputMaybe<LocationWhereInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput> | LocationOrderByWithRelationInput>;
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AggregateLocationQuery = { __typename?: 'Query', aggregateLocation: { __typename?: 'AggregateLocation', _count?: { __typename?: 'LocationCountAggregate', id: number } | null } };

export type CreateOneLocationMutationVariables = Exact<{
  data: LocationCreateInput;
}>;


export type CreateOneLocationMutation = { __typename?: 'Mutation', createOneLocation: { __typename?: 'Location', id: string, name: string } };

export type UpdateOneLocationMutationVariables = Exact<{
  data: LocationUpdateInput;
  where: LocationWhereUniqueInput;
}>;


export type UpdateOneLocationMutation = { __typename?: 'Mutation', updateOneLocation?: { __typename?: 'Location', id: string, name: string } | null };

export type DeleteOneLocationMutationVariables = Exact<{
  where: LocationWhereUniqueInput;
}>;


export type DeleteOneLocationMutation = { __typename?: 'Mutation', deleteOneLocation?: { __typename?: 'Location', id: string, name: string } | null };

export type OrganizationsQueryVariables = Exact<{
  where?: InputMaybe<OrganizationWhereInput>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput> | OrganizationOrderByWithRelationInput>;
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum> | OrganizationScalarFieldEnum>;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: string, name: string, description?: string | null, createdAt?: any | null, updatedAt?: any | null }> };

export type OrganizationQueryVariables = Exact<{
  where: OrganizationWhereUniqueInput;
}>;


export type OrganizationQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, name: string, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type CreateOneOrganizationMutationVariables = Exact<{
  data: OrganizationCreateInput;
}>;


export type CreateOneOrganizationMutation = { __typename?: 'Mutation', createOneOrganization: { __typename?: 'Organization', id: string, name: string, description?: string | null } };

export type UpdateOneOrganizationMutationVariables = Exact<{
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
}>;


export type UpdateOneOrganizationMutation = { __typename?: 'Mutation', updateOneOrganization?: { __typename?: 'Organization', name: string, description?: string | null } | null };

export type DeleteOneOrganizationMutationVariables = Exact<{
  where: OrganizationWhereUniqueInput;
}>;


export type DeleteOneOrganizationMutation = { __typename?: 'Mutation', deleteOneOrganization?: { __typename?: 'Organization', id: string, name: string } | null };

export type OutputsQueryVariables = Exact<{
  where?: InputMaybe<OutputWhereInput>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput> | OutputOrderByWithRelationInput>;
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<OutputScalarFieldEnum> | OutputScalarFieldEnum>;
}>;


export type OutputsQuery = { __typename?: 'Query', outputs: Array<{ __typename?: 'Output', id: string, name: string, description?: string | null, createdAt?: any | null, updatedAt?: any | null }> };

export type OutputQueryVariables = Exact<{
  where: OutputWhereUniqueInput;
}>;


export type OutputQuery = { __typename?: 'Query', output?: { __typename?: 'Output', id: string, name: string, description?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type OutputTreeQueryVariables = Exact<{
  where?: InputMaybe<OutputWhereInput>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput> | OutputOrderByWithRelationInput>;
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<OutputScalarFieldEnum> | OutputScalarFieldEnum>;
}>;


export type OutputTreeQuery = { __typename?: 'Query', outputs: Array<{ __typename?: 'Output', id: string, name: string, description?: string | null, projectId: string, createdAt?: any | null, updatedAt?: any | null, activities: Array<{ __typename?: 'Activity', id: string, code: string, name: string, description?: string | null, outputId: string, projectId: string, createdAt?: any | null, updatedAt?: any | null }> }> };

export type OutputTreeImplementationQueryVariables = Exact<{
  where?: InputMaybe<OutputWhereInput>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput> | OutputOrderByWithRelationInput>;
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<OutputScalarFieldEnum> | OutputScalarFieldEnum>;
}>;


export type OutputTreeImplementationQuery = { __typename?: 'Query', outputs: Array<{ __typename?: 'Output', id: string, name: string, description?: string | null, projectId: string, createdAt?: any | null, updatedAt?: any | null, activities: Array<{ __typename?: 'Activity', id: string, code: string, name: string, description?: string | null, outputId: string, projectId: string, createdAt?: any | null, updatedAt?: any | null, implementations: Array<{ __typename?: 'ActivityImplementation', id: string, dateStart: any, dateEnd: any, description: string, createdAt?: any | null, updatedAt?: any | null, activityId: string }> }> }> };

export type AggregateOutputQueryVariables = Exact<{
  where?: InputMaybe<OutputWhereInput>;
  orderBy?: InputMaybe<Array<OutputOrderByWithRelationInput> | OutputOrderByWithRelationInput>;
  cursor?: InputMaybe<OutputWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AggregateOutputQuery = { __typename?: 'Query', aggregateOutput: { __typename?: 'AggregateOutput', _count?: { __typename?: 'OutputCountAggregate', id: number } | null } };

export type CreateOneOutputMutationVariables = Exact<{
  data: OutputCreateInput;
}>;


export type CreateOneOutputMutation = { __typename?: 'Mutation', createOneOutput: { __typename?: 'Output', id: string, name: string } };

export type UpdateOneOutputMutationVariables = Exact<{
  data: OutputUpdateInput;
  where: OutputWhereUniqueInput;
}>;


export type UpdateOneOutputMutation = { __typename?: 'Mutation', updateOneOutput?: { __typename?: 'Output', id: string, name: string } | null };

export type DeleteOneOutputMutationVariables = Exact<{
  where: OutputWhereUniqueInput;
}>;


export type DeleteOneOutputMutation = { __typename?: 'Mutation', deleteOneOutput?: { __typename?: 'Output', id: string, name: string } | null };

export type ProjectsQueryVariables = Exact<{
  where?: InputMaybe<ProjectWhereInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput> | ProjectOrderByWithRelationInput>;
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum> | ProjectScalarFieldEnum>;
}>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: string, code: string, name: string, description?: string | null, createdAt?: any | null, updatedAt?: any | null, organization?: { __typename?: 'Organization', id: string, name: string } | null }> };

export type ProjectQueryVariables = Exact<{
  where: ProjectWhereUniqueInput;
}>;


export type ProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, code: string, name: string, description?: string | null, createdAt?: any | null, updatedAt?: any | null, organization?: { __typename?: 'Organization', id: string, name: string } | null } | null };

export type CreateOneProjectMutationVariables = Exact<{
  data: ProjectCreateInput;
}>;


export type CreateOneProjectMutation = { __typename?: 'Mutation', createOneProject: { __typename?: 'Project', id: string, code: string, name: string } };

export type UpdateOneProjectMutationVariables = Exact<{
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
}>;


export type UpdateOneProjectMutation = { __typename?: 'Mutation', updateOneProject?: { __typename?: 'Project', id: string, code: string, name: string } | null };

export type DeleteOneProjectMutationVariables = Exact<{
  where: ProjectWhereUniqueInput;
}>;


export type DeleteOneProjectMutation = { __typename?: 'Mutation', deleteOneProject?: { __typename?: 'Project', id: string, code: string, name: string } | null };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
  cursor?: InputMaybe<UserWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum> | UserScalarFieldEnum>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, username: string, fullName?: string | null, email: string, isAdmin: boolean, createdAt?: any | null, updatedAt?: any | null, organization?: { __typename?: 'Organization', id: string, name: string } | null }> };

export type UserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, username: string, fullName?: string | null, email: string, isAdmin: boolean, createdAt?: any | null, updatedAt?: any | null, organization?: { __typename?: 'Organization', id: string, name: string } | null } | null };

export type CreateOneUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateOneUserMutation = { __typename?: 'Mutation', createOneUser: { __typename?: 'User', id: string, email: string } };

export type UpdateOneUserMutationVariables = Exact<{
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
}>;


export type UpdateOneUserMutation = { __typename?: 'Mutation', updateOneUser?: { __typename?: 'User', id: string, email: string } | null };

export type DeleteOneUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type DeleteOneUserMutation = { __typename?: 'Mutation', deleteOneUser?: { __typename?: 'User', id: string, email: string } | null };

export const ActivitiesDocument = gql`
    query activities($where: ActivityWhereInput, $orderBy: [ActivityOrderByWithRelationInput!], $cursor: ActivityWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ActivityScalarFieldEnum!]) {
  activities(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    code
    name
    description
    output {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivitiesGQL extends Apollo.Query<ActivitiesQuery, ActivitiesQueryVariables> {
    document = ActivitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActivityDocument = gql`
    query activity($where: ActivityWhereUniqueInput!) {
  activity(where: $where) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivityGQL extends Apollo.Query<ActivityQuery, ActivityQueryVariables> {
    document = ActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateActivityDocument = gql`
    query aggregateActivity($where: ActivityWhereInput, $orderBy: [ActivityOrderByWithRelationInput!], $cursor: ActivityWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateActivity(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateActivityGQL extends Apollo.Query<AggregateActivityQuery, AggregateActivityQueryVariables> {
    document = AggregateActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneActivityDocument = gql`
    mutation createOneActivity($data: ActivityCreateInput!) {
  createOneActivity(data: $data) {
    id
    code
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneActivityGQL extends Apollo.Mutation<CreateOneActivityMutation, CreateOneActivityMutationVariables> {
    document = CreateOneActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneActivityDocument = gql`
    mutation updateOneActivity($data: ActivityUpdateInput!, $where: ActivityWhereUniqueInput!) {
  updateOneActivity(data: $data, where: $where) {
    code
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneActivityGQL extends Apollo.Mutation<UpdateOneActivityMutation, UpdateOneActivityMutationVariables> {
    document = UpdateOneActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneActivityDocument = gql`
    mutation deleteOneActivity($where: ActivityWhereUniqueInput!) {
  deleteOneActivity(where: $where) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneActivityGQL extends Apollo.Mutation<DeleteOneActivityMutation, DeleteOneActivityMutationVariables> {
    document = DeleteOneActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActivityImplementationsDocument = gql`
    query activityImplementations($where: ActivityImplementationWhereInput, $orderBy: [ActivityImplementationOrderByWithRelationInput!], $cursor: ActivityImplementationWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ActivityImplementationScalarFieldEnum!]) {
  activityImplementations(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    dateStart
    dateEnd
    activity {
      id
      code
      name
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivityImplementationsGQL extends Apollo.Query<ActivityImplementationsQuery, ActivityImplementationsQueryVariables> {
    document = ActivityImplementationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActivityImplementationDocument = gql`
    query activityImplementation($where: ActivityImplementationWhereUniqueInput!) {
  activityImplementation(where: $where) {
    id
    dateStart
    dateEnd
    activity {
      id
      code
      name
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivityImplementationGQL extends Apollo.Query<ActivityImplementationQuery, ActivityImplementationQueryVariables> {
    document = ActivityImplementationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateActivityImplementationDocument = gql`
    query aggregateActivityImplementation($where: ActivityImplementationWhereInput, $orderBy: [ActivityImplementationOrderByWithRelationInput!], $cursor: ActivityImplementationWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateActivityImplementation(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateActivityImplementationGQL extends Apollo.Query<AggregateActivityImplementationQuery, AggregateActivityImplementationQueryVariables> {
    document = AggregateActivityImplementationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneActivityImplementationDocument = gql`
    mutation createOneActivityImplementation($data: ActivityImplementationCreateInput!) {
  createOneActivityImplementation(data: $data) {
    id
    dateStart
    dateEnd
    activityId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneActivityImplementationGQL extends Apollo.Mutation<CreateOneActivityImplementationMutation, CreateOneActivityImplementationMutationVariables> {
    document = CreateOneActivityImplementationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneActivityImplementationDocument = gql`
    mutation updateOneActivityImplementation($data: ActivityImplementationUpdateInput!, $where: ActivityImplementationWhereUniqueInput!) {
  updateOneActivityImplementation(data: $data, where: $where) {
    id
    dateStart
    dateEnd
    activityId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneActivityImplementationGQL extends Apollo.Mutation<UpdateOneActivityImplementationMutation, UpdateOneActivityImplementationMutationVariables> {
    document = UpdateOneActivityImplementationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneActivityImplementationDocument = gql`
    mutation deleteOneActivityImplementation($where: ActivityImplementationWhereUniqueInput!) {
  deleteOneActivityImplementation(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneActivityImplementationGQL extends Apollo.Mutation<DeleteOneActivityImplementationMutation, DeleteOneActivityImplementationMutationVariables> {
    document = DeleteOneActivityImplementationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    success
    access_token
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoggedInDocument = gql`
    subscription loggedIn {
  loggedIn {
    fullName
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoggedInGQL extends Apollo.Subscription<LoggedInSubscription, LoggedInSubscriptionVariables> {
    document = LoggedInDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BeneficiariesDocument = gql`
    query beneficiaries($where: BeneficiaryWhereInput, $orderBy: [BeneficiaryOrderByWithRelationInput!], $cursor: BeneficiaryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [BeneficiaryScalarFieldEnum!]) {
  beneficiaries(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    fullName
    sex
    age
    phone
    email
    location {
      id
      name
      isLeaf
    }
    groups {
      group {
        id
        name
      }
    }
    projectId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BeneficiariesGQL extends Apollo.Query<BeneficiariesQuery, BeneficiariesQueryVariables> {
    document = BeneficiariesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BeneficiaryDocument = gql`
    query beneficiary($where: BeneficiaryWhereUniqueInput!) {
  beneficiary(where: $where) {
    id
    fullName
    sex
    age
    phone
    email
    location {
      id
      name
      isLeaf
    }
    groups {
      group {
        id
        name
      }
    }
    projectId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BeneficiaryGQL extends Apollo.Query<BeneficiaryQuery, BeneficiaryQueryVariables> {
    document = BeneficiaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateBeneficiaryDocument = gql`
    query aggregateBeneficiary($where: BeneficiaryWhereInput, $orderBy: [BeneficiaryOrderByWithRelationInput!], $cursor: BeneficiaryWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateBeneficiary(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateBeneficiaryGQL extends Apollo.Query<AggregateBeneficiaryQuery, AggregateBeneficiaryQueryVariables> {
    document = AggregateBeneficiaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneBeneficiaryDocument = gql`
    mutation createOneBeneficiary($data: BeneficiaryCreateInput!) {
  createOneBeneficiary(data: $data) {
    id
    fullName
    sex
    age
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneBeneficiaryGQL extends Apollo.Mutation<CreateOneBeneficiaryMutation, CreateOneBeneficiaryMutationVariables> {
    document = CreateOneBeneficiaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneBeneficiaryDocument = gql`
    mutation updateOneBeneficiary($data: BeneficiaryUpdateInput!, $where: BeneficiaryWhereUniqueInput!) {
  updateOneBeneficiary(data: $data, where: $where) {
    id
    fullName
    sex
    age
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneBeneficiaryGQL extends Apollo.Mutation<UpdateOneBeneficiaryMutation, UpdateOneBeneficiaryMutationVariables> {
    document = UpdateOneBeneficiaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneBeneficiaryDocument = gql`
    mutation deleteOneBeneficiary($where: BeneficiaryWhereUniqueInput!) {
  deleteOneBeneficiary(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneBeneficiaryGQL extends Apollo.Mutation<DeleteOneBeneficiaryMutation, DeleteOneBeneficiaryMutationVariables> {
    document = DeleteOneBeneficiaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteManyBeneficiaryGroupDocument = gql`
    mutation deleteManyBeneficiaryGroup($where: BeneficiaryGroupWhereInput!) {
  deleteManyBeneficiaryGroup(where: $where) {
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteManyBeneficiaryGroupGQL extends Apollo.Mutation<DeleteManyBeneficiaryGroupMutation, DeleteManyBeneficiaryGroupMutationVariables> {
    document = DeleteManyBeneficiaryGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GroupsDocument = gql`
    query groups($where: GroupWhereInput, $orderBy: [GroupOrderByWithRelationInput!], $cursor: GroupWhereUniqueInput, $take: Int, $skip: Int, $distinct: [GroupScalarFieldEnum!]) {
  groups(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    parent {
      id
      name
    }
    isLeaf
    children {
      id
      name
      parentId
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupsGQL extends Apollo.Query<GroupsQuery, GroupsQueryVariables> {
    document = GroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GroupDocument = gql`
    query group($where: GroupWhereUniqueInput!) {
  group(where: $where) {
    id
    name
    parent {
      id
      name
    }
    isLeaf
    children {
      id
      name
      parentId
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupGQL extends Apollo.Query<GroupQuery, GroupQueryVariables> {
    document = GroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GroupTreeDocument = gql`
    query groupTree($where: GroupWhereInput, $orderBy: [GroupOrderByWithRelationInput!], $cursor: GroupWhereUniqueInput, $take: Int, $skip: Int, $distinct: [GroupScalarFieldEnum!]) {
  groups(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    level
    isLeaf
    children {
      id
      name
      level
      isLeaf
      children {
        id
        name
        level
        isLeaf
        children {
          id
          name
          level
          isLeaf
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupTreeGQL extends Apollo.Query<GroupTreeQuery, GroupTreeQueryVariables> {
    document = GroupTreeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateGroupDocument = gql`
    query aggregateGroup($where: GroupWhereInput, $orderBy: [GroupOrderByWithRelationInput!], $cursor: GroupWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateGroup(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateGroupGQL extends Apollo.Query<AggregateGroupQuery, AggregateGroupQueryVariables> {
    document = AggregateGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneGroupDocument = gql`
    mutation createOneGroup($data: GroupCreateInput!) {
  createOneGroup(data: $data) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneGroupGQL extends Apollo.Mutation<CreateOneGroupMutation, CreateOneGroupMutationVariables> {
    document = CreateOneGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneGroupDocument = gql`
    mutation updateOneGroup($data: GroupUpdateInput!, $where: GroupWhereUniqueInput!) {
  updateOneGroup(data: $data, where: $where) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneGroupGQL extends Apollo.Mutation<UpdateOneGroupMutation, UpdateOneGroupMutationVariables> {
    document = UpdateOneGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneGroupDocument = gql`
    mutation deleteOneGroup($where: GroupWhereUniqueInput!) {
  deleteOneGroup(where: $where) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneGroupGQL extends Apollo.Mutation<DeleteOneGroupMutation, DeleteOneGroupMutationVariables> {
    document = DeleteOneGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImplementationAssetsDocument = gql`
    query implementationAssets($where: ImplementationAssetWhereInput, $orderBy: [ImplementationAssetOrderByWithRelationInput!], $cursor: ImplementationAssetWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ImplementationAssetScalarFieldEnum!]) {
  implementationAssets(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    implementationId
    type
    url
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImplementationAssetsGQL extends Apollo.Query<ImplementationAssetsQuery, ImplementationAssetsQueryVariables> {
    document = ImplementationAssetsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImplementationAssetDocument = gql`
    query implementationAsset($where: ImplementationAssetWhereUniqueInput!) {
  implementationAsset(where: $where) {
    id
    implementationId
    type
    url
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImplementationAssetGQL extends Apollo.Query<ImplementationAssetQuery, ImplementationAssetQueryVariables> {
    document = ImplementationAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateAssetDocument = gql`
    query aggregateAsset($where: ImplementationAssetWhereInput!) {
  aggregateImplementationAsset(where: $where) {
    _count {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateAssetGQL extends Apollo.Query<AggregateAssetQuery, AggregateAssetQueryVariables> {
    document = AggregateAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneImplementationAssetDocument = gql`
    mutation createOneImplementationAsset($data: ImplementationAssetCreateInput!) {
  createOneImplementationAsset(data: $data) {
    id
    implementationId
    type
    url
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneImplementationAssetGQL extends Apollo.Mutation<CreateOneImplementationAssetMutation, CreateOneImplementationAssetMutationVariables> {
    document = CreateOneImplementationAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneImplementationAssetDocument = gql`
    mutation updateOneImplementationAsset($data: ImplementationAssetUpdateInput!, $where: ImplementationAssetWhereUniqueInput!) {
  updateOneImplementationAsset(data: $data, where: $where) {
    id
    implementationId
    type
    url
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneImplementationAssetGQL extends Apollo.Mutation<UpdateOneImplementationAssetMutation, UpdateOneImplementationAssetMutationVariables> {
    document = UpdateOneImplementationAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneImplementationAssetDocument = gql`
    mutation deleteOneImplementationAsset($where: ImplementationAssetWhereUniqueInput!) {
  deleteOneImplementationAsset(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneImplementationAssetGQL extends Apollo.Mutation<DeleteOneImplementationAssetMutation, DeleteOneImplementationAssetMutationVariables> {
    document = DeleteOneImplementationAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImplementationParticipantsDocument = gql`
    query implementationParticipants($where: ImplementationParticipantWhereInput, $orderBy: [ImplementationParticipantOrderByWithRelationInput!], $cursor: ImplementationParticipantWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ImplementationParticipantScalarFieldEnum!]) {
  implementationParticipants(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    beneficiaryId
    implementationId
    implementation {
      id
      activityId
    }
    beneficiary {
      id
      fullName
      sex
      age
      phone
      email
      location {
        id
        name
        isLeaf
      }
      groups {
        group {
          id
          name
        }
      }
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImplementationParticipantsGQL extends Apollo.Query<ImplementationParticipantsQuery, ImplementationParticipantsQueryVariables> {
    document = ImplementationParticipantsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImplementationParticipantDocument = gql`
    query implementationParticipant($where: ImplementationParticipantWhereUniqueInput!) {
  implementationParticipant(where: $where) {
    id
    beneficiaryId
    implementationId
    beneficiary {
      id
      fullName
      sex
      age
      phone
      email
      location {
        id
        name
        isLeaf
      }
      groups {
        group {
          id
          name
        }
      }
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImplementationParticipantGQL extends Apollo.Query<ImplementationParticipantQuery, ImplementationParticipantQueryVariables> {
    document = ImplementationParticipantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateParticipantDocument = gql`
    query aggregateParticipant($where: ImplementationParticipantWhereInput!) {
  aggregateImplementationParticipant(where: $where) {
    _count {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateParticipantGQL extends Apollo.Query<AggregateParticipantQuery, AggregateParticipantQueryVariables> {
    document = AggregateParticipantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneImplementationParticipantDocument = gql`
    mutation createOneImplementationParticipant($data: ImplementationParticipantCreateInput!) {
  createOneImplementationParticipant(data: $data) {
    id
    beneficiaryId
    implementationId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneImplementationParticipantGQL extends Apollo.Mutation<CreateOneImplementationParticipantMutation, CreateOneImplementationParticipantMutationVariables> {
    document = CreateOneImplementationParticipantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneImplementationParticipantDocument = gql`
    mutation updateOneImplementationParticipant($data: ImplementationParticipantUpdateInput!, $where: ImplementationParticipantWhereUniqueInput!) {
  updateOneImplementationParticipant(data: $data, where: $where) {
    id
    beneficiaryId
    implementationId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneImplementationParticipantGQL extends Apollo.Mutation<UpdateOneImplementationParticipantMutation, UpdateOneImplementationParticipantMutationVariables> {
    document = UpdateOneImplementationParticipantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneImplementationParticipantDocument = gql`
    mutation deleteOneImplementationParticipant($where: ImplementationParticipantWhereUniqueInput!) {
  deleteOneImplementationParticipant(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneImplementationParticipantGQL extends Apollo.Mutation<DeleteOneImplementationParticipantMutation, DeleteOneImplementationParticipantMutationVariables> {
    document = DeleteOneImplementationParticipantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LocationsDocument = gql`
    query locations($where: LocationWhereInput, $orderBy: [LocationOrderByWithRelationInput!], $cursor: LocationWhereUniqueInput, $take: Int, $skip: Int, $distinct: [LocationScalarFieldEnum!]) {
  locations(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    parent {
      id
      name
    }
    isLeaf
    children {
      id
      name
      parentId
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LocationsGQL extends Apollo.Query<LocationsQuery, LocationsQueryVariables> {
    document = LocationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LocationDocument = gql`
    query location($where: LocationWhereUniqueInput!) {
  location(where: $where) {
    id
    name
    parent {
      id
      name
    }
    isLeaf
    children {
      id
      name
      parentId
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LocationGQL extends Apollo.Query<LocationQuery, LocationQueryVariables> {
    document = LocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LocationTreeDocument = gql`
    query locationTree($where: LocationWhereInput, $orderBy: [LocationOrderByWithRelationInput!], $cursor: LocationWhereUniqueInput, $take: Int, $skip: Int, $distinct: [LocationScalarFieldEnum!]) {
  locations(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    level
    isLeaf
    projectId
    children {
      id
      name
      level
      isLeaf
      projectId
      children {
        id
        name
        level
        isLeaf
        projectId
        children {
          id
          name
          level
          isLeaf
          projectId
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LocationTreeGQL extends Apollo.Query<LocationTreeQuery, LocationTreeQueryVariables> {
    document = LocationTreeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateLocationDocument = gql`
    query aggregateLocation($where: LocationWhereInput, $orderBy: [LocationOrderByWithRelationInput!], $cursor: LocationWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateLocation(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateLocationGQL extends Apollo.Query<AggregateLocationQuery, AggregateLocationQueryVariables> {
    document = AggregateLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneLocationDocument = gql`
    mutation createOneLocation($data: LocationCreateInput!) {
  createOneLocation(data: $data) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneLocationGQL extends Apollo.Mutation<CreateOneLocationMutation, CreateOneLocationMutationVariables> {
    document = CreateOneLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneLocationDocument = gql`
    mutation updateOneLocation($data: LocationUpdateInput!, $where: LocationWhereUniqueInput!) {
  updateOneLocation(data: $data, where: $where) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneLocationGQL extends Apollo.Mutation<UpdateOneLocationMutation, UpdateOneLocationMutationVariables> {
    document = UpdateOneLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneLocationDocument = gql`
    mutation deleteOneLocation($where: LocationWhereUniqueInput!) {
  deleteOneLocation(where: $where) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneLocationGQL extends Apollo.Mutation<DeleteOneLocationMutation, DeleteOneLocationMutationVariables> {
    document = DeleteOneLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationsDocument = gql`
    query organizations($where: OrganizationWhereInput, $orderBy: [OrganizationOrderByWithRelationInput!], $cursor: OrganizationWhereUniqueInput, $take: Int, $skip: Int, $distinct: [OrganizationScalarFieldEnum!]) {
  organizations(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsGQL extends Apollo.Query<OrganizationsQuery, OrganizationsQueryVariables> {
    document = OrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationDocument = gql`
    query organization($where: OrganizationWhereUniqueInput!) {
  organization(where: $where) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationGQL extends Apollo.Query<OrganizationQuery, OrganizationQueryVariables> {
    document = OrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneOrganizationDocument = gql`
    mutation createOneOrganization($data: OrganizationCreateInput!) {
  createOneOrganization(data: $data) {
    id
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneOrganizationGQL extends Apollo.Mutation<CreateOneOrganizationMutation, CreateOneOrganizationMutationVariables> {
    document = CreateOneOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneOrganizationDocument = gql`
    mutation updateOneOrganization($data: OrganizationUpdateInput!, $where: OrganizationWhereUniqueInput!) {
  updateOneOrganization(data: $data, where: $where) {
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneOrganizationGQL extends Apollo.Mutation<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables> {
    document = UpdateOneOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneOrganizationDocument = gql`
    mutation deleteOneOrganization($where: OrganizationWhereUniqueInput!) {
  deleteOneOrganization(where: $where) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneOrganizationGQL extends Apollo.Mutation<DeleteOneOrganizationMutation, DeleteOneOrganizationMutationVariables> {
    document = DeleteOneOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OutputsDocument = gql`
    query outputs($where: OutputWhereInput, $orderBy: [OutputOrderByWithRelationInput!], $cursor: OutputWhereUniqueInput, $take: Int, $skip: Int, $distinct: [OutputScalarFieldEnum!]) {
  outputs(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OutputsGQL extends Apollo.Query<OutputsQuery, OutputsQueryVariables> {
    document = OutputsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OutputDocument = gql`
    query output($where: OutputWhereUniqueInput!) {
  output(where: $where) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OutputGQL extends Apollo.Query<OutputQuery, OutputQueryVariables> {
    document = OutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OutputTreeDocument = gql`
    query outputTree($where: OutputWhereInput, $orderBy: [OutputOrderByWithRelationInput!], $cursor: OutputWhereUniqueInput, $take: Int, $skip: Int, $distinct: [OutputScalarFieldEnum!]) {
  outputs(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    description
    projectId
    activities(orderBy: {code: asc}) {
      id
      code
      name
      description
      outputId
      projectId
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OutputTreeGQL extends Apollo.Query<OutputTreeQuery, OutputTreeQueryVariables> {
    document = OutputTreeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OutputTreeImplementationDocument = gql`
    query outputTreeImplementation($where: OutputWhereInput, $orderBy: [OutputOrderByWithRelationInput!], $cursor: OutputWhereUniqueInput, $take: Int, $skip: Int, $distinct: [OutputScalarFieldEnum!]) {
  outputs(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    description
    projectId
    activities(orderBy: {code: asc}) {
      id
      code
      name
      description
      outputId
      projectId
      createdAt
      updatedAt
      implementations(orderBy: {dateStart: asc}) {
        id
        dateStart
        dateEnd
        description
        createdAt
        updatedAt
        activityId
      }
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OutputTreeImplementationGQL extends Apollo.Query<OutputTreeImplementationQuery, OutputTreeImplementationQueryVariables> {
    document = OutputTreeImplementationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateOutputDocument = gql`
    query aggregateOutput($where: OutputWhereInput, $orderBy: [OutputOrderByWithRelationInput!], $cursor: OutputWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateOutput(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateOutputGQL extends Apollo.Query<AggregateOutputQuery, AggregateOutputQueryVariables> {
    document = AggregateOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneOutputDocument = gql`
    mutation createOneOutput($data: OutputCreateInput!) {
  createOneOutput(data: $data) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneOutputGQL extends Apollo.Mutation<CreateOneOutputMutation, CreateOneOutputMutationVariables> {
    document = CreateOneOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneOutputDocument = gql`
    mutation updateOneOutput($data: OutputUpdateInput!, $where: OutputWhereUniqueInput!) {
  updateOneOutput(data: $data, where: $where) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneOutputGQL extends Apollo.Mutation<UpdateOneOutputMutation, UpdateOneOutputMutationVariables> {
    document = UpdateOneOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneOutputDocument = gql`
    mutation deleteOneOutput($where: OutputWhereUniqueInput!) {
  deleteOneOutput(where: $where) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneOutputGQL extends Apollo.Mutation<DeleteOneOutputMutation, DeleteOneOutputMutationVariables> {
    document = DeleteOneOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectsDocument = gql`
    query projects($where: ProjectWhereInput, $orderBy: [ProjectOrderByWithRelationInput!], $cursor: ProjectWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ProjectScalarFieldEnum!]) {
  projects(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    code
    name
    description
    organization {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectsGQL extends Apollo.Query<ProjectsQuery, ProjectsQueryVariables> {
    document = ProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectDocument = gql`
    query project($where: ProjectWhereUniqueInput!) {
  project(where: $where) {
    id
    code
    name
    description
    organization {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectGQL extends Apollo.Query<ProjectQuery, ProjectQueryVariables> {
    document = ProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneProjectDocument = gql`
    mutation createOneProject($data: ProjectCreateInput!) {
  createOneProject(data: $data) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneProjectGQL extends Apollo.Mutation<CreateOneProjectMutation, CreateOneProjectMutationVariables> {
    document = CreateOneProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneProjectDocument = gql`
    mutation updateOneProject($data: ProjectUpdateInput!, $where: ProjectWhereUniqueInput!) {
  updateOneProject(data: $data, where: $where) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneProjectGQL extends Apollo.Mutation<UpdateOneProjectMutation, UpdateOneProjectMutationVariables> {
    document = UpdateOneProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneProjectDocument = gql`
    mutation deleteOneProject($where: ProjectWhereUniqueInput!) {
  deleteOneProject(where: $where) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneProjectGQL extends Apollo.Mutation<DeleteOneProjectMutation, DeleteOneProjectMutationVariables> {
    document = DeleteOneProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!], $cursor: UserWhereUniqueInput, $take: Int, $skip: Int, $distinct: [UserScalarFieldEnum!]) {
  users(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    username
    fullName
    email
    isAdmin
    organization {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDocument = gql`
    query user($where: UserWhereUniqueInput!) {
  user(where: $where) {
    id
    username
    fullName
    email
    isAdmin
    organization {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    document = UserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOneUserDocument = gql`
    mutation createOneUser($data: UserCreateInput!) {
  createOneUser(data: $data) {
    id
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOneUserGQL extends Apollo.Mutation<CreateOneUserMutation, CreateOneUserMutationVariables> {
    document = CreateOneUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOneUserDocument = gql`
    mutation updateOneUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateOneUser(data: $data, where: $where) {
    id
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneUserGQL extends Apollo.Mutation<UpdateOneUserMutation, UpdateOneUserMutationVariables> {
    document = UpdateOneUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOneUserDocument = gql`
    mutation deleteOneUser($where: UserWhereUniqueInput!) {
  deleteOneUser(where: $where) {
    id
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOneUserGQL extends Apollo.Mutation<DeleteOneUserMutation, DeleteOneUserMutationVariables> {
    document = DeleteOneUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }