import { Component } from '@angular/core';

@Component({
  selector: 'app-beneficiary-stats-location',
  templateUrl: './beneficiary-stats-location.component.html',
  styleUrls: ['./beneficiary-stats-location.component.css']
})
export class BeneficiaryStatsLocationComponent {

}
