<h1>Output</h1>
<ng-container *ngIf="(outputs$ | async) as outputs; else noOutputs">
  <button class="mb-3" mat-raised-button color="primary" (click)="addOutput()"><mat-icon>add</mat-icon>Add Output</button>
  <mat-card>
    <mat-card-content *ngIf="outputs.length > 0; else noOutputs">
      <ng-container *ngFor="let output of outputs">
        <div class="flex items-start p-3 bg-white hover:bg-slate-100">
          <mat-icon class="text-orange-500">folder</mat-icon>
          <div class="grow flex flex-col mx-3">
            <div>{{output.name}}</div>
            <div class="text-slate-500">{{output.description}}</div>
          </div>
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item action">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="addActivity(output)">
                <mat-icon>add</mat-icon>
                <span>Add Activity</span>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="editOutput(output)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteOutput(output.id)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="flex items-start ml-7 p-3 bg-white hover:bg-slate-100" *ngFor="let act of output.activities">
          <div class="grow flex flex-row mx-3">
            <div class="py-2 px-3 rounded bg-blue-100 mr-3 flex items-center font-bold text-blue-700">{{act.code}}</div>
            <div>
              <div class="">{{act.name}}</div>
              <div class="text-slate-500 mt-2">{{act.description}}</div>
            </div>
          </div>
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item action">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editActivity(act)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteActivity(act.id)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
    <ng-container *ngIf="displayForm">
      <div class="right-panel drop-shadow-lg flex flex-col justify-start vertical-divider mat-drawer-container" [@slideLeftRight]>
          <div class="flex flex-row justify-start items-center py-2 pr-3 border-b border-slate-200">
              <button mat-icon-button (click)="toggleDisplayForm()"><mat-icon class="text-slate-500">clear</mat-icon></button>
              <div class="m-0 p-0 font-bold text-lg text-slate-500">{{formTitle}}</div>
          </div>
          <div class="grow p-3">
            <ng-container *ngIf="(util.projectState$ | async) as projectState">
              <ng-container *ngIf="formType=='output'; else activityForm">
                <ng-container *ngIf="isNewRecord; else updateOutputForm">
                  <app-output-form [projectId]="projectState.project.id" (complete)="saved()"></app-output-form>
                </ng-container>
              </ng-container>
              <ng-template #updateOutputForm>
                <app-output-form [output]="output" (complete)="saved()"></app-output-form>
              </ng-template>
              <ng-template #activityForm>
                <ng-container *ngIf="isNewRecord; else updateActivityForm">
                  <app-activity-form [output]="this.output" [projectId]="projectState.project.id" (complete)="saved()"></app-activity-form>
                </ng-container>
              </ng-template>
              <ng-template #updateActivityForm>
                <app-activity-form [activity]="activity" (complete)="saved()"></app-activity-form>
              </ng-template>
            </ng-container>
          </div>
      </div>
  </ng-container>
</ng-container>
<ng-template #noOutputs>
<mat-card-content class="text-slate-500"><strong>Ooops...</strong><br/>No Outputs yet, in this project</mat-card-content>
</ng-template>
