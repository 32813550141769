import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { LoginGQL, LoginMutation, LoginOutput, Project, ProjectsGQL, SortOrder, User } from './graphql';
import { LocalStorageService } from './localstorage.service';
import jwtDecode from 'jwt-decode';

type IUser = {
  email: string;
  id: string;
}

type JWT = {
  iat:number;
  exp:number;
  sub:string;
  email:string;
  organizationId:string;
  projectIds:string[];
  isAdmin: boolean;
}

@Injectable({
  providedIn: 'root'
})


export class AuthService {

  public userLogged:IUser = <IUser>{}
  // private userLoggedIn = new BehaviorSubject<IUser>(<IUser>this.ls.get('auth')?.User || {})
  isLoggedInSubject = new BehaviorSubject<boolean>(this.isLoggedIn)

  constructor(
    private ls: LocalStorageService,
    private loginGQL: LoginGQL,
    private getProjects: ProjectsGQL
  ) { }

  get isLoggedIn(): boolean {
    // check is localStorage exists or local variable isset
    // const lsAuth = this.ls.get('auth')
    // return (lsAuth && lsAuth.token) || (this.userLogged && this.userLogged.token) ? true : false

    if(this.token){
      const now = Math.floor(new Date().getTime()/1000);
      if(this.token.exp > now) return true
      return false
    }
    return false
  }
  /**
   *
   * @returns {Observable<T>}
   */
  get isLoggedIn$() : Observable<boolean> {

    this.isLoggedInSubject.next(this.isLoggedIn);
    return this.isLoggedInSubject.asObservable();
  }
  // get userLogged$() : Observable<User> {
  //   return this.userLoggedIn.asObservable();
  // }
  login$(params: {email: string; password: string}){
    return this.loginGQL.mutate(params).pipe(
      map( ({data}) => data?.login),
      map(res=> {
        if(res?.success && res?.access_token){
          this.ls.set('access_token', res.access_token);
          return true;
        }
        return false;
      })
    )
  }

  get token(): JWT | null{
    const access_token = this.ls.get('access_token')
    if(access_token){
      //decode
      const token_value:JWT = jwtDecode(access_token)
      return token_value
    }
    return null
  }

  get userEmail(): string | null{
    if(this.isLoggedIn && this.token){
      return this.token.email
    }
    return null
  }
  get isAdmin(): boolean{
    if(this.isLoggedIn && this.token){
      return this.token.isAdmin
    }
    return false
  }
  get userOrganizationId(): string | null{
    if(this.isLoggedIn && this.token){
      return this.token.organizationId
    }
    return null
  }
  get userProjectIds(): string[] | null{
    if(this.isLoggedIn && this.token){
      return this.token.projectIds
    }
    return null
  }
  get hasProject(): boolean {
    if(this.isLoggedIn && this.token && this.token.projectIds && this.token.projectIds.length > 0){
      return true
    }
    return false
  }
  get userProjects$(): Observable<Project[]>{
    if(this.isAdmin){
      return this.getProjects.watch({
        orderBy:{createdAt:{sort:SortOrder.Desc}}
      })
      .valueChanges
      .pipe(
        map(p=><Project[]>p.data.projects)
      )
    }else{
      if(this.userProjectIds && this.userProjectIds?.length > 0){
        return this.getProjects.watch({
          where:{
            id:{
              in:this.userProjectIds
            }
          }
        })
        .valueChanges
        .pipe(
          map(p=><Project[]>p.data.projects)
        )
      }else{
        return of(<Project[]>[])
      }
    }
  }
  get defaultProjectState(): Observable<Project>{
    return this.userProjects$.pipe(
      map(projects=>{
        return projects.length > 0 ? <Project>projects[0] : <Project>{}
      })
    )
  }
}
