<h1>Activity Implementation</h1>
<ng-container *ngIf="(outputs$ | async) as outputs; else noOutputs">
  <mat-card class="relative">
    <mat-card-content *ngIf="outputs.length > 0; else noOutputs">
      <ng-container *ngFor="let output of outputs">
        <div class="flex items-start p-3 bg-white hover:bg-slate-100">
          <mat-icon class="text-orange-500">folder</mat-icon>
          <div class="grow flex flex-col mx-3">
            <div>{{output.name}}</div>
            <div class="text-slate-500">{{output.description}}</div>
          </div>
        </div>

        <ng-container *ngFor="let act of output.activities">
          <div class="flex items-start ml-12 p-2 bg-white hover:bg-slate-100">
            <div class="grow flex flex-row">
              <div class="py-2 px-3 rounded bg-blue-100 mr-3 flex items-center font-bold text-blue-700">{{act.code}}</div>
                <div>
                  <div class="font-bold">{{act.name}}</div>
                  <div class="text-slate-500 mt-2">{{act.description}}</div>
                </div>
            </div>
            <div>
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item action">
                <mat-icon class="text-blue-700">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addImplementation(act)">
                  <mat-icon>add</mat-icon>
                  <span>Add implementation</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <ng-container *ngFor="let imp of act.implementations">
            <span class="absolute w-[10px] h-[10px] rounded-full bg-blue-700 left-[92px]"></span>
            <div class="flex flex-col ml-20 px-3 pb-1 hover:bg-slate-100 border-l border-l-slate-300">
              <div class="flex items-center text-blue-700 uppercase text-xs"><a (click)="updateImplementation(imp, act)" title="Click to Edit" class="cursor-pointer border-b border-b-transparent hover:border-b-blue-700">{{activityDate(imp.dateStart, imp.dateEnd)}}</a></div>
              <div class="mb-3 flex items-center justify-between">
                <div>
                  <div class="py-2">{{imp.description}}</div>
                  <div class="grow flex items-center">
                    <a (click)="openAssets()" class="mr-3 flex w-auto items-center border border-transparent hover:border-b-blue-500 cursor-pointer">
                      <span class="mr-1"><app-activity-implementation-stats [implementation]="imp" type="assets" [refresh]="refreshStats"></app-activity-implementation-stats></span>
                      <span class="text-slate-700">assets</span>
                    </a>
                    <a (click)="openParticipants(imp)" class="flex w-auto items-center border border-transparent hover:border-b-blue-500 cursor-pointer">
                      <span class="mr-1"><app-activity-implementation-stats [implementation]="imp" type="participants" [refresh]="refreshStats"></app-activity-implementation-stats></span>
                      <span class="text-slate-700">participants</span>
                    </a>
                    <!-- <div (click)="openAssets()" class="mr-3 flex w-auto items-center border border-transparent hover:border-b-blue-500 cursor-pointer"><span class="mr-1" *ngIf="(assetsCount(imp.id) | async) as _assetCount">{{_assetCount || 0}}</span><span class="text-slate-700">assets</span></div>
                    <div (click)="openParticipants(imp)"class="flex w-auto items-center border border-transparent hover:border-b-blue-500 cursor-pointer"><span class="mr-1" *ngIf="(participantsCount(imp.id) | async) as _participantCount">{{_participantCount || 0}}</span><span class="text-slate-700">participants</span></div> -->
                  </div>
                </div>
                <div>
                  <button mat-icon-button [matMenuTriggerFor]="implementationMenu" aria-label="Implementation action">
                    <mat-icon class="text-slate-500">more_vert</mat-icon>
                  </button>
                  <mat-menu #implementationMenu="matMenu">
                    <button mat-menu-item (click)="updateImplementation(imp, act)">
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="deleteImplementation(imp.id)">
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
              </div>
          </ng-container>
        </ng-container>

      </ng-container>
    </mat-card-content>
  </mat-card>
    <ng-container *ngIf="displayForm">
      <div class="right-panel drop-shadow-lg flex flex-col justify-start vertical-divider mat-drawer-container" [@slideLeftRight]>
          <div class="flex flex-row justify-start items-center py-2 pr-3 border-b border-slate-200">
              <button mat-icon-button (click)="toggleDisplayForm()"><mat-icon class="text-slate-500">clear</mat-icon></button>
              <div class="m-0 p-0 font-bold text-lg text-slate-500">{{formTitle}}</div>
          </div>
          <div class="grow p-3">
            <ng-container *ngIf="formType=='implementation'">
              <ng-container *ngIf="isNewRecord; else updateImplementationForm">
                <app-activity-implementation-form [activity]="activity" (complete)="implementationSaved()"></app-activity-implementation-form>
              </ng-container>
              <ng-template #updateImplementationForm>
                <app-activity-implementation-form [implementation]="implementation" (complete)="implementationSaved()"></app-activity-implementation-form>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="formType=='assets'">
              <app-assets-form></app-assets-form>
            </ng-container>

            <ng-container *ngIf="formType=='participants'">
              <app-participants-form [implementation]="implementation" (change)="onChangeParticipants()"></app-participants-form>
            </ng-container>

            <!-- <ng-container *ngIf="(util.projectState$ | async) as projectState">
              <ng-container *ngIf="formType=='output'; else activityForm">
                <ng-container *ngIf="isNewRecord; else updateOutputForm">
                  <app-output-form [projectId]="projectState.project.id" (complete)="saved()"></app-output-form>
                </ng-container>
              </ng-container>
              <ng-template #updateOutputForm>
                <app-output-form [output]="output" (complete)="saved()"></app-output-form>
              </ng-template>
              <ng-template #activityForm>
                <ng-container *ngIf="isNewRecord; else updateActivityForm">
                  <app-activity-form [output]="this.output" [projectId]="projectState.project.id" (complete)="saved()"></app-activity-form>
                </ng-container>
              </ng-template>
              <ng-template #updateActivityForm>
                <app-activity-form [activity]="activity" (complete)="saved()"></app-activity-form>
              </ng-template>
            </ng-container> -->
          </div>
      </div>
  </ng-container>
</ng-container>
<ng-template #noOutputs>
<mat-card-content class="text-slate-500"><strong>Ooops...</strong><br/>No Outputs yet, in this project</mat-card-content>
</ng-template>
