import { AfterViewInit, Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, switchAll, switchMap } from 'rxjs/operators';
import { Group, GroupTreeGQL, GroupsGQL } from 'src/app/core/_services/graphql';
import { UtilService } from 'src/app/core/_services/util.service';

export type GroupStatsDataSets = {
  data: number[]
  label: string
}
export type GroupStats = {
  labels: string[],
  datasets: GroupStatsDataSets[]
}
@Component({
  selector: 'app-beneficiary-stats-group',
  templateUrl: './beneficiary-stats-group.component.html',
  styleUrls: ['./beneficiary-stats-group.component.css']
})
export class BeneficiaryStatsGroupComponent implements OnChanges, AfterViewInit{

  groups$:Observable<Group[]>
  statsForm: FormGroup
  stats$?: Observable<GroupStats>
  // public barChartData: ChartData<'bar'>{}

  constructor(
    private groupsGQL: GroupsGQL,
    private groupTreeGQL: GroupTreeGQL,
    private utilService: UtilService,
    private fb: FormBuilder
  ){
    this.groups$ = this.utilService.projectState$.pipe(
      map(p=>p.project.id),
      switchMap(pid=>{
        return this.groupsGQL.watch({where:{level:{equals:0}, projectId:{equals:pid}}}).valueChanges.pipe(
          map(res=>{
            return <Group[]>res.data.groups
          })
        )
      })
    )
    this.statsForm = this.buildForm()
    this.stats$ = this.statsForm.controls['group']
    .valueChanges
    .pipe(
      switchMap((group:Group)=>{
        const groupId = group.id
        return this.groupsGQL.watch({where:{
          parentId:{equals: groupId}
        }}).valueChanges.pipe(map(res=><Group[]>res.data.groups))
      }),
      map((groups:Group[])=>{
        const labels = groups.map(g=>g.name)
        const data = groups.map(g=>g._count?.beneficiaries)
        const result =  <GroupStats>{
          labels: labels,
          datasets: [
            {
              label: 'Beneficiaries Stats',
              data: data
            }
          ]
        }
        console.log(result)
        return result
      })
    )


  }
  ngOnChanges(changes: SimpleChanges): void {
      this.buildForm()
  }
  ngAfterViewInit(): void {

  }
  buildForm(){
    return this.fb.group({
      group:[]
    })
  }
  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 && o1.name === o2.name && o1.id === o2.id;
  }
}
