<div class="p-3" *ngIf="isFormReady && userForm">
  <form [formGroup]="userForm" (keyup.enter)="save()" (ngSubmit)="save()" class="w-100">
      <div class=row>
          <div class="flex flex-col">
              <mat-form-field class="block">
                <mat-label>Full Name</mat-label>
                <input matInput type="text" placeholder="Full Name" formControlName="fullName">
                <mat-error *ngIf="userForm.controls['fullName']?.hasError('required')">
                  Full Name must be filled.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="block">
                <mat-label>Username</mat-label>
                <input matInput type="text" placeholder="Username" formControlName="username">
                <mat-error *ngIf="userForm.controls['username']?.hasError('required')">
                    Username must be filled.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="block">
                <mat-label>Email</mat-label>
                <input matInput type="text" placeholder="Email" formControlName="email">
                <mat-error *ngIf="userForm.controls['email']?.hasError('required')">
                    Email must be filled.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="block">
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
                <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-hint *ngIf="!passwordInput?.value && isNewRecord">Please fill the password</mat-hint>
                <mat-hint *ngIf="!isNewRecord">Keep it empty if you dont want to update the password</mat-hint>
              </mat-form-field>

              <mat-label class="mt-3">Is Administrator?</mat-label>
              <mat-radio-group aria-label="Is Administrator?" formControlName="isAdmin">
                <mat-radio-button [value]=true [checked]="userForm.get('isAdmin')?.value == true">YES</mat-radio-button>
                <mat-radio-button [value]=false [checked]="userForm.get('isAdmin')?.value == false">NO</mat-radio-button>
              </mat-radio-group>
              <ng-container *ngIf="userForm.get('isAdmin')?.value == false">
                <mat-form-field class="w-full mt-3" appearance="fill">
                  <mat-label>Member of Organization</mat-label>
                  <input type="text"
                         placeholder="Pick one"
                         aria-label="Organization"
                         matInput
                         formControlName="organization"
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of listOrganizations | async" [value]="option">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </ng-container>
          </div>
      </div>
  </form>
</div>
<div class="p-3">
<button type="submit" mat-raised-button color="primary" (click)="save()">Save</button>
</div>

