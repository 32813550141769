import { Component } from '@angular/core';

@Component({
  selector: 'app-activity-attendee',
  templateUrl: './activity-attendee.component.html',
  styleUrls: ['./activity-attendee.component.css']
})
export class ActivityAttendeeComponent {

}
