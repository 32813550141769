<h2>Locations</h2>
<button mat-raised-button color="primary" class="mb-3" (click)="addRootNode()"><mat-icon>add</mat-icon> Add root node</button>

<mat-card>
<div class="treeview pt-3">
  <div role="group">
    <ng-container *ngIf="dataSource.data.length > 0; else noData";>
      <div class="flex items-center pl-5 pb-3 pt-1">
        <button (click)="expandAll()" mat-stroked-button color="secondary" class="mr-3">Expand All</button>
        <button (click)="collapseAll()" mat-stroked-button color="accent">Collapse All</button>
      </div>
      <mat-divider></mat-divider>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node class="tree-node" *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
          <div class="flex items-center hover:bg-slate-100">
            <mat-form-field appearance="fill">
              <mat-label>New location...</mat-label>
              <input matInput #itemValue placeholder="Ex. Jawa Timur" (keyup.enter)="saveNode(node, itemValue.value)">
            </mat-form-field>
            <button mat-raised-button (click)="saveNode(node, itemValue.value)" color="primary" class="mx-3">Save</button>
            <button mat-button (click)="cancelAdd(node)">Cancel</button>
          </div>
        </mat-tree-node>

        <mat-tree-node class="tree-node hover:bg-slate-300" *matTreeNodeDef="let node" matTreeNodePadding>
          <div class="flex items-center w-full hover:bg-slate-100">
            <div class="grow">
              <ng-container *ngIf="node.edit; else viewNode">
                <div class="flex items-center">
                  <mat-form-field appearance="fill">
                    <mat-label>Edit location...</mat-label>
                    <input matInput #itemEditValue placeholder="Ex. Jawa Timur" [value]="node.item.name" (keyup.enter)="saveNode(node, itemEditValue.value)">
                  </mat-form-field>
                  <button mat-raised-button (click)="saveNode(node, itemEditValue.value)" color="primary" class="mx-3">Save</button>
                  <button mat-button (click)="cancelEdit(node)">Cancel</button>
                </div>
              </ng-container>
              <ng-template #viewNode>
                <div class="flex items-center hover:bg-slate-100">
                  <mat-icon [className]="levelClass(node.level)">folder</mat-icon>
                  <div class="mx-2">{{node.item.name}}</div>
                  <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item" *ngIf="node.hasChild">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>
                  <div *ngIf="!node.hasChild" class="pad"></div>
                </div>
              </ng-template>
            </div>
            <div>
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item action">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNewItem(node)">
                  <mat-icon>add</mat-icon>
                  <span>Add child</span>
                </button>
                <button mat-menu-item (click)="edit(node)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="delete(node)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <!-- <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button> -->
        </mat-tree-node>
      </mat-tree>
    </ng-container>
    <ng-template #noData>
      <div class="p-5 text-slate-700"><strong>Ooops...</strong><br/>There is no locations yet in the project. Please add root node of location, first.</div>
    </ng-template>

  </div>
</div>
</mat-card>
