<div [formGroup]="autoForm">
  <mat-form-field class="w-full block">
    <span matPrefix class="mx-2"><mat-icon class="text-slate-300">search</mat-icon></span>
    <input matInput [placeholder]="placeholder" formControlName="input" [matAutocomplete]="autoInput">
    <mat-autocomplete #autoInput="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
      <mat-option *ngIf="loading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="(results$ | async) as results">
        <mat-option *ngFor="let item of results" [value]="item">
          <span class="border-b" [ngClass]="{'border-b-blue-300':item.sex=='MALE', 'border-b-pink-300':item.sex=='FEMALE', 'border-b-slate-300':item.sex=='UNDEFINED',}">{{ item?.fullName }}</span>
          <span class="text-slate-700 text-xs mx-2">{{ item?.age }}</span>
          <span class="text-slate-700 text-xs">{{ item?.location?.name }}</span>
        </mat-option>
        <ng-container *ngIf="results?.length == 1 && results[0]?.id == 'not-found'">
          <mat-option [value]="optionValueAdd"><mat-icon class="text-blue-700">add</mat-icon> Add beneficiary</mat-option>
        </ng-container>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</div>
