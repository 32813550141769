import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree , Router} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { UtilService } from './util.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private router: Router
  ){ }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    // if(this.authService.isLoggedIn){
    //   return true;
    // }else{
    //   this.utilService.sendMessage('Ooops... Maaf, Anda perlu masuk log terlebih dulu.')
    //   this.router.navigate(['/login']);
    // }
    // return false;
    return this.authService.isLoggedIn$.pipe(map(logged=>{
      // return logged
      if(logged) return true;
      else{
        this.utilService.sendMessage('Ooops... Sorry, you need to login first to access to this page.')
        this.router.navigate(['/login']);
        return false;
      }
    }))
  }
}
