<div *ngIf="autoForm" [formGroup]="autoForm">
  <mat-form-field class="w-full block" appearance="fill">
    <mat-label>Search Project</mat-label>
    <mat-chip-grid #chipGrid aria-label="Enter fruits">
      <mat-chip-row *ngIf="data && data.name"
      [editable]="selectable"
      [removable]="removable"
      (removed)="remove()"
                    [aria-description]="'press enter to edit ' + data.name">
        {{data.name}}
        <button matChipRemove [attr.aria-label]="'remove ' + data.name">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input placeholder="Type project..."
             [matChipInputFor]="chipGrid"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"/>
    </mat-chip-grid>
    <mat-hint>{{placeholder}} with project name</mat-hint>
      <mat-autocomplete #projectAutocomplete="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
          <ng-container *ngIf="loading | async">
            <mat-option *ngIf="loading" class="is-loading"><span class="text-muted">Loading...</span></mat-option>
          </ng-container>
          <ng-container *ngIf="(data$ | async) as data">
            <mat-option *ngFor="let item of data" [value]="item">
                <div class="flex justify-between items-center">
                    <span>{{ item?.name }}</span>
                    <span class="font-weight-bold">{{item?.code}}</span>
                </div>
            </mat-option>
            <mat-option *ngIf="data.length == 0 && autoForm.controls['input'].value !== ''">Tidak ditemukan</mat-option>
        </ng-container>
      </mat-autocomplete>
  </mat-form-field>

    <ng-container *ngIf="displayResult && data && data.name">
        <mat-card class="mt-3">
            <div class="flex justify-between items-center">
                <div>
                    <strong>{{ data.name }}</strong><br/>
                    <span class="text-muted">{{ data.organization?.name }}</span>
                </div>
                <div class="d-flex flex-column">
                    <div><span class="text-muted mr-2">CODE</span><span class="font-weight-bold">{{data.code}}</span></div>
                    <div><span class="text-muted mr-2">ORGANIZATION</span><span class="font-weight-bold">{{data.organization?.name}}</span></div>
                </div>
            </div>
        </mat-card>
    </ng-container>
</div>
