
<ng-container *ngIf="this.authService.hasProject || authService.isAdmin">
    <div class="bg-white p-3">
    <ul class="py-3">
      <li><a class="hover:text-blue-700 hover:underline" routerLink="/home">Dashboard</a></li>
    </ul>
    <ng-container *ngIf="authService.isAdmin">
      <div class="text-slate-500">ADMIN MENU</div>
      <ul class="pl-3 py-3">
        <li><a class="hover:text-blue-700 hover:underline" routerLink="/organization">Organizations</a></li>
        <li><a class="hover:text-blue-700 hover:underline" routerLink="/project">Projects</a></li>
        <li><a class="hover:text-blue-700 hover:underline" routerLink="/user">Users</a></li>
      </ul>
    </ng-container>
    <div class="text-slate-500">DATA DICTIONARY</div>
    <ul class="pl-3 py-3">
      <li><a class="hover:text-blue-700 hover:underline" routerLink="/output">Outputs & Activities</a></li>
      <li><a class="hover:text-blue-700 hover:underline" routerLink="/location">Project Area/Locations</a></li>
      <li><a class="hover:text-blue-700 hover:underline" routerLink="/group">Beneficiary Groups</a></li>
    </ul>
    <div class="text-slate-500 mt-2">IMPLEMENTATION</div>
    <ul class="pl-3 py-2">
      <li><a class="hover:text-blue-700 hover:underline" routerLink="/beneficiary">Beneficiaries/Participants</a></li>
      <li><a class="hover:text-blue-700 hover:underline" routerLink="/activity-implementation">Activity Implementation</a></li>
    </ul>
  </div>
</ng-container>

