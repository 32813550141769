import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, from } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CreateOneUserGQL, User, UpdateOneUserGQL, Organization, OrganizationsGQL, SortOrder, UserGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnChanges, OnDestroy{
  @Input() recordId:string = ''
  @Input() userId:string = ''
  // @Input() user:User = <User>{}
  @Input() isFullAccess:boolean = false
  @Output() complete = new EventEmitter()

  // user: User = <User>{}
  listOrganizations: Observable<Organization[]>

  user$?:Observable<User>
  userSub?:Subscription
  userForm?: FormGroup
  isNewRecord: boolean = false
  isFormReady:boolean = false
  hide = true

  constructor(
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private createUser: CreateOneUserGQL,
    private updateUser: UpdateOneUserGQL,
    private organizationGQL: OrganizationsGQL,
    private getUser: UserGQL,
    private loader: LoaderService
  ){
    // this.isNewRecord = this.user?.id ? false : true
    this.isNewRecord = this.userId ? false : true
    if(this.isNewRecord){
      this.userForm = this.buildForm({})
      this.isFormReady = true
    }else{
      this.user$ = this.getUser.watch({where:{id:this.userId}},{fetchPolicy:"network-only"})
      .valueChanges
      .pipe(
        map(res=><User>res.data.user)
      )
      this.userSub = this.user$.subscribe(user=>{
        // console.log('edit user:', user)
        this.userForm = this.buildForm(user);
        this.isFormReady = true
      })
    }

    this.listOrganizations = this.organizationGQL.watch({orderBy:{name:SortOrder.Asc}}).valueChanges.pipe(
      map(res=> <Organization[]>res.data.organizations)
    )

  }
  ngOnChanges(changes: SimpleChanges): void {
      this.isNewRecord = this.userId ? false : true
      if(this.isNewRecord){
        this.userForm = this.buildForm({})
        this.isFormReady = true
      }else{
        this.user$ = this.getUser.watch({where:{id:this.userId}},{fetchPolicy:"network-only"})
        .valueChanges
        .pipe(
          map(res=><User>res.data.user)
        )
        this.userSub = this.user$.subscribe(user=>{
          // console.log('change user:', user)
          this.userForm = this.buildForm(user);
          this.isFormReady = true
        })
      }
  }
  ngOnDestroy(): void {
      this.userSub?.unsubscribe()
  }
  buildForm(user:any){
    if(this.isNewRecord){
      return this.fb.group({
        username: ['', Validators.required],
        fullName: ['', Validators.required],
        email: ['', Validators.email],
        password: ['', Validators.required ],
        isAdmin: [false, Validators.required],
        organization: [''],
      })
    }else{
      return this.fb.group({
        username: [user?.username, Validators.required],
        fullName: [user?.fullName, Validators.required],
        email: [user?.email, Validators.email],
        password: [''],
        isAdmin: [user?.isAdmin, Validators.required],
        organization: [user?.organization],
      })
    }
  }
  save(){
    // if(this.userForm?.valid){
      const formValue = <User>this.userForm?.value
      if(this.isNewRecord && this.userForm?.valid){
        let _data = {
          username: formValue.username,
          fullName: formValue.fullName,
          email: formValue.email,
          password: formValue.password,
          isAdmin: formValue.isAdmin,
        }
        if(formValue.isAdmin == false && formValue.organization?.id){
          _data = {..._data, ...{
            organization:{
              connect:{
                id: formValue.organization?.id
              }
            }
          }}
        }
        // console.log(this.userForm.value)
        this.createUser.mutate({data:_data})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.createOneUser.id)
        ).subscribe(id=>{
          if(id){
            this.complete.emit(true)
          }
        })
      }else{
        if(formValue.username && formValue.fullName && formValue.email){
          let updateEntry = {
            username:{set:formValue.username},
            fullName:{set:formValue.fullName},
            email: {set:formValue.email},
            isAdmin: {set:formValue.isAdmin}
          }

          if(formValue.password !== '') updateEntry = {...updateEntry, ...{password: {set:formValue.password}}}

          if(formValue.isAdmin == false && formValue.organization?.id) updateEntry = {...updateEntry, ...{organization:{
            connect:{
              id: formValue.organization?.id
            }
          }}}

          this.updateUser.mutate({data: updateEntry, where:{id:this.userId}})
          .pipe(
            take(1),
            this.loader.indicate(),
            map(res=>res.data?.updateOneUser?.id)
          ).subscribe(id=>{
            if(id){
              this.complete.emit(true)
            }
          })
        }else{
          // console.log(formValue)
          alert('Please check your form entries')
        }
      }
  }
  displayFn(org: Organization): string {
    return org && org.name ? org.name : '';
  }
  get passwordInput() { return this.userForm?.get('password'); }
}
