import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LoaderService, LoaderState } from '../_services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnDestroy {

  loader$?: Observable<LoaderState>;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private loaderService: LoaderService) {
    this.loader$ = this.loaderService.loaderState.pipe(
      map((state:LoaderState) => state),
      takeUntil(this.destroy$)
    )
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
