import { Injectable } from '@angular/core';
import { Subject, Observable, defer } from 'rxjs';
import { finalize} from 'rxjs/operators';

export type LoaderState  = {
  show: boolean
}

export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source;
  });
}

export function indicate<T>(indicator: Subject<boolean>): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => indicator.next(true)),
    finalize(() => indicator.next(false))
  )
}

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();

  loaderState = this.loaderSubject.asObservable();

  constructor() { }

  show() {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hide() {
    setTimeout(() => {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }, 500);
  }

  prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => defer(() => {
      callback();
      return source;
    });
  }

  indicate<T>(): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => source.pipe(
      this.prepare(() => this.loaderSubject.next(<LoaderState>{ show: true })),
      finalize(() => {
        setTimeout(() => {
          this.loaderSubject.next(<LoaderState>{ show: false });
      }, 300)
      })
    )
  }

}
