<h1>Beneficiary</h1>
<ng-container *ngIf="(beneficiaries$ | async) as org">
  <button class="mb-3" mat-raised-button color="primary" (click)="add()"><mat-icon>add</mat-icon>Add Beneficiary</button>
  <table mat-table [dataSource]="org" matSort>
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)" class="cursor-pointer"> {{element?.fullName}} </td>
    </ng-container>
    <ng-container matColumnDef="sex">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sex </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{element?.sex}} </td>
    </ng-container>
    <ng-container matColumnDef="age">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Age </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{element?.age}} </td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{element?.phone}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{element?.email}} </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Address or Location </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{element?.location?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)">
        <ng-container *ngFor="let g of element?.groups">
          <span class="mr-3 bg-slate-100 p-2 rounded-lg">{{g?.group?.name}}</span>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{util.timeAgo(element?.createdAt)}} </td>
    </ng-container>
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{util.timeAgo(element?.updatedAt)}} </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
      <td mat-cell *matCellDef="let element"> <button mat-icon-button class="text-slate-200 hover:text-red-500" (click)="delete(element?.id)"><mat-icon>delete</mat-icon></button> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="row-data" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
    <ng-container *ngIf="displayForm">
      <div class="right-panel drop-shadow-lg flex flex-col justify-start vertical-divider mat-drawer-container" [@slideLeftRight]>
          <div class="flex flex-row justify-start items-center py-2 pr-3 border-b border-slate-200">
              <button mat-icon-button (click)="toggleDisplayForm()"><mat-icon class="text-slate-500">clear</mat-icon></button>
              <div class="m-0 p-0 font-bold text-lg text-slate-500">{{formTitle}}</div>
          </div>
          <div class="grow p-3">
            <!-- <app-beneficiary-form [beneficiary]="beneficiary" (complete)="saved()"></app-beneficiary-form> -->
            <ng-container *ngIf="isNewRecord; else updateForm">
                <app-beneficiary-form (complete)="saved()"></app-beneficiary-form>
            </ng-container>
            <ng-template #updateForm>
                <app-beneficiary-form [beneficiary]="beneficiary" (complete)="saved()"></app-beneficiary-form>
            </ng-template>
          </div>
      </div>
  </ng-container>

</ng-container>
