<div class="p-3" *ngIf="isFormReady && beneficiaryForm">
  <form [formGroup]="beneficiaryForm" (keyup.enter)="save()" (ngSubmit)="save()" class="w-100">
      <div class=row>
          <div class="flex flex-col">
              <mat-form-field class="block">
                <mat-label>Full Name</mat-label>
                <input matInput type="text" placeholder="Full Name" formControlName="fullName">
                <mat-error *ngIf="beneficiaryForm.controls['fullName']?.hasError('required')">
                  Full Name must be filled.
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Sex</mat-label>
                <mat-select formControlName="sex">
                  <mat-option *ngFor="let s of sexList" [value]="s">
                    {{s}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="block">
                <mat-label>Age</mat-label>
                <input matInput type="number" placeholder="Age" formControlName="age">
                <span matSuffix class="px-3 text-slate-700">years old</span>
                <mat-error *ngIf="beneficiaryForm.controls['age']?.hasError('required')">
                 Age must be filled by numbers.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="block">
                <mat-label>Phone Number</mat-label>
                <input matInput type="text" placeholder="Phone Number" formControlName="phone">
              </mat-form-field>
              <mat-form-field class="block">
                <mat-label>Email</mat-label>
                <input matInput type="text" placeholder="Email" formControlName="email">
              </mat-form-field>
              <!-- Begin Locations -->
              <ng-container *ngIf="(locations$ | async) as locations">
                <mat-form-field>
                  <mat-label>Address or Location</mat-label>
                  <mat-select formControlName="location" disableOptionCentering [compareWith]="compareObjects">
                    <ng-container
                      *ngTemplateOutlet="
                        recursiveOptions;
                        context: { $implicit: locations, depth: 1 }
                      "
                    >
                    </ng-container>

                    <ng-template #recursiveOptions let-list let-depth="depth">
                      <ng-container *ngFor="let location of list">
                        <mat-option
                          [value]="location"
                          [ngStyle]="{
                            'padding-left.px': depth <= 1 ? null : 16 * depth
                          }"
                        >
                          {{ location.name }}
                        </mat-option>

                        <ng-container
                          *ngIf="!!location.children && location.children.length > 0"
                        >
                          <ng-container
                            *ngTemplateOutlet="
                              recursiveOptions;
                              context: { $implicit: location.children, depth: depth + 1 }
                            "
                          >
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <!-- End Locations -->
              <!-- Begin Groups -->
              <ng-container *ngIf="(groups$ | async) as groups">
                <mat-form-field>
                  <mat-label>Groups</mat-label>
                  <mat-select formControlName="groups" disableOptionCentering [compareWith]="compareObjects" multiple>
                    <ng-container
                      *ngTemplateOutlet="
                        recursiveOptions;
                        context: { $implicit: groups, depth: 1 }
                      "
                    >
                    </ng-container>

                    <ng-template #recursiveOptions let-list let-depth="depth">
                      <ng-container *ngFor="let group of list">
                        <mat-option
                          [value]="group"
                          [ngStyle]="{
                            'padding-left.px': depth <= 1 ? null : 16 * depth
                          }"
                        >
                          {{ group.name }}
                        </mat-option>

                        <ng-container
                          *ngIf="!!group.children && group.children.length > 0"
                        >
                          <ng-container
                            *ngTemplateOutlet="
                              recursiveOptions;
                              context: { $implicit: group.children, depth: depth + 1 }
                            "
                          >
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <!-- End Groups -->
          </div>
      </div>
  </form>
</div>
<div class="p-3">
<button type="submit" mat-raised-button color="primary" (click)="save()">Save</button>
</div>

