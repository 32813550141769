import { Component, HostListener } from '@angular/core';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { SortOrder, User, UsersGQL, UserGQL, UsersQueryVariables, UsersQuery, DeleteOneUserGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { QueryRef } from 'apollo-angular';
import { UtilService } from 'src/app/core/_services/util.service';
import { DialogService } from 'src/app/core/_services/dialog.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('500ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('50ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ]),
    trigger('slideLeftRight', [
      transition(':enter', [style({ left: '100%' }), animate(200)]),
      transition(':leave', [animate(200, style({ left: '100%' }))]),
    ]),
  ]
})
export class UserComponent {
  users$:Observable<User[]>
  queryVariables:UsersQueryVariables = {
    orderBy:{createdAt:{sort:SortOrder.Desc}},
    take:10
  }
  displayedColumns: string[] = [
    'fullName',
    'email',
    'organizationId',
    'isAdmin',
    'createdAt',
    'updatedAt',
    'action'
  ]
  displayForm = false
  formTitle = 'Add User'
  isNewRecord = false
  userId = ''
  user: User = <User>{}
  userQuery: QueryRef<UsersQuery>
  private readonly refresh$ = new Subject<void>();
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.displayForm) this.displayForm = false
  }
  constructor(
    private usersGQL: UsersGQL,
    private loader: LoaderService,
    private deleteUser: DeleteOneUserGQL,
    public util: UtilService,
    private dialogService: DialogService
    ){

    this.userQuery = usersGQL.watch(this.queryVariables, {fetchPolicy:'network-only'})
    this.users$ = this.refresh$
    .pipe(
      startWith(undefined),
      switchMap(ref=>{
        return this.usersGQL.watch(this.queryVariables, {fetchPolicy:'network-only'}).valueChanges.pipe(
          take(1),
          this.loader.indicate(),
          map(res=><User[]>res.data.users)
        )
      })
    )

    // this.users$ = this.userQuery.valueChanges.pipe(
    //   take(1),
    //   this.loader.indicate(),
    //   map(res=><User[]>res.data.users)
    // )
  }
  selectRow(row?:User){
    this.update(row)
  }
  toggleDisplayForm(){
    this.displayForm = this.displayForm ? false : true
  }
  add(){
    if(this.isNewRecord) this.toggleDisplayForm()
    else{
      this.displayForm = true
      this.isNewRecord = true
      this.formTitle = 'Add User'
    }
  }
  update(row?:User){
    if(row){
      if(row.id && row.id == this.user.id && !this.isNewRecord) this.toggleDisplayForm()
      else{
        this.displayForm = true
        this.formTitle = 'Update User'
        this.isNewRecord = false
        this.user = row
        this.userId = row.id
      }
    }
  }
  delete(id:string){
    this.dialogService.confirm({
      title:'Delete Confirmation',
      message:'Are you sure you want to delete this user?'
    }).subscribe(yes=>{
      if(yes){
        this.deleteUser.mutate({where:{id:id}}).subscribe(({data})=>{
          if(data?.deleteOneUser?.id){
            this.refresh$.next()
          }
        })
      }
    })
  }
  saved(){
    this.toggleDisplayForm()
    this.refresh$.next()
  }
}
