import { Injectable } from '@angular/core';
import { isObject } from 'underscore';

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {
    set(key: string, value: string) {
        localStorage.setItem(key, value)
    }

    get(key: string) {
        const item:string = localStorage.getItem(key) || ''
        return item ? item : null;
        // return localStorage.getItem(key)
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
    clear(){
      localStorage.clear()
    }
}

@Injectable({
  providedIn: 'root'
})
export class LocalQueryService {
  set(key: string, value: string | object) {
      let _value:string = isObject(value) ? <string>JSON.stringify(value) : <string>value
      localStorage.setItem(key, _value)
  }

  get(key: string) {
      let _value:string = <string>localStorage.getItem(key)
      return _value && _value.indexOf('{') !== -1 && _value.indexOf('}') !== -1 ? JSON.parse(_value) : _value
  }

  remove(key: string) {
      localStorage.removeItem(key);
  }
  clear(){
    localStorage.clear()
  }
}
