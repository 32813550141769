<div class="chart">
  <canvas baseChart
    type="pie"
    [data]="chartData"
    [labels]="labels"
    [options]="options"
    [legend]="legend"
    [plugins]="plugins"
    >
  </canvas>
</div>
