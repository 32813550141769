import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { CreateOneOrganizationGQL, Organization, UpdateOneOrganizationGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';

@Component({
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.css']
})
export class OrganizationFormComponent implements OnChanges{
  @Input() recordId:string = ''
  @Input() organizationId:string = ''
  @Input() organization:Organization = <Organization>{}
  @Input() isFullAccess:boolean = false
  @Output() complete = new EventEmitter()

  // organization: Organization = <Organization>{}
  organizationForm?: FormGroup
  isNewRecord: boolean
  isFormReady:boolean = false

  constructor(
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private createOrganization: CreateOneOrganizationGQL,
    private updateOrganization: UpdateOneOrganizationGQL,
    private loader: LoaderService
  ){
    this.isNewRecord = this.organization?.id ? false : true
    this.organizationForm = this.buildForm()
    this.isFormReady = true
  }
  ngOnChanges(changes: SimpleChanges): void {
      // this.organization = changes.organization?.currentValue
      // console.log(this.organization)
      this.isNewRecord = this.organization?.id ? false : true
      this.organizationForm = this.buildForm()
      this.isFormReady = true
  }
  buildForm(){
    return this.fb.group({
      name: [!this.isNewRecord ? this.organization?.name : '', Validators.required],
      description: [!this.isNewRecord ? this.organization?.description : '', Validators.required],
    })
  }
  save(){
    if(this.organizationForm?.valid){
      if(this.isNewRecord){
        this.createOrganization.mutate({data:this.organizationForm.value})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.createOneOrganization.id)
        ).subscribe(id=>{
          if(id){
            this.complete.emit(true)
          }
        })
      }else{
        this.updateOrganization.mutate({data:{
          name:{set:this.organizationForm.get('name')?.value},
          description:{set:this.organizationForm.get('description')?.value},
        }, where:{id:this.organization.id}})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.updateOneOrganization?.name)
        ).subscribe(name=>{
          if(name){
            this.complete.emit(true)
          }
        })
      }

    }
  }
}
