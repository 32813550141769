<h1>Project</h1>
<ng-container *ngIf="(projects$ | async) as proj">
  <button class="mb-3" mat-raised-button color="primary" (click)="add()"><mat-icon>add</mat-icon>Add Project</button>
  <table mat-table [dataSource]="proj" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)" class="cursor-pointer"> {{element?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)" class="cursor-pointer"> {{element?.code}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{element?.description}} </td>
    </ng-container>
    <ng-container matColumnDef="organization">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{element?.organization?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{util.timeAgo(element?.createdAt)}} </td>
    </ng-container>
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
      <td mat-cell *matCellDef="let element" (click)="selectRow(element)"> {{util.timeAgo(element?.updatedAt)}} </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
      <td mat-cell *matCellDef="let element"> <button mat-icon-button class="text-slate-200 hover:text-red-500" (click)="delete(element?.id)"><mat-icon>delete</mat-icon></button> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="row-data" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
    <ng-container *ngIf="displayForm">
      <div class="right-panel drop-shadow-lg flex flex-col justify-start vertical-divider mat-drawer-container" [@slideLeftRight]>
          <div class="flex flex-row justify-start items-center py-2 px-3 border-b border-slate-200">
              <button mat-icon-button (click)="toggleDisplayForm()"><mat-icon class="text-slate-500">clear</mat-icon></button>
              <div class="m-0 p-0 font-bold text-lg text-slate-500">{{formTitle}}</div>
          </div>
          <div class="grow p-3">
            <!-- <app-project-form [project]="project" (complete)="saved()"></app-project-form> -->
            <ng-container *ngIf="isNewRecord; else updateForm">
                <app-project-form (complete)="saved()"></app-project-form>
            </ng-container>
            <ng-template #updateForm>
                <app-project-form [project]="project" (complete)="saved()"></app-project-form>
            </ng-template>
          </div>
      </div>
  </ng-container>

</ng-container>
