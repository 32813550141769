import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { CreateOneActivityGQL, Activity, UpdateOneActivityGQL, Output as IOutput } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';

@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.css']
})
export class ActivityFormComponent implements OnChanges{
  @Input() recordId:string = ''
  @Input() activityId:string = ''
  @Input() projectId:string = ''
  @Input() output:IOutput = <IOutput>{}
  @Input() activity:Activity = <Activity>{}
  @Input() isFullAccess:boolean = false
  @Output() complete = new EventEmitter()

  // activity: Activity = <Activity>{}
  activityForm?: FormGroup
  isNewRecord: boolean
  isFormReady:boolean = false

  constructor(
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private createActivity: CreateOneActivityGQL,
    private updateActivity: UpdateOneActivityGQL,
    private loader: LoaderService
  ){
    this.isNewRecord = this.activity?.id ? false : true
    this.activityForm = this.buildForm()
    this.isFormReady = true
  }
  ngOnChanges(changes: SimpleChanges): void {
      // this.activity = changes.activity?.currentValue
      // console.log(this.activity)
      this.isNewRecord = this.activity?.id ? false : true
      this.activityForm = this.buildForm()
      this.isFormReady = true
  }
  buildForm(){
    return this.fb.group({
      code: [!this.isNewRecord ? this.activity?.code : '', Validators.required],
      name: [!this.isNewRecord ? this.activity?.name : '', Validators.required],
      description: [!this.isNewRecord ? this.activity?.description : '', Validators.required],
    })
  }
  save(){
    if(this.activityForm?.valid){
      if(this.isNewRecord){
        this.createActivity.mutate({data:{...this.activityForm.value, ...{project:{
          connect:{id: this.projectId}
        }, output:{
          connect:{id: this.output.id}
        }}}})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.createOneActivity.id)
        ).subscribe(id=>{
          if(id){
            this.complete.emit(true)
          }
        })
      }else{
        this.updateActivity.mutate({data:{
          code:{set:this.activityForm.get('code')?.value},
          name:{set:this.activityForm.get('name')?.value},
          description:{set:this.activityForm.get('description')?.value},
        }, where:{id:this.activity.id}})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.updateOneActivity?.name)
        ).subscribe(name=>{
          if(name){
            this.complete.emit(true)
          }
        })
      }

    }
  }
}
