<div class="w-100 w-md-50 mx-auto pt-3 mt-5">
  <div class="w-100 m-auto">
    <ng-container *ngIf="(auth.isLoggedIn$ | async) == true; else logForm">
        <h2>You have signed in</h2>
    </ng-container>
    <ng-template #logForm>
        <form [formGroup]="loginForm" (keyup.enter)="login()" (submit)="login()" class="w-ful">
            <mat-card class="mat-elevation-z8">
                <mat-card-title><h2 class="pt-3 px-3 text-lg text-slate-500">Sign in</h2></mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content class="block p-5">
                        <mat-form-field class="block mt-3 w-full md:w-80">
                            <input matInput type="text" class="" placeholder="Email" formControlName="email">
                        </mat-form-field><br/>
                        <mat-form-field class="block mt-3 w-full md:w-80">
                            <input matInput type="password" class="w-60" placeholder="Password" formControlName="password">
                        </mat-form-field>
                </mat-card-content>
                <mat-card-actions class="flex justify-content-between align-items-center p-3">
                    <button type="submit" mat-raised-button color="primary" (click)="login()">Sign in</button>
                </mat-card-actions>
            </mat-card>
        </form>
    </ng-template>
</div>
</div>
