import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectState, UtilService } from 'src/app/core/_services/util.service';
import { AuthService } from 'src/app/core/_services/auth.service';
import { Project } from 'src/app/core/_services/graphql';
import { LocalQueryService } from 'src/app/core/_services/localstorage.service';
import { combineLatest, Observable, of, from, merge, BehaviorSubject, Subscription } from 'rxjs';
import { map, switchMap, take, last } from 'rxjs/operators';

@Component({
  selector: 'app-project-state',
  templateUrl: './project-state.component.html',
  styleUrls: ['./project-state.component.css']
})
export class ProjectStateComponent implements OnInit, OnDestroy {

  project$?: Observable<Project>
  projectList$: Observable<Project[]>
  project:Project = <Project>{}
  projectChooserVisible = false

  projectSub = new BehaviorSubject(<Project>{})
  projectEvent$ = this.projectSub.asObservable()
  sub?: Subscription
  sub2?: Subscription

  projectChached?:Project

  constructor(private utilService: UtilService, private lq: LocalQueryService, public authService: AuthService) {
    let cached = <Project>this.lq.get('PROJECTSTATE_CACHED_QUERY')
    if(cached){
      this.projectChached = <Project>cached
      // console.log('cached:', this.projectChached)
      this.utilService.setProjectState(<Project>cached)
      this.projectSub.next(cached)
    }

    // const defaultProject$:Observable<Project> = merge(
    //     this.authService.defaultProjectState.pipe(
    //       take(1),
    //       map(project=>project)
    //     ),
    //     of(this.utilService.projectStateProject),
    //     of(this.projectChached)
    //   )
    //   .pipe(map(project=>{
    //     // console.log(project)
    //     this.utilService.setProjectState(<Project>project)
    //     this.projectSub.next(<Project>project)
    //     return <Project>project
    //   }))

    const defaultProject$:Observable<Project> = combineLatest([
      this.authService.defaultProjectState.pipe(
        take(1),
        map(project=>project)
      ),
      of(this.utilService.projectStateProject),
      of(this.projectChached)
      ]
    )
    .pipe(map(([_default, _state, _cached])=>{
      // console.log('_default:',_default)
      // console.log('_state:',_state)
      // console.log('_cached:',_cached)
      const project:Project = <Project>{..._default, ..._cached, ..._state}
      // console.log(project)
      this.utilService.setProjectState(<Project>project)
      this.projectSub.next(<Project>project)
      // console.log('set PROJECTSTATE_CACHED_QUERY')
      this.lq.set('PROJECTSTATE_CACHED_QUERY', project)
      return <Project>project
    }))

    this.project$ = combineLatest([defaultProject$, this.projectEvent$]).pipe(
      map(([_default, _event])=>{
        const project = <Project>{..._default, ..._event}
        return project
      })
    )

    this.projectList$ = this.authService.userProjects$
  }



  ngOnInit(): void {

  }
  toggleChooser(){
    this.projectChooserVisible = this.projectChooserVisible == true ? false : true
  }
  setProject($event: Project, processChooser:boolean = true){
    if($event.hasOwnProperty('id')){
      this.utilService.setProjectState({
        isActive: true,
        project: $event
      })
      this.projectSub.next($event)
      if (processChooser) this.toggleChooser()
      this.projectChached = $event
      this.lq.set('PROJECTSTATE_CACHED_QUERY', $event)
    }
  }
  ngAfterViewChecked(){
    // console.log(this.project)
    // if(this.project) this.utilService.setProjectState(Object.assign({isActive: true, project: this.project}))
  }
  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe()
    // this.lq.set('PROJECTSTATE_CACHED_QUERY', this.utilService.projectStateProject)
  }

}
