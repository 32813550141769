import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { NoProjectComponent } from './no-project/no-project.component';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    NoProjectComponent
  ],
  imports: [
    CommonModule,
    CoreModule
  ],
  exports:[
    AuthComponent,
    LoginComponent,
  ],
  bootstrap: [AuthComponent]
})
export class AuthModule { }
