<ng-container *ngIf="(project$ | async) as p">
  <mat-card>
    <h2 class="p-3 m-0">Project Detail</h2>
    <div class="px-3 pb-3 flex flex-row">
      <div>
        <div class="text-slate-700">Project Code</div>
        <div>{{p?.project?.code}}</div>
        <div class="text-slate-700 mt-3">Project Name</div>
        <div>{{p?.project?.name}}</div>
        <div class="text-slate-700 mt-3">Project Description</div>
        <div>{{p?.project?.description}}</div>
        <div class="text-slate-700 mt-3">Handled by</div>
        <div>{{p?.project?.organization?.name}}</div>
      </div>
      <div class="ml-20">
        <div class="mb-3">Summary Stats</div>
        <div><a class="px-2 hover:text-blue-700 hover:underline" routerLink="/output">{{p?.stats?.outputs}}</a> <span class="text-slate-700 ml-3">Expected Outputs</span></div>
        <div><a class="px-2 hover:text-blue-700 hover:underline" routerLink="/output">{{p?.stats?.activities}}</a> <span class="text-slate-700 ml-3">Planned Activities</span></div>
        <div><a class="px-2 hover:text-blue-700 hover:underline" routerLink="/location">{{p?.stats?.locations}}</a> <span class="text-slate-700 ml-3">Locations</span></div>
        <div><a class="px-2 hover:text-blue-700 hover:underline" routerLink="/group">{{p?.stats?.groups}}</a> <span class="text-slate-700 ml-3">Group Classifications</span></div>
        <div><a class="px-2 hover:text-blue-700 hover:underline" routerLink="/beneficiary">{{p?.stats?.beneficiaries}}</a> <span class="text-slate-700 ml-3">Beneficiaries</span></div>
      </div>
    </div>
  </mat-card>
</ng-container>
