<div class="p-3" *ngIf="isFormReady && activityForm">
  <form [formGroup]="activityForm" (keyup.enter)="save()" (ngSubmit)="save()" class="w-100">
      <div class=row>
          <div class="flex flex-col">
            <mat-form-field class="block">
              <input matInput type="text" placeholder="Activity Code" formControlName="code">
              <mat-error *ngIf="activityForm.controls['code']?.hasError('required')">
                Code must be filled.
              </mat-error>
            </mat-form-field>
              <mat-form-field class="block">
                  <input matInput type="text" placeholder="Activity Name" formControlName="name">
                  <mat-error *ngIf="activityForm.controls['name']?.hasError('required')">
                    Name must be filled.
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="block">
                  <input matInput type="text" placeholder="Description" formControlName="description">
                  <mat-error *ngIf="activityForm.controls['description']?.hasError('required')">
                      Description must be filled.
                  </mat-error>
              </mat-form-field>
          </div>
      </div>
  </form>
</div>
<div class="p-3">
<button type="submit" mat-raised-button color="primary" (click)="save()">Save</button>
</div>

