import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../core/_services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/core/_services/util.service';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup
  loginSub?: Subscription
  constructor(private fb: FormBuilder, public auth: AuthService, private router: Router, private utilService: UtilService, private loader: LoaderService) {
    this.loginForm = fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    })
  }

  ngOnInit(){
    if(this.auth.isLoggedIn == true){
      this.utilService.sendMessage('Your session is still active, no need to relogin')
      this.router.navigate(['home'])
    }
  }

  login(){
    if(this.loginForm.valid){
      const params = {
        email: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value
      }
      this.loginSub = this.auth.login$(params)
      .pipe(
        take(1),
        this.loader.indicate())
      .subscribe((success)=>{
        if(success){
          if(this.auth.isAdmin) this.router.navigate(['/home'])
          else{
            if(this.auth.userProjectIds && this.auth.userProjectIds.length > 0){
              this.router.navigate(['/home'])
            }else{
              this.router.navigate(['/no-project'])
            }
          }
        }else{
          this.utilService.sendMessage('Email or password invalid. Please fill the form with your credential account correctly')
        }

      })
    }
  }
  ngOnDestroy(): void {
    if(this.loginSub) this.loginSub.unsubscribe()
  }

}
