import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/_services/auth.service';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css']
})
export class DashboardMenuComponent {
  constructor(public authService: AuthService){

  }
}
