import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ColorGenerator {
  calculatePoint(i:number, intervalSize:number, colorRangeInfo:any) {
    let { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
    return (useEndAsStart
      ? (colorEnd - (i * intervalSize))
      : (colorStart + (i * intervalSize)));
  }

  /* Must use an interpolated color scale, which has a range of [0, 1] */
  interpolateColors(dataLength:number, colorScale:any, colorRangeInfo:any) {
    let { colorStart, colorEnd } = colorRangeInfo;
    let colorRange = colorEnd - colorStart;
    let intervalSize = colorRange / dataLength;
    let i, colorPoint;
    let colorArray = [];
    console.log(typeof colorScale)

    for (i = 0; i < dataLength; i++) {
      colorPoint = this.calculatePoint(i, intervalSize, colorRangeInfo);
      colorArray.push(colorScale(colorPoint));
    }

    return colorArray;
  }
  interpolateDanger(){

  }
}
