import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/_services/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './features/home/home.component';
import { OrganizationComponent } from './features/organization/organization.component';
import { ProjectComponent } from './features/project/project.component';
import { LocationComponent } from './features/location/location.component';
import { OutputComponent } from './features/output/output.component';
import { ActivityComponent } from './features/activity/activity.component';
import { UserComponent } from './features/user/user.component';
import { GroupComponent } from './features/group/group.component';
import { BeneficiaryComponent } from './features/beneficiary/beneficiary.component';
import { ActivityImplementationComponent } from './features/activity-implementation/activity-implementation.component';
import { ActivityAttendeeComponent } from './features/activity-attendee/activity-attendee.component';
import { NoProjectComponent } from './auth/no-project/no-project.component';
const routes: Routes = [
  // { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: {title: 'Beranda' } },

  //AUTH
  { path: 'login', component: LoginComponent, data: {title: 'Masuk Log' } },
  { path: 'no-project', component: NoProjectComponent, data: {title: 'No project found for your organization' } },

  //FEATURES
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: {title: 'Beranda' } },
  { path: 'organization', component: OrganizationComponent, canActivate: [AuthGuard], data: {title: 'Organization' } },
  { path: 'project', component: ProjectComponent, canActivate: [AuthGuard], data: {title: 'Project' } },
  { path: 'location', component: LocationComponent, canActivate: [AuthGuard], data: {title: 'Project Location' } },
  { path: 'output', component: OutputComponent, canActivate: [AuthGuard], data: {title: 'Project Output' } },
  { path: 'activity', component: ActivityComponent, canActivate: [AuthGuard], data: {title: 'Project Activity' } },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard], data: {title: 'User' } },
  { path: 'group', component: GroupComponent, canActivate: [AuthGuard], data: {title: 'Group' } },
  { path: 'beneficiary', component: BeneficiaryComponent, canActivate: [AuthGuard], data: {title: 'Beneficiary' } },
  { path: 'activity-implementation', component: ActivityImplementationComponent, canActivate: [AuthGuard], data: {title: 'Activity Implementation' } },
  { path: 'activity-attendee', component: ActivityAttendeeComponent, canActivate: [AuthGuard], data: {title: 'Activity Attendee' } },


  //Undefined route
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
