import { Component } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AggregateActivityGQL, AggregateBeneficiaryGQL, AggregateGroupGQL, AggregateLocationGQL, AggregateOutputGQL, Project, ProjectGQL } from 'src/app/core/_services/graphql';
import { UtilService } from 'src/app/core/_services/util.service';
import { identity, isEmpty } from 'underscore';

export type ProjectStats = {
  locations: number;
  groups: number;
  beneficiaries: number;
  outputs: number;
  activities: number;
}
export type ProjectSummary = {
  project: Project,
  stats: ProjectStats
}

@Component({
  selector: 'app-project-stats',
  templateUrl: './project-stats.component.html',
  styleUrls: ['./project-stats.component.css']
})

export class ProjectStatsComponent {
  project$:Observable<ProjectSummary>

  constructor(
    private utilService:UtilService,
    private projectDetail: ProjectGQL,
    private aggregateLocation: AggregateLocationGQL,
    private aggregateGroup: AggregateGroupGQL,
    private aggregateBeneficiary: AggregateBeneficiaryGQL,
    private aggregateOutput: AggregateOutputGQL,
    private aggregateActivity: AggregateActivityGQL
  ){

    this.project$ = this.utilService.projectState$
    .pipe(
      map(p=> p.project.id),
      filter(p=> !isEmpty(p)),
      switchMap(pid=>{
        const _project = this.projectDetail.watch({where:{id:pid}})
        .valueChanges
        .pipe(
          map(res=><Project>res.data.project)
        )
        const _location = this.aggregateLocation
        .watch({where:{projectId:{equals:pid}}})
        .valueChanges
        .pipe(
          map(res=> res.data.aggregateLocation._count?.id || 0)
        )
        const _group = this.aggregateGroup
        .watch({where:{projectId:{equals:pid}}})
        .valueChanges
        .pipe(
          map(res=> res.data.aggregateGroup._count?.id || 0)
        )
        const _beneficiary = this.aggregateBeneficiary
        .watch({where:{projectId:{equals:pid}}})
        .valueChanges
        .pipe(
          map(res=> res.data.aggregateBeneficiary._count?.id || 0)
        )
        const _output = this.aggregateOutput
        .watch({where:{projectId:{equals:pid}}})
        .valueChanges
        .pipe(
          map(res=> res.data.aggregateOutput._count?.id || 0)
        )
        const _activity = this.aggregateActivity
        .watch({where:{projectId:{equals:pid}}})
        .valueChanges
        .pipe(
          map(res=> res.data.aggregateActivity._count?.id || 0)
        )

        return combineLatest([_project, _location, _group, _beneficiary, _output, _activity])
        .pipe(
          map(([__project, __location, __group, __beneficiary, __output, __activity])=>{
            return <ProjectSummary>{
              project: __project,
              stats:{
                locations: __location,
                groups: __group,
                beneficiaries: __beneficiary,
                outputs: __output,
                activities: __activity
              }
            }
          })
        )

        // return this.projectDetail.watch({where:{id:pid}})
        // .valueChanges
        // .pipe(
        //   map(res=><Project>res.data.project)
        // )
      })
    )
  }
}
