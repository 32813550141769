import { Component, HostListener } from '@angular/core';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { SortOrder, Output, OutputsGQL, OutputGQL, OutputsQueryVariables, OutputsQuery, DeleteOneOutputGQL, ActivitiesGQL, Activity, DeleteOneActivityGQL, OutputTreeGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { UtilService } from 'src/app/core/_services/util.service';
import { AuthService } from 'src/app/core/_services/auth.service';
import { DialogService } from 'src/app/core/_services/dialog.service';

@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('500ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('50ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ]),
    trigger('slideLeftRight', [
      transition(':enter', [style({ left: '100%' }), animate(200)]),
      transition(':leave', [animate(200, style({ left: '100%' }))]),
    ]),
  ]
})
export class OutputComponent {
  outputs$:Observable<Output[]>
  queryVariables:OutputsQueryVariables = {
    orderBy:{createdAt:{sort:SortOrder.Desc}}
  }
  displayedColumns: string[] = [
    'name',
    'description',
    'createdAt',
    'updatedAt',
    'action'
  ]
  displayForm = false
  formTitle = 'Add Output'
  isNewRecord = false
  output: Output = <Output>{}
  activity: Activity = <Activity>{}
  formType = ''
  // orgQuery: QueryRef<OutputsQuery>
  private readonly refresh$ = new Subject<void>();
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.displayForm) this.displayForm = false
  }
  constructor(
    private outputsGQL: OutputTreeGQL,
    private loader: LoaderService,
    private deleteOutputGQL: DeleteOneOutputGQL,
    private deleteActivityGQL: DeleteOneActivityGQL,
    public util: UtilService,
    public authService: AuthService,
    private dialogService: DialogService
    ){

    this.outputs$ = this.refresh$
    .pipe(
      startWith(undefined),
      switchMap(res=>{
        return this.util.projectState$.pipe(
          map(p=>p.project.id)
        )}
      ),
      switchMap(pid=>{
        return this.outputsGQL.watch({where:{projectId:{equals:pid}}}, {fetchPolicy:'network-only'}).valueChanges.pipe(
          take(1),
          this.loader.indicate(),
          map(res=><Output[]>res.data.outputs)
        )
      })
    )
  }
  toggleDisplayForm(){
    this.displayForm = this.displayForm ? false : true
  }
  addOutput(){
    if(this.isNewRecord && this.formType == 'output') this.toggleDisplayForm()
    else{
      this.displayForm = true
      this.isNewRecord = true
      this.formTitle = 'Add Output'
      this.formType = 'output'
    }
  }
  editOutput(row?:Output){
    if(row){
      if(row.id && row.id == this.output.id && !this.isNewRecord && this.formType == 'output') this.toggleDisplayForm()
      else{
        this.formType = 'output'
        this.displayForm = true
        this.formTitle = 'Update Output'
        this.isNewRecord = false
        this.output = row
      }
    }
  }
  deleteOutput(id:string){
    this.dialogService.confirm({
      title:'Delete Confirmation',
      message:'Are you sure you want to delete this output data?'
    }).subscribe(yes=>{
      if(yes){
        this.deleteOutputGQL.mutate({where:{id:id}}).subscribe(({data})=>{
          if(data?.deleteOneOutput?.id){
            this.refresh$.next()
          }
        })
      }
    })
  }
  addActivity(output:Output){
    if(this.isNewRecord && this.formType == 'activity') this.toggleDisplayForm()
    else{
      this.displayForm = true
      this.isNewRecord = true
      this.formTitle = 'Add Activity'
      this.formType = 'activity'
      this.output = output
    }
  }
  editActivity(row:Activity){
    if(row){
      if(row.id && row.id == this.output.id && !this.isNewRecord && this.formType == 'activity') this.toggleDisplayForm()
      else{
        this.formType = 'activity'
        this.displayForm = true
        this.formTitle = 'Update Activity'
        this.isNewRecord = false
        this.activity = row
      }
    }
  }
  deleteActivity(id:string){
    this.dialogService.confirm({
      title:'Delete Confirmation',
      message:'Are you sure you want to delete this activity data?'
    }).subscribe(yes=>{
      if(yes){
        this.deleteActivityGQL.mutate({where:{id:id}}).subscribe(({data})=>{
          if(data?.deleteOneActivity?.id){
            this.refresh$.next()
          }
        })
      }
    })
  }
  saved(){
    this.toggleDisplayForm()
    this.refresh$.next()
  }
}
