import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/_services/auth.service';
import { LocalQueryService, LocalStorageService } from 'src/app/core/_services/localstorage.service';
import { UtilService } from 'src/app/core/_services/util.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent {
  constructor(public authService: AuthService, private utilService: UtilService, private ls:LocalStorageService, private lq: LocalQueryService, private router: Router){}
  logout(){
    this.ls.clear()
    this.lq.clear()
    this.utilService.destroyProjectState()
    console.log(this.utilService.projectStateProject)
    this.router.navigate(['/login'])
  }
}
