<div class="p-3" *ngIf="isFormReady && organizationForm">
    <form [formGroup]="organizationForm" (keyup.enter)="save()" (ngSubmit)="save()" class="w-100">
        <div class=row>
            <div class="flex flex-col">
                <mat-form-field class="block">
                  <mat-label>Organization Name</mat-label>
                  <input matInput type="text" placeholder="Organization Name" formControlName="name">
                  <mat-error *ngIf="organizationForm.controls['name']?.hasError('required')">
                    Name must be filled.
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="block">
                  <mat-label>Description</mat-label>
                  <input matInput type="text" placeholder="Description" formControlName="description">
                  <mat-error *ngIf="organizationForm.controls['description']?.hasError('required')">
                      Description must be filled.
                  </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="p-3">
  <button type="submit" mat-raised-button color="primary" (click)="save()">Save</button>
</div>

