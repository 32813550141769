import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CreateOneActivityImplementationGQL, ActivityImplementation, UpdateOneActivityImplementationGQL, ActivityImplementationCreateInput, Activity } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { UtilService } from 'src/app/core/_services/util.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-activity-implementation-form',
  templateUrl: './activity-implementation-form.component.html',
  styleUrls: ['./activity-implementation-form.component.css']
})
export class ActivityImplementationFormComponent implements OnChanges, OnDestroy{
  @Input() recordId:string = ''
  @Input() isFullAccess:boolean = false
  @Input() activity:Activity = <Activity>{}
  @Input() implementation:ActivityImplementation = <ActivityImplementation>{}
  @Output() complete = new EventEmitter()

  // activityImplementation: ActivityImplementation = <ActivityImplementation>{}
  activityImplementationForm?: FormGroup
  isNewRecord: boolean
  isFormReady:boolean = false
  projectId = ''
  sub: Subscription

  constructor(
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private createActivityImplementation: CreateOneActivityImplementationGQL,
    private updateActivityImplementation: UpdateOneActivityImplementationGQL,
    private loader: LoaderService,
    private util: UtilService
  ){
    this.isNewRecord = this.implementation?.id ? false : true
    this.activityImplementationForm = this.buildForm()
    this.isFormReady = true
    this.sub = this.util.projectState$.subscribe(p=>{
      this.projectId = p.project.id
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
      this.isNewRecord = this.implementation?.id ? false : true
      this.activityImplementationForm = this.buildForm()
      if(!this.isNewRecord) this.activity = this.implementation.activity
      this.isFormReady = true
  }
  buildForm(){
    return this.fb.group({
      dateStart: [!this.isNewRecord ? this.util.localizeDate(this.implementation?.dateStart) : '', Validators.required],
      dateEnd: [!this.isNewRecord ? this.util.localizeDate(this.implementation?.dateEnd) : '', Validators.required],
      description: [!this.isNewRecord ? this.implementation?.description : '', Validators.required],
      activity: [!this.isNewRecord ? this.implementation?.activity : this.activity, Validators.required],
    })
  }
  save(){
    if(this.activityImplementationForm?.valid){
      const formValue = <ActivityImplementation>this.activityImplementationForm.value
      if(this.isNewRecord){
        const data:ActivityImplementationCreateInput = {
          dateStart: this.util.normalizeDate(formValue.dateStart),
          dateEnd: this.util.normalizeDate(formValue.dateEnd),
          description: formValue.description,
          activity:{
            connect:{
              id: formValue.activity.id
            }
          }
        }
        this.createActivityImplementation.mutate({data:data})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.createOneActivityImplementation.id)
        ).subscribe(id=>{
          if(id){
            this.complete.emit(true)
          }
        })
      }else{
        this.updateActivityImplementation.mutate({data:{
          dateStart:{set:this.util.normalizeDate(formValue.dateStart)},
          dateEnd:{set:this.util.normalizeDate(formValue.dateEnd)},
          description:{set: formValue.description},
          activity:{
            connect:{
              id: formValue.activity.id
            }
          }
        }, where:{id:this.implementation.id}})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.updateOneActivityImplementation?.id)
        ).subscribe(name=>{
          if(name){
            this.complete.emit(true)
          }
        })
      }

    }else{
      console.log(this.activityImplementationForm?.value)
    }
  }
  ngOnDestroy(): void {
      if(this.sub) this.sub.unsubscribe()
  }
}
