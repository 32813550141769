import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {AfterViewInit, Component, Injectable} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/_services/auth.service';
import { DialogService } from 'src/app/core/_services/dialog.service';
import { CreateOneLocationGQL, DeleteOneLocationGQL, Location, LocationTreeGQL, LocationsGQL, UpdateOneLocationGQL, UuidFilter } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { UtilService } from 'src/app/core/_services/util.service';
import { filter, first, pick } from 'underscore';
/**
 * Node for to-do item
 */
export class LocationItemNode {
  children!: LocationItemNode[];
  item!: Location;
}

/** Flat to-do item node with expandable and level information */
export class LocationItemFlatNode {
  item!: Location;
  level!: number;
  expandable!: boolean;
  hasChild!: boolean;
  edit!: boolean
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<LocationItemNode[]>([]);

  get data(): LocationItemNode[] {
    return this.dataChange.value;
  }

  constructor(
    private locationTree: LocationTreeGQL,
    private createLocation: CreateOneLocationGQL,
    private editLocation: UpdateOneLocationGQL,
    private deleteLocation: DeleteOneLocationGQL,
    private loader: LoaderService,
    private utilService: UtilService
  ) {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `LocationItemNode` with nested
    //     file node as children.

    this.buildTree().subscribe(data=>{
      this.dataChange.next(data);
    })
  }

  buildTree(){
    return this.utilService.projectState$.pipe(
      map(p=>p.project.id),
      switchMap(pid=>{
        return this.locationTree.watch({where:{level:{equals:0}, projectId:{equals:pid}}}, {fetchPolicy:"network-only"}).valueChanges.pipe(
          map(res=>{
            const locs = <Location[]>res.data.locations
            return this._tree(locs)
          })
        )
      })
    )
  }

  _tree(locs: Location[]): LocationItemNode[] {
    return Object.keys(locs).reduce<LocationItemNode[]>((accumulator, val, key) => {

      const value:Location = <Location>locs[key];
      const node = new LocationItemNode();
      node.item = value;

      if (value != null) {
        if (typeof value === 'object' && value?.children?.length > 0) {
          node.children = this._tree(value.children);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: LocationItemNode, item: Location) {
    console.log(parent.item)
    if (parent.children) {
      parent.children.push({
        item: <Location>{
          parentId:parent.item.id,
          projectId: this.utilService.projectStateId,
          level: parent.item.level + 1
        }
      } as LocationItemNode);
      this.dataChange.next(this.data);
    }else{
      parent.children = [{
        item:<Location>{
          parentId:parent.item.id,
          projectId: this.utilService.projectStateId,
          level: parent.item.level + 1
        }
      } as LocationItemNode];
      this.dataChange.next(this.data);
    }
  }

  addRootNode(item: Location) {
    const newItem = new LocationItemNode()
    newItem.item = <Location>{
      name:'',
      isLeaf: true,
      projectId: this.utilService.projectStateId,
      level:0
    };
    // console.log(this.utilService.projectState?.project?.id)
    // newItem.children = []
    this.data.push(newItem)
    this.dataChange.next(this.data);
  }

  createItem(item: LocationItemFlatNode, name: string){
    return this.createLocation.mutate({data:{
      name: name,
      isLeaf: true,
      level: item.item.level,
      project:{
        connect:{
          id: item.item.projectId
        }
      },
      parent:{
        connect:{
          id: item.item.parentId,
        }
      }
    }}).pipe(
      take(1),
      this.loader.indicate(),
    )
  }
  setNoLeaf(id:string){
    return this.editLocation.mutate({data:{isLeaf:{set: false}}, where:{id:id}}).pipe(map(res=>res.data?.updateOneLocation))
  }
  createRootItem(item: LocationItemFlatNode, name: string){
    return this.createLocation.mutate({data:{
      name: name,
      isLeaf: true,
      level: item.item.level,
      project:{
        connect:{
          id: item.item.projectId
        }
      }
    }}).pipe(
      take(1),
      this.loader.indicate(),
    )
  }

  updateItem(node: LocationItemNode, item: Location) {
    node.item = item;
    this.dataChange.next(this.data);
  }

  editItem(node: LocationItemNode){
    this.dataChange.next(this.data);
  }

  update(node: LocationItemFlatNode, name: string){
    return this.editLocation.mutate(
      {
        data:{
          name: {
            set:name
          }
        },
        where:{
          id:node.item.id
        }
      }).pipe(
        take(1),
        this.loader.indicate(),
      )
  }

  deleteItem(id: string){
    return this.deleteLocation.mutate({where:{id:id}}).pipe(
      take(1),
      this.loader.indicate(),
      map(res=>res.data?.deleteOneLocation?.id)
    )
  }
}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-location',
  templateUrl: 'location.component.html',
  styleUrls: ['location.component.css'],
  providers: [ChecklistDatabase],
})
export class LocationComponent implements AfterViewInit{
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<LocationItemFlatNode, LocationItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<LocationItemNode, LocationItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: LocationItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<LocationItemFlatNode>;

  treeFlattener: MatTreeFlattener<LocationItemNode, LocationItemFlatNode>;

  dataSource: MatTreeFlatDataSource<LocationItemNode, LocationItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<LocationItemFlatNode>(true /* multiple */);

  constructor(private _database: ChecklistDatabase, public authService: AuthService, private dialogService: DialogService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<LocationItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  getLevel = (node: LocationItemFlatNode) => node?.level;

  isExpandable = (node: LocationItemFlatNode) => node?.expandable;

  getChildren = (node: LocationItemNode): LocationItemNode[] => node?.children;

  hasChild = (_: number, _nodeData: LocationItemFlatNode) => _nodeData?.expandable;

  hasNoContent = (_: number, _nodeData: LocationItemFlatNode) => _nodeData.item.name &&  _nodeData.item.name ? false : true;

  isEdit = (node: LocationItemFlatNode) => node?.edit;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: LocationItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new LocationItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = true;
    flatNode.hasChild = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.expandAll()
    }, 500)
  }
  expandAll(){
    this.treeControl.expandAll()
  }
  collapseAll(){
    this.treeControl.collapseAll()
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: LocationItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this._database.insertItem(parentNode!, <Location>{});
    this.treeControl.expand(node);
  }

  addRootNode() {
    this._database.addRootNode(<Location>{});
  }

  /** Save the node to database */
  saveNode(node: LocationItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    console.log(node)
    if(nestedNode?.item?.name){
      // this._database.updateItem(nestedNode!, <Location>{name: itemValue});

      if(node.edit){
        this._database.update(node, itemValue).subscribe((res)=>{
          if(res.data?.updateOneLocation){
            console.log('UPDATE success', res.data?.updateOneLocation)
            this._database.updateItem(nestedNode!, <Location>{...nestedNode?.item, ...{name: itemValue}});
            node.edit = false
          }
        })
      }
    }else{
      if(node.item.parentId){
        this._database.createItem(node, itemValue).subscribe((res)=>{
          if(!res.errors && res.data?.createOneLocation){
            console.log('CREATE success', res.data?.createOneLocation)
            this._database.updateItem(nestedNode!, <Location>{...nestedNode?.item, ...{name: itemValue, id:res.data?.createOneLocation.id}});
            this._database.setNoLeaf(<string>node?.item?.parentId).subscribe()
          }
        });
      }else{
        this._database.createRootItem(node, itemValue).subscribe((res)=>{
          if(!res.errors && res.data?.createOneLocation){
            console.log('CREATE root success', res.data?.createOneLocation)
            this._database.updateItem(nestedNode!, <Location>{...nestedNode?.item, ...{name: itemValue, id:res.data?.createOneLocation.id}});
          }
        });
      }
    }
  }
  delete(item: LocationItemFlatNode){
    // console.log(this._database.data)
    this.dialogService.confirm({
      title:'Delete Confirmation',
      message:'Are you sure you want to delete this location data?'
    }).subscribe(yes=>{
      if(yes){
        let _data = this._database.data
        this._database.deleteItem(item.item.id).subscribe(id=>{
          if(id==item.item.id){
            const data = this._delete(_data, item.item.id)
            this._database.dataChange.next(data)
            this.treeControl.expandAll()
          }
        })
      }
    })

  }
  _delete(items: LocationItemNode[], id:string){
    return Object.keys(items).reduce<LocationItemNode[]>((accumulator, val, key) => {

      const value = <LocationItemNode>items[key];
      const node = new LocationItemNode();
      node.item = value.item;

      if(value != null && value.item.id !== id){
        if (typeof value === 'object' && value?.children?.length > 0) {
          node.children = this._delete(value.children, id);
        } else {
          node.item = value.item;
        }
        return accumulator.concat(node);
      }else{
        return filter(accumulator, ac=>ac.item.id != id)
      }
    }, []);
  }
  edit(node: LocationItemFlatNode){
    const _node = this.flatNodeMap.get(node);
    node.edit = true
    this._database.editItem(_node!)
  }
  levelClass(level:number){
    switch(level){
      case 0 : return 'text-red-300';break;
      case 1 : return 'text-blue-300';break;
      case 2 : return 'text-orange-300';break;
      case 3 : return 'text-green-300';break;
      default: return 'text-slate-300';break;
    }
  }
  cancelAdd(node: LocationItemFlatNode){
    const data = this._delete(this._database.data, node.item.id)
    this._database.dataChange.next(data)
  }
  cancelEdit(node: LocationItemFlatNode){
    node.edit = false
  }
}
