import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, of, combineLatest } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { shareReplay, map, switchMap, startWith } from 'rxjs/operators';
import { pick, isObject, isArray, isEmpty, has, map as _map } from 'underscore';
import { Project } from './graphql';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { formatDate } from '@angular/common';

export interface MessageState {
  message: string;
  type?: string; // info | success | warning |  error - info as default
  button?: string;
}

export interface ProjectState {
  isActive: boolean;
  project: Project
}

@Injectable({ providedIn: 'root' })
export class UtilService {
  // https://simple.wikipedia.org/wiki/Stellar_classification
  SETELLAR_COLOR = [
    '#9db4ff', // 5m blue
    '#aabfff', // 15m deep blue white
    '#cad8ff', // 45m blue white
    '#ffddb4', // 2h pale yellow orange
    '#ffbd6f', // 6h light orange red
    '#f84235', // 20h scarlet
    '#ba3059', // 2d magenta
    '#605170' // dark purple
  ];
  isHandset = false;
  titleSubject = new BehaviorSubject<string>('Beranda');
  title$ = this.titleSubject.asObservable();

  projectSubject = new BehaviorSubject<ProjectState>({isActive: false, project:<Project>{}});
  projectState$ = this.projectSubject.asObservable();


  /** Number of different types of notification. */
  notificationCount$?: Observable<number>;

  private messageSubject = new Subject<MessageState>();
  messageState = this.messageSubject.asObservable();

  constructor(
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver
      .observe(Breakpoints.Handset)
      .subscribe(result => this.isHandset = result.matches);

  }

  getColor(ts: number, type: string = 'bg') {
    if (ts) {
      const c = this.SETELLAR_COLOR;
      const ago = (Date.now() - ts) / 1000 / 60;
      for (let i = 0, t = 5; i < c.length; i++, t *= 3) {
        if (ago <= t) {
          if (type == 'bg') return c[i];
          else {
            if (i > 5) return 'inherit';
          }
        }
      }
    }
    return '';
  }

  setTitle(title: string) {
    this.titleSubject.next(title);
  }

  timeAgo(time: string) {
    dayjs.extend(relativeTime)
    return dayjs(time).fromNow()
  }

  formatDate(date:any, format:string = 'D MMM YYYY'){
    return dayjs(date).format(format)
  }
  normalizeDate(date:any, format:string = 'yyyy-MM-dd'){
    return formatDate(date, format, 'en')
  }
  localizeDate(date:any, format:string = 'YYYY-MM-DD'){
    const local = dayjs(date).format(format)
    return local
  }

  sendMessage(message: string | MessageState) {
    if (typeof message == 'object') {
      if (!message.button) message.button = 'OK';
      this.messageSubject.next(<MessageState>message);
    } else if (typeof message == 'string') {
      let messageToSend = {
        message: message,
        button: 'OK',
        type: 'info'
      }
      this.messageSubject.next(<MessageState>messageToSend);
    }
  }
  pickValid(data:any, excludes:string[]=[], deep:boolean = false){
    // console.log(excludes)
    const __keysValid = Object.keys(data).map((k:any)=>{
      if(isArray(data[k]) && data[k].length > 0){
        // console.log(k, 'is array')
        const valids = data[k].map((e:any)=> {
          if(!isEmpty(e)) return e
        })
        if(valids.length > 0){
          data[k] = valids
          return k
        }
      }
      else if(isObject(data[k])){
        // console.log(k, 'is object')
        // console.log(data[k])
        //ignore moment object
        // if(has(data[k], '_isAMomentObject')) return k

        if(excludes.indexOf(k) !== -1){
          // console.log('index ', data[k], ' '+ excludes.indexOf(data[k]))
          return k
        }

        // data[k] = deep ? this.pickValid(data[k], excludes, true) : this.pickValid(data[k])
        const _obj = deep ? this.pickValid(data[k], excludes, true) : this.pickValid(data[k])
        if(!isEmpty(_obj)) return k
      }
      else if(data[k]!== null && data[k] !== '') return k
    })
    // console.log(__keysValid)
    return pick(data, __keysValid)
  }
  numberWithCommas(x:any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  isValidEmail(email:string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  setProjectState(state: boolean | Project | ProjectState){
    if (typeof state == 'object') {
      if(state.hasOwnProperty('id')){
        let currentState = this.projectSubject.getValue()
        currentState.project = <Project>state
        this.projectSubject.next(currentState);
      }else{
        this.projectSubject.next(<ProjectState>state);
      }
    } else if (typeof state == 'boolean') {
      let currentState = this.projectSubject.getValue()
      currentState.isActive = state
      currentState.project = currentState.project ? currentState.project : <Project>{}
      this.projectSubject.next(currentState);
    }
  }
  get projectState(): ProjectState{
    return this.projectSubject.getValue()
  }
  get hasProjectState(): boolean{
    if(this.projectState.hasOwnProperty('project') && this.projectState.project) return true;
    return false;
  }
  get projectStateId(): string{
    const state = this.projectSubject.getValue()
    return state.project && state.project.id ? state.project.id : ''
  }
  get projectStateName(): string{
    const state = this.projectSubject.getValue()
    return state.project && state.project.name ? state.project.name : ''
  }
  get projectStateProject(): Project{
    const state = this.projectSubject.getValue()
    return state.project ? state.project : <Project>{}
  }
  destroyProjectState(){
    this.projectSubject.next(<ProjectState>{isActive:false, project:{}})
  }
}
