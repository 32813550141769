import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { CreateOneOutputGQL, Output as IOutput, UpdateOneOutputGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';

@Component({
  selector: 'app-output-form',
  templateUrl: './output-form.component.html',
  styleUrls: ['./output-form.component.css']
})
export class OutputFormComponent implements OnChanges{
  @Input() recordId:string = ''
  @Input() outputId:string = ''
  @Input() projectId:string = ''
  @Input() output:IOutput = <IOutput>{}
  @Input() isFullAccess:boolean = false
  @Output() complete = new EventEmitter()

  // output: Output = <Output>{}
  outputForm?: FormGroup
  isNewRecord: boolean
  isFormReady:boolean = false

  constructor(
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private createOutput: CreateOneOutputGQL,
    private updateOutput: UpdateOneOutputGQL,
    private loader: LoaderService
  ){
    this.isNewRecord = this.output?.id ? false : true
    this.outputForm = this.buildForm()
    this.isFormReady = true
  }
  ngOnChanges(changes: SimpleChanges): void {
      // this.output = changes.output?.currentValue
      // console.log(this.output)
      this.isNewRecord = this.output?.id ? false : true
      this.outputForm = this.buildForm()
      this.isFormReady = true
  }
  buildForm(){
    return this.fb.group({
      name: [!this.isNewRecord ? this.output?.name : '', Validators.required],
      description: [!this.isNewRecord ? this.output?.description : '', Validators.required],
    })
  }
  save(){
    if(this.outputForm?.valid){
      if(this.isNewRecord){
        this.createOutput.mutate({data:{...this.outputForm.value, ...{project:{
          connect:{id: this.projectId}
        }}}})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.createOneOutput.id)
        ).subscribe(id=>{
          if(id){
            this.complete.emit(true)
          }
        })
      }else{
        this.updateOutput.mutate({data:{
          name:{set:this.outputForm.get('name')?.value},
          description:{set:this.outputForm.get('description')?.value},
        }, where:{id:this.output.id}})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.updateOneOutput?.name)
        ).subscribe(name=>{
          if(name){
            this.complete.emit(true)
          }
        })
      }

    }
  }
}
