<mat-card>
  <h2 class="p-3 m-0">Beneficiaries by Group Classification</h2>
  <div class="p-3 w-full">
    <!-- Begin Groups -->
    <ng-container *ngIf="(groups$ | async) as groups">
      <form [formGroup]="statsForm">
        <mat-form-field class="w-full block">
          <mat-label>Groups</mat-label>
          <mat-select formControlName="group" disableOptionCentering [compareWith]="compareObjects">
            <ng-container
              *ngTemplateOutlet="
                recursiveOptions;
                context: { $implicit: groups, depth: 1 }
              "
            >
            </ng-container>

            <ng-template #recursiveOptions let-list let-depth="depth">
              <ng-container *ngFor="let group of list">
                <mat-option
                  [value]="group"
                  [ngStyle]="{
                    'padding-left.px': depth <= 1 ? null : 16 * depth
                  }"
                >
                  {{ group?.name }}
                </mat-option>

                <!-- <ng-container
                  *ngIf="!!group.children && group.children.length > 0"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      recursiveOptions;
                      context: { $implicit: group.children, depth: depth + 1 }
                    "
                  >
                  </ng-container>
                </ng-container> -->
              </ng-container>
            </ng-template>
          </mat-select>
        </mat-form-field>
      </form>
    </ng-container>
    <!-- End Groups -->
    <ng-container *ngIf="(stats$ | async) as stats">
      <app-bar-chart
      [labels]="stats.labels"
      [datasets]="stats.datasets"
      >
      </app-bar-chart>
    </ng-container>
  </div>
</mat-card>

