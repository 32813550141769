<div class="p-3" *ngIf="isFormReady && projectForm">
  <form [formGroup]="projectForm" (keyup.enter)="save()" (ngSubmit)="save()" class="w-100">
      <div class=row>
          <div class="flex flex-col">
              <mat-form-field class="block">
                <mat-label>Project Name</mat-label>
                <input matInput type="text" placeholder="Project Name" formControlName="name">
                <mat-error *ngIf="projectForm.controls['name']?.hasError('required')">
                  Name must be filled.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="block">
                <mat-label>Project Code</mat-label>
                <input matInput type="text" placeholder="Project Code" formControlName="code">
                <mat-error *ngIf="projectForm.controls['code']?.hasError('required')">
                  Project Code must be filled.
                </mat-error>
            </mat-form-field>
              <mat-form-field class="block">
                <mat-label>Description</mat-label>
                <input matInput type="text" placeholder="Description" formControlName="description">
                <mat-error *ngIf="projectForm.controls['description']?.hasError('required')">
                    Description must be filled.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="fill">
                <mat-label>Organization who implement</mat-label>
                <input type="text"
                       placeholder="Pick one"
                       aria-label="Organization"
                       matInput
                       formControlName="organization"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let option of listOrganizations | async" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
          </div>
      </div>
  </form>
</div>
<div class="p-3">
<button type="submit" mat-raised-button color="primary" (click)="save()">Save</button>
</div>

