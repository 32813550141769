<ng-container *ngIf="(authService.isLoggedIn$ | async) == true; else loginTemplate">
  <div class="flex flex-col h-screen">
    <div class="bg-white border-b border-slate-200">
      <div class="p-3"><app-header-menu></app-header-menu></div>
    </div>
    <div class="bg-white flex flex-row grow overflow-y-auto">
      <div class="h-max"><app-dashboard-menu></app-dashboard-menu></div>
      <div class="h-max min-w-3/4 grow bg-slate-100 p-5"><router-outlet></router-outlet></div>
    </div>
    <div class="bg-slate-200 p-3">Powered by INFEST</div>
  </div>
</ng-container>
<app-loader></app-loader>
<app-message-alert></app-message-alert>
<ng-template #loginTemplate>
  <div class="flex flex-col justify-center items-center" style="height: 100vh;width:100vw;background-color: #4e4e4e;">
    <div class="auth-header flex flex-col">
      <div class="flex-grow-1"></div>
      <div class="py-3">
        <img src="assets/app-icon.png" alt="" style="max-height:200px;">
      </div>
    </div>
    <div class="auth-content w-100 p-auto" style="height: 50vh;">
      <app-login></app-login>
      <!-- <router-outlet></router-outlet> -->
    </div>
    <div class="auth-footer" style="height: 25vh;"></div>
  </div>
</ng-template>
