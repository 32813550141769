import { Component, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { SortOrder, ActivityImplementation, ActivityImplementationsGQL, ActivityImplementationGQL, ActivityImplementationsQueryVariables, ActivityImplementationsQuery, DeleteOneActivityImplementationGQL, OutputTreeGQL, Output, Activity, OutputTreeImplementationGQL, AggregateParticipantGQL, AggregateAssetGQL } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { QueryRef } from 'apollo-angular';
import { UtilService } from 'src/app/core/_services/util.service';
import { DialogService } from 'src/app/core/_services/dialog.service';

@Component({
  selector: 'app-activityImplementation',
  templateUrl: './activity-implementation.component.html',
  styleUrls: ['./activity-implementation.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('500ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('50ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ]),
    trigger('slideLeftRight', [
      transition(':enter', [style({ left: '100%' }), animate(200)]),
      transition(':leave', [animate(200, style({ left: '100%' }))]),
    ]),
  ]
})
export class ActivityImplementationComponent implements OnChanges{
  displayForm = false
  formTitle = 'Add Activity Implementation'
  isNewRecord = false
  formType = 'implementation'
  activity:Activity = <Activity>{}
  activityImplementationId = ''

  implementation: ActivityImplementation = <ActivityImplementation>{}
  private readonly refresh$ = new Subject<void>();
  outputs$:Observable<Output[]> = of(<Output[]>[])
  refreshStats = new BehaviorSubject(new Date().toISOString())

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.displayForm) this.displayForm = false
  }

  constructor(
    private activityImplementationsGQL: ActivityImplementationsGQL,
    private loader: LoaderService,
    private deleteActivityImplementation: DeleteOneActivityImplementationGQL,
    public util: UtilService,
    private outputsGQL: OutputTreeImplementationGQL,
    private dialogService: DialogService
  ){
    this.outputs$ = this.refresh$
    .pipe(
      startWith(undefined),
      switchMap(res=>{
        return this.util.projectState$.pipe(
          map(p=>p.project.id)
        )}
      ),
      switchMap(pid=>{
        return this.outputsGQL.watch({where:{projectId:{equals:pid}}}, {fetchPolicy:'network-only'}).valueChanges.pipe(
          take(1),
          this.loader.indicate(),
          map(res=><Output[]>res.data.outputs)
        )
      })
    )
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.outputs$ = this.refresh$
    .pipe(
      startWith(undefined),
      switchMap(res=>{
        return this.util.projectState$.pipe(
          map(p=>p.project.id)
        )}
      ),
      switchMap(pid=>{
        return this.outputsGQL.watch({where:{projectId:{equals:pid}}}, {fetchPolicy:'network-only'}).valueChanges.pipe(
          take(1),
          this.loader.indicate(),
          map(res=><Output[]>res.data.outputs)
        )
      })
    )
  }
  toggleDisplayForm(){
    this.displayForm = this.displayForm ? false : true
  }
  addImplementation(act:Activity){
    this.formType = 'implementation'
    if(this.isNewRecord) this.toggleDisplayForm()
    else{
      this.activity = act
      this.displayForm = true
      this.isNewRecord = true
      this.formTitle = 'Add Activity Implementation'
    }
  }
  updateImplementation(activityImplementation:ActivityImplementation, activity:Activity){
    if(activityImplementation){
      this.formType = 'implementation'
      if(activityImplementation.id && activityImplementation.id == this.implementation.id && !this.isNewRecord){
        this.toggleDisplayForm()
        this.implementation = {...activityImplementation, ...{activity: activity}}
      }
      else{
        this.displayForm = true
        this.formTitle = 'Update Activity Implementation'
        this.isNewRecord = false
        this.implementation = {...activityImplementation, ...{activity: activity}}
      }
    }
  }
  deleteImplementation(id:string){
    this.dialogService.confirm({
      title:"Delete Confirmation",
      message:'Are you sure want to delete this activity implementation and its related data?'
    }).subscribe(yes=>{
      if(yes){
        this.deleteActivityImplementation.mutate({where:{id:id}}).subscribe(({data})=>{
          if(data?.deleteOneActivityImplementation?.id){
            this.refresh$.next()
          }
        })
      }
    })
  }
  implementationSaved(){
    this.toggleDisplayForm()
    this.refresh$.next()
  }
  activityDate(dateStart:string, dateEnd: string){
    return dateStart == dateEnd ? this.util.formatDate(dateStart) : `${this.util.formatDate(dateStart)} - ${this.util.formatDate(dateEnd)}`
  }
  openAssets(){
    this.formType = 'assets'
    this.displayForm = true
    this.formTitle = 'Manage Assets'
  }
  openParticipants(implementation: ActivityImplementation){
    this.formType = 'participants'
    this.implementation = implementation
    this.displayForm = true
    this.formTitle = 'Manage Participants'
  }
  onChangeParticipants(){
    this.refreshStats.next(new Date().toISOString())
  }
}
