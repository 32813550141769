import { Component } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AggregateBeneficiaryGQL } from 'src/app/core/_services/graphql';
import { UtilService } from 'src/app/core/_services/util.service';

export type BeneStatsBySex = {
  male: number
  female: number
  undefined: number
}

@Component({
  selector: 'app-beneficiary-stats-sex',
  templateUrl: './beneficiary-stats-sex.component.html',
  styleUrls: ['./beneficiary-stats-sex.component.css']
})
export class BeneficiaryStatsSexComponent {

  stats$: Observable<number[]>
  labels:string[] = ['MALE', 'FEMALE', 'UNDEFINED']

  constructor(
    private utilService: UtilService,
    private aggregateBene: AggregateBeneficiaryGQL
  ){
    this.stats$ = this.utilService.projectState$
    .pipe(
      switchMap(p=>{
        const pid = p.project.id
        return combineLatest([
          this.aggregateBene.watch({
            where:{sex:{equals:"MALE"}, projectId:{equals: pid}}
          }).valueChanges,
          this.aggregateBene.watch({
            where:{sex:{equals:"FEMALE"}, projectId:{equals: pid}}
          }).valueChanges,
          this.aggregateBene.watch({
            where:{sex:{equals:"UNDEFINED"}, projectId:{equals: pid}}
          }).valueChanges,
        ])
        .pipe(
          map(([male, female, undefined])=>{
            const result = <number[]>[
              male.data.aggregateBeneficiary._count?.id,
              female.data.aggregateBeneficiary._count?.id,
              undefined.data.aggregateBeneficiary._count?.id
            ]
            return result
          })
        )
      })
    )
  }
}
