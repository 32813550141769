import { Component } from '@angular/core';

@Component({
  selector: 'app-activity-autocomplete',
  templateUrl: './activity-autocomplete.component.html',
  styleUrls: ['./activity-autocomplete.component.css']
})
export class ActivityAutocompleteComponent {

}
