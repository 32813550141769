<div class="flex flex-row justify-between items-center">
  <div class="flex flex-row items-center text-lg">
    <!-- <span class="font-bold mr-1">AWO</span> -->
    <a routerLink="/home" class="flex items-center">
      <img src="assets/app-icon.png" alt="" style="max-height:40px;">
      <span class="ml-2 font-bold text-sm">database</span>
    </a>
    <div class="px-3">
      <app-project-state></app-project-state>
    </div>
  </div>
  <div class="flex items-center">
    <span class="text-slate-500">{{authService.userEmail}}</span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User Menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>person</mat-icon>
        <span>Profil</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Sign out</span>
      </button>
    </mat-menu>
  </div>
</div>
