<div class="p-3" *ngIf="isFormReady && activityImplementationForm">
  <form [formGroup]="activityImplementationForm" (keyup.enter)="save()" (ngSubmit)="save()" class="w-100">
      <div class=row>
          <div class="flex flex-col">
            <div class="flex flex-col mb-3">
              <div class="font-bold mb-3">Activity</div>
              <div class="flex flex-row" *ngIf="activity">
                <div class="py-2 px-3 rounded bg-blue-100 mr-3 flex items-center font-bold text-blue-700">{{activity.code}}</div>
                <div>
                  <div class="">{{activity.name}}</div>
                  <div class="text-slate-500 mt-2">{{activity.description}}</div>
                </div>
              </div>
            </div>
            <mat-form-field>
              <mat-label>Enter dates for activity implementation</mat-label>
              <mat-date-range-input [formGroup]="activityImplementationForm" [rangePicker]="picker">
                <input matStartDate formControlName="dateStart" placeholder="Start date">
                <input matEndDate formControlName="dateEnd" placeholder="End date">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error *ngIf="activityImplementationForm.controls.dateStart.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="activityImplementationForm.controls.dateEnd.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
            <mat-form-field class="mt-3">
              <mat-label>Description</mat-label>
              <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
          </div>
      </div>
      <div class="p-3">
        <button type="submit" mat-raised-button color="primary">Save</button>
      </div>
  </form>
</div>
