import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartType, ChartOptions, ChartData } from 'chart.js';
// import { Label } from 'ng2-charts';
import PluginDataLabels from 'chartjs-plugin-datalabels';
import * as d3 from 'd3';
import { ColorGenerator } from '../color-generator';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnChanges {

  public options: ChartOptions = {
    responsive: true,
    // legend: {
    //   position: 'top',
    // },
    // plugins: {
    //   datalabels: {
    //     formatter: (value, ctx) => {
    //       const label = ctx.chart.data.labels[ctx.dataIndex];
    //       return label;
    //     },
    //   },
    // }
  };
  @Input() labels:string[] = <string[]>[]
  @Input() data: number[] = <number[]>[]
  public chartData: ChartData<'pie', number[], string | string[]> = {
    labels: this.labels,
    datasets: [ {
      data: this.data
    } ]
  };
  @Input() legend:boolean = true
  @Input() prosentase:boolean = false
  public type: ChartType = 'pie';
  public plugins = [PluginDataLabels];
  public colors:any[] = []

  constructor(private colorGenerator: ColorGenerator) { }

  ngOnInit() {
    if(this.prosentase){
      // this.options.tooltips = {
      //   callbacks: {
      //       label: function(tooltipItem, ctx) {
      //         const label = ctx.labels[tooltipItem.index];
      //         const dataItem = ctx.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
      //         return `${label}: ${dataItem}%`;
      //       }
      //   }
      // }
    }
    // this.colors = [{
    //   backgroundColor:this.colorGenerator.interpolateColors(
    //     this.data.length,
    //     d3.interpolateInferno,
    //     {
    //       colorStart: 0,
    //       colorEnd: 1,
    //       useEndAsStart: false,
    //     }
    //   )
    // }]
  }
  ngOnChanges(changes: SimpleChanges): void {
      this.chartData = {
        labels: this.labels,
        datasets:[
          {data: this.data}
        ]
      }
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}
