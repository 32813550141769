import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { CreateOneBeneficiaryGQL, Beneficiary, UpdateOneBeneficiaryGQL, Group, GroupTreeGQL, DeleteManyBeneficiaryGroupGQL, LocationTreeGQL, Location, BeneficiaryCreateInput, BeneficiaryUpdateInput } from 'src/app/core/_services/graphql';
import { LoaderService } from 'src/app/core/_services/loader.service';
import { UtilService } from 'src/app/core/_services/util.service';

@Component({
  selector: 'app-beneficiary-form',
  templateUrl: './beneficiary-form.component.html',
  styleUrls: ['./beneficiary-form.component.css']
})
export class BeneficiaryFormComponent implements OnChanges, AfterViewInit{
  @Input() recordId:string = ''
  @Input() beneficiaryId:string = ''
  @Input() projectId:string = ''
  @Input() beneficiary:Beneficiary = <Beneficiary>{}
  @Input() isFullAccess:boolean = false
  @Output() complete = new EventEmitter()

  beneficiaryForm?: FormGroup
  isNewRecord: boolean
  isFormReady:boolean = false

  sexList = ['MALE', 'FEMALE', 'UNDEFINED']
  groups$:Observable<Group[]>
  locations$: Observable<Location[]>

  constructor(
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private createBeneficiary: CreateOneBeneficiaryGQL,
    private updateBeneficiary: UpdateOneBeneficiaryGQL,
    private deleteManyBeneficiaryGroups: DeleteManyBeneficiaryGroupGQL,
    private loader: LoaderService,
    private groupTree: GroupTreeGQL,
    private locationTree: LocationTreeGQL,
    private utilService: UtilService
  ){
    this.isNewRecord = this.beneficiary?.id ? false : true
    this.beneficiaryForm = this.buildForm()
    this.isFormReady = true
    this.groups$ = this.utilService.projectState$.pipe(
      map(p=>p.project.id),
      switchMap(pid=>{
        this.projectId = pid
        return this.groupTree.watch({where:{level:{equals:0}, projectId:{equals:pid}}}).valueChanges.pipe(
          map(res=>{
            return <Group[]>res.data.groups
          })
        )
      })
    )
    this.locations$ = this.utilService.projectState$.pipe(
      map(p=>p.project.id),
      switchMap(pid=>{
        this.projectId = pid
        return this.locationTree.watch({where:{level:{equals:0}, projectId:{equals:pid}}}).valueChanges.pipe(
          map(res=>{
            return <Location[]>res.data.locations
          })
        )
      })
    )
  }
  ngOnChanges(changes: SimpleChanges): void {
      this.isNewRecord = this.beneficiary?.id ? false : true
      this.beneficiaryForm = this.buildForm()
      this.isFormReady = true

  }
  ngAfterViewInit(): void {
    if(!this.isNewRecord){
      this.beneficiaryForm?.get('groups')?.setValue(this.beneficiary.groups.map(g=>g.group))
    }
  }
  buildForm(){
    return this.fb.group({
      fullName: [!this.isNewRecord ? this.beneficiary?.fullName : '', Validators.required],
      sex: [!this.isNewRecord ? this.beneficiary?.sex : '', Validators.required],
      age: [!this.isNewRecord ? this.beneficiary?.age : '', Validators.required],
      phone: [!this.isNewRecord ? this.beneficiary?.phone : ''],
      email: [!this.isNewRecord ? this.beneficiary?.email : ''],
      location: [!this.isNewRecord ? this.beneficiary?.location : ''],
      groups: [!this.isNewRecord ? this.beneficiary?.groups.map(g=>g.group) : ''],
    })
  }
  save(){
    // console.log(this.beneficiaryForm?.value)

    if(this.beneficiaryForm?.valid && this.projectId){
      // CREATE
      if(this.isNewRecord){
        const formValue = <Beneficiary>this.beneficiaryForm.value
        let data:BeneficiaryCreateInput = {
          fullName: formValue.fullName,
          sex: formValue.sex,
          age: formValue.age,
          phone: formValue.phone,
          email: formValue.email,
          project:{
            connect:{id:this.projectId}
          }
        }
        if(formValue.groups.length > 0){
          data = {...data, ...{
            groups:{connectOrCreate:formValue.groups.map(g=> {return {
              where:{
                id: g.id
              },
              create:{
                group:{
                  connect:{
                    id: g.id
                  }
                }
              }
            }})}
          }}
        }
        if(formValue.location?.id){
          data = {...data, ...{
            location: {
              connect:{id:formValue.location?.id}
            }
          }}
        }

        this.createBeneficiary.mutate({data: data})
        .pipe(
          take(1),
          this.loader.indicate(),
          map(res=>res.data?.createOneBeneficiary.id)
        ).subscribe(id=>{
          if(id){
            this.complete.emit(id)
          }
        })
      }
      // UPDATE
      else{
        const formValue = <Beneficiary>this.beneficiaryForm.value
        if(formValue.groups.length > 0){
          this.deleteManyBeneficiaryGroups.mutate({
            where:{beneficiaryId:{
              equals:this.beneficiary.id
            }}
          }).pipe(
            switchMap(res=>{
              let data:BeneficiaryUpdateInput = {
                fullName:{set:formValue.fullName},
                sex:{set:formValue.sex},
                age:{set:formValue.age},
                phone: {set:formValue.phone},
                email: {set:formValue.email},
                project:{
                  connect:{id:this.projectId}
                },
                groups:{connectOrCreate:formValue.groups.map(g=> {return {
                  where:{
                    id: g.id
                  },
                  create:{
                    group:{
                      connect:{
                        id: g.id
                      }
                    }
                  }
                }})}
              }
              if(formValue.location?.id){
                data = {...data, ...{
                  location: {
                    connect:{id:formValue.location?.id}
                  }
                }}
              }
              return this.updateBeneficiary.mutate({data: data, where:{id:this.beneficiary.id}}).pipe(
                map(r=>r.data?.updateOneBeneficiary)
              )
            })
          ).subscribe(res=>{
            if(res?.id){
              this.complete.emit(res?.id)
            }
          })
        }else{
          let data:BeneficiaryUpdateInput = {
            fullName:{set:formValue.fullName},
            sex:{set:formValue.sex},
            age:{set:formValue.age},
            phone: {set:formValue.phone},
            email: {set:formValue.email},
            project:{
              connect:{id:this.projectId}
            }
          }

          if(formValue.groups.length > 0){
            data = {...data, ...{groups:{connectOrCreate:formValue.groups.map(g=> {return {
              where:{
                id: g.id
              },
              create:{
                group:{
                  connect:{
                    id: g.id
                  }
                }
              }
            }})}}}
          }
          if(formValue.location?.id){
            data = {...data, ...{
              location: {
                connect:{id:formValue.location?.id}
              }
            }}
          }

          console.log(formValue)

          this.updateBeneficiary.mutate({data: data, where:{id:this.beneficiary.id}})
          .pipe(
            take(1),
            this.loader.indicate(),
            map(res=>res.data?.updateOneBeneficiary?.id)
          ).subscribe(id=>{
            if(id){
              this.complete.emit(id)
            }
          })
        }
      }

    }
  }
  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }
}
